<template>
  <v-card
    class="ma-5"
    outlined
  >
    <v-toolbar flat>
      <v-toolbar-title>{{ capitalize(title) }}</v-toolbar-title>
      <v-spacer />
      <v-col class="pr-0">
        <search-horse
          :id="$route.query.stallion"
          horse-type="Hengst"
          :stud-only="true"
          :clearable="true"
          :outlined="true"
          :dense="true"
          @emit-horse="updateSearch"
        />
      </v-col>
      <filters
        :filters="filters"
        :options="options"
        :fetcher="fetchHorsesWithStock"
        @fetching="loading = $event"
        @fetchedResults="horses = $event"
        @fetchedTotal="totalHorses = $event"
      />
    </v-toolbar>
    <v-data-table
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="ma-5"
      :headers="headers"
      :items="horses"
      :loading="loading"
      loading-text="Bezig met laden..."
      :server-items-length="totalHorses"
      :options.sync="options"
    >
      <template #no-data>
        Geen {{ title }} gevonden
      </template>
      <template #item="props">
        <tr
          class="clickable"
          @click="openCollectionPage(props.item._id)"
        >
          <td>
            {{ props.item.horse.name }}
          </td>
          <td>
            {{ props.item.totalInitial }}
          </td>
          <td>
            {{ props.item.currentInventory }}
          </td>
          <td>
            {{ props.item.totalMods }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { horseName, ownerName, capitalize, compareRouteAndPush, tubesAvailable } from '@/Helpers';
import { generateCsvFromHorseSemenTable } from '@/Helpers/table.helper.ts';
import { nitrogenContainerAPI, semenAPI } from '@/services';
import SearchHorse from '@/components/search/SearchHorse';
import { exportTypes } from '@/consts';
import Filters from '@/components/filters/Filters';

export default {
  components: { SearchHorse, Filters },
  props: {
    title: { type: String, default: 'Horses' },
    headers: { type: Array, required: true },
    hasCsvExport: { type: Boolean, default: false },
  },
  data: (data) => ({
    filteredHeaders: [],
    horses: [],
    totalHorses: 0,
    loading: false,
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || 10,
    },
    nitrogenContainers: [],
    sortBy: 'horse',
    sortDesc: false,
  }),
  computed: {
    filters() {
      return {
        exportType: {
          vTag: 'select',
          label: 'Filter op export type van sperma loten',
          options: { multiple: true },
          items: [exportTypes.NATIONAL, exportTypes.EU, exportTypes.WORLDWIDE],
        },
        container: {
          vTag: 'select',
          label: 'Container',
          items: this.nitrogenContainers,
          cols: 6,
          objectItems: {
            text: horseName,
            value: '_id',
          },
        },
        tube: {
          vTag: 'select',
          label: 'Koker',
          items: tubesAvailable(this.nitrogenContainers.filter((container) => container._id === this.$route.query.container)),
          cols: 6,
          objectItems: {
            text: 'text',
            value: (item) => item.value.toString(),
          },
          hideIf: {
            target: 'container',
            condition: undefined,
          },
        },
      };
    },
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler() {
        this.compareRouteAndPush({
          ...this.$route.query,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
        }, this.$route, this.$router);
      },
    },
    horses: {
      immediate: true,
      handler(newData) {
        if (newData.length > 0 && this.hasCsvExport) {
          const csvData = generateCsvFromHorseSemenTable(newData);
          this.$emit('update-download-data', {
            dataUri: encodeURI(csvData),
            name: 'horses-with-semen.csv',
          });
        }
      },
    },
  },
  created() {
    this.getNitrogenContainers();
  },
  methods: {
    ownerName,
    horseName,
    capitalize,
    compareRouteAndPush,
    openCollectionPage(id) {
      document.body.style.cursor = 'default';
      this.$router.push(`/semen/${id}?hideEmpty=true`);
    },
    updateSearch(horse) {
      this.compareRouteAndPush({
        ...this.$route.query,
        stallion: horse ? horse._id : undefined,
      }, this.$route, this.$router);
    },
    showColumn(col) { return this.headers.find((header) => header.value === col).selected; },
    fetchHorsesWithStock(URLparams) {
      return semenAPI.getHorsesWithStock(URLparams);
    },
    async getNitrogenContainers() {
      try {
        const { data: { results } } = await nitrogenContainerAPI.getNitrogenContainers();
        this.nitrogenContainers = results;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      }
    },
  },
};
</script>
