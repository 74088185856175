<template>
  <v-data-table
    class="ma-5"
    :headers="headers"
    :items="cases"
    item-key="_id"
    :server-items-length="totalCases"
    :expanded.sync="expanded"
    single-expand
    show-expand
    :loading="loading"
    loading-text="Onderzoeksfiches ophalen..."
    :options.sync="tableOptions"
  >
    <template #no-data>
      Geen cases toegevoegd
    </template>
    <template #[`item.customer`]="{ item }">
      {{ ownerName(item.customer) }}
    </template>
    <template #[`item.horse`]="{ item }">
      {{ horseName(item.horse) }}
    </template>
    <template #[`item.stallion`]="{ item }">
      {{ horseName(item.stallion) }}
    </template>
    <template #[`item.length`]="{ item }">
      {{ item.researchSheets.length }}
    </template>
    <template #[`item.status`]="{ item }">
      <tag
        :text="item.status"
        :color="colorTag(item.status)"
      />
    </template>
    <template #[`item.updatedAt`]="{ item }">
      {{ formatDate(item.updatedAt) }}
    </template>
    <template #[`item.createdAt`]="{ item }">
      {{ formatDate(item.createdAt) }}
    </template>
    <template #[`expanded-item`]="{ headers, item }">
      <td
        :colspan="headers.length"
        class="pa-0 subitem"
      >
        <v-data-table
          :headers="sheetHeaders"
          :items="item.researchSheets"
          hide-default-header
          hide-default-footer
          class="light-grey"
        >
          <template #item="props">
            <tr
              class="clickable"
              @click="openCasePage(item._id)"
            >
              <td class="pl-16">
                {{ formatDateFull(props.item.researchDate) }}
              </td>
              <td>{{ props.item.doctor }}</td>
            </tr>
          </template>
        </v-data-table>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { formatDate, formatDateFull, ownerName, horseName, compareRouteAndPush } from '@/Helpers';
import Tag from '@/components/common/Tag';

export default {
  components: { Tag },
  props: {
    cases: { type: Array, required: true },
    headers: { type: Array, required: true },
    totalCases: { type: Number, required: true },
    horse: { type: String, default: '' },
    customer: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    options: { type: Object },
  },
  data: ({ options }) => ({
    sheetHeaders: [
      { text: 'Onderzoeksdatum', value: 'researchDate', selected: true },
      { text: 'Dokter', value: 'doctor', selected: true },
    ],
    expanded: [],
    tableOptions: { ...options },
  }),
  watch: {
    tableOptions: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit('update-options', this.tableOptions);
      },
    },
  },
  methods: {
    ownerName,
    horseName,
    formatDate,
    formatDateFull,
    compareRouteAndPush,
    showColumn(col) {
      return this.headers.find((header) => header.value === col).selected;
    },
    colorTag(tagText) {
      switch (tagText) {
      case 'Gesloten':
        return 'grey';
      case 'Factureerbaar':
        return 'green';
      default:
        return 'primary';
      }
    },
    openCasePage(id) {
      this.$router.push({ path: `/case/${id}` });
    },
  },
};
</script>

<style scoped>
.subitem .v-data-table {
  border-radius: unset !important;
}
</style>
