const errorMessage = {
  namespaced: true,

  state: () => ({
    message: '',
  }),

  actions: {
    setErrorMessage({ commit }, message) {
      commit('setError', { message });
    },
    resetError({ commit }) {
      commit('resetError');
    },
  },

  mutations: {
    setError(state, { message }) {
      state.message = message;
    },
    resetError(state) {
      state.message = '';
    },
  },
};

export default errorMessage;
