<template>
  <v-layout
    column
    fill-height
    justify-space-between
  >
    <stock-table
      title="Stock overzicht"
      :headers="headers"
      :filters="filters"
      :sort-by="sortBy"
      :update="update"
      @updated="update = $event"
    />
    <v-btn
      style="width: fit-content; align-self: flex-end"
      color="primary"
      class="mr-5 mb-5"
      depressed
      @click="mediniDialog = true"
    >
      import medini
      <v-icon right>
        mdi-autorenew
      </v-icon>
    </v-btn>
    <medini-dialog
      :show-delivery-products="mediniDialog"
      @close="mediniDialog = $event"
      @update-stock="update = $event"
    />
  </v-layout>
</template>

<script>
import stockTable from '@/components/stock/Table';
import mediniDialog from '@/components/mediniDialog';
import { configAPI } from '@/services';

export default {
  components: { stockTable, mediniDialog },
  data: () => ({
    update: false,
    mediniDialog: false,
    headers: [
      { selected: true, text: 'Productnaam', value: 'name' },
      { selected: true, text: 'Type', value: 'type' },
      { selected: true, text: 'CNK', value: 'CNK' },
      { selected: true, text: 'Dosering', value: 'dosage' },
      { selected: true, text: 'Eenheid', value: 'outgoingUnit' },
      { selected: true, text: 'BTW', value: 'tax' },
      { selected: true, text: 'Vervaldatum', value: 'expirationDate' },
      { selected: false, sortable: false, text: 'Wachttijd (dagen)', value: 'waitingTime' },
      { align: 'end', selected: false, text: 'Supplement', value: 'supplementAdministration' },
      { align: 'end', selected: true, text: 'Totale verkoopprijs', value: 'sellValue' },
      { align: 'end', selected: false, text: 'Resterende aankoopprijs', value: 'buyinValue' },
      { align: 'end', selected: true, text: 'Resterend', value: 'remaining' },
    ],
    sortBy: ['name'],
    productTypes: [],
    taxes: [],
  }),
  computed: {
    filters() {
      return {
        type: {
          vTag: 'select',
          label: 'Filter op type',
          items: this.productTypes,
          options: { multiple: true },
        },
        tax: {
          vTag: 'select',
          label: 'Filter op BTW',
          items: this.taxes,
          options: { multiple: true },
        },
        remaining: {
          vTag: 'select',
          label: 'Filter op resterend',
          items: ['In stock', 'Out of stock'],
        },
      };
    },
  },
  watch: {
    '$route.query': {
      deep: true,
      immediate: true,
      handler() {
        this.sortBy = this.$route.query.sortBy;
      },
    },
  },
  mounted() {
    this.getProductConfig();
  },
  methods: {
    async getProductConfig() {
      try {
        if (!this.productTypes.length && !this.taxes.length) {
          const { data: { types, tax } } = await configAPI.getProductConfig();
          this.productTypes = types;
          this.taxes = tax;
        }
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
  },
};
</script>
