<template>
  <v-layout column fill-height justify-space-between>
    <v-card class="ma-5" outlined>
      <v-tabs v-model="type" center-active>
        <!-- ICSI -->
        <v-tab key="icsi" href="#icsi"> ICSI </v-tab>
        <v-tab-item value="icsi">
          <icsi-costs-table />
        </v-tab-item>

        <!-- SEMEN -->
        <v-tab key="semen" href="#semen"> Sperma </v-tab>
        <v-tab-item value="semen">
          <semen-costs-table />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-layout>
</template>

<script>
import IcsiCostsTable from '@/components/StorageCosts/IcsiCostsTable.vue';
import SemenCostsTable from '@/components/StorageCosts/SemenCostsTable.vue';

export default {
  name: 'StorageCostsOverview',
  components: { IcsiCostsTable, SemenCostsTable },
  computed: {
    type: {
      set(type) {
        if (this.$route.query.type !== type) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              type,
            },
          });
        }
      },
      get() {
        return this.$route.query.type;
      },
    },
  },
};
</script>
