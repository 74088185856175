<template>
  <pension-form
    :pension="pension"
    :loading="loading"
    :disabled="disabled"
    @update-pension="pension => this.pension = pension"
  />
</template>

<script>
import pensionForm from '@/components/pension/Form';
import { pensionAPI } from '@/services';

export default {
  components: { pensionForm },
  props: ['id'],
  data: () => ({
    disabled: false,
    pension: {
      translations: {
        fr: '',
        en: '',
      },
    },
    loading: false,
    config: false,
  }),
  beforeMount() {
    if (this.id !== 'undefined') {
      this.getPension(this.id);
    }
  },
  methods: {
    async getPension(id) {
      this.loading = true;
      try {
        const { data } = await pensionAPI.get(id);
        this.pension = data;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
