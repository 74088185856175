
import Vue from 'vue';
import { formatDate } from '@/Helpers/dates';

export default Vue.extend({
  name: 'DailyLiveStockTable',
  props: {
    group: { type: Object, default: () => {} },
    loading: { type: Boolean, default: false },
  },
  methods: {
    formatDate,
  },
});
