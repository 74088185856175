<template>
  <labo-form
    :labo="labo"
    :loading="loading"
    :disabled="disabled"
    :config="config"
    :parameter-options="parameterOptions"
    @update-labo="labo = $event"
    @change-config="config = $event"
  />
</template>

<script>
import laboForm from '@/components/labos/Form';
import { laboAPI } from '@/services';

export default {
  components: { laboForm },
  props: {
    id: { type: String, default: '' },
  },
  data() {
    return {
      disabled: false,
      labo: {
        translations: {
          fr: '',
          en: '',
        },
        products: [],
        parameters: [],
      },
      parameterOptions: [
        {
          name: 'Voor dit onderzoek wil ik een sperma locatie kunnen selecteren',
          value: 'SemenCollection',
          switch: false,
        },
        {
          name: 'Voor dit onderzoek wil ik een embryo locatie kunnen selecteren',
          value: 'Embryo',
          switch: false,
        },
        {
          name: 'Voor dit onderzoek wil ik een paard kunnen selecteren',
          value: 'Horse',
          switch: false,
          select: true,
          selectText: 'met als geslacht',
          selectValue: undefined,
          specification: true,
          specificationSwitch: false,
        },
      ],
      loading: false,
      config: false,
    };
  },
  beforeMount() {
    if (this.id !== 'undefined') {
      this.getLabo(this.id);
    }
  },
  methods: {
    async getLabo(id) {
      try {
        this.loading = true;
        const { data } = await laboAPI.getLabo(id);
        this.labo = data;
        this.config = !!this.labo.parameters.length || !!this.labo.notifications.length;
        this.parameterOptions = this.parameterOptions.map((option) => {
          const index = this.labo.parameters.findIndex((param) => param.elementModel === option.value);
          return {
            ...option,
            switch: index >= 0,
            selectValue: index >= 0 ? this.labo.parameters[index].elementType : option.selectValue,
            specificationSwitch: index >= 0 ? this.labo.parameters[index].elementSpecification : false,
          };
        });
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
