type HorseLocation = {
  stable: string;
  prefix: string;
  name: string;
};

export const displayLocation = (location: HorseLocation): string => {
  if (location) {
    if (location.prefix) {
      return `${location.stable} ${location.prefix}-${location.name}`;
    }
    return `${location.stable} ${location.name}`;
  }
  return '-';
};

type EmbryoLocation = {
  container: {
    name: string;
  };
  position: 'Onder' | 'Boven';
  tube: number;
};
export const displayEmbryoLocation = (location: EmbryoLocation): string =>
  `${location.container.name} - ${location.position} - ${location.tube}`;
