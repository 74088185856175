<template>
  <v-layout
    column
    fill-height
    justify-space-between
  >
    <v-card
      class="ma-5"
      outlined
    >
      <semen-costs-details-table
        :dialog="dialog"
        @select="selected = $event"
        @toggle-dialog="dialog = $event"
      />
    </v-card>
    <div class="button-container mr-5 mb-5">
      <v-btn
        color="primary"
        outlined
        depressed
        @click="$router.back()"
      >
        <v-icon left>
          mdi-chevron-left
        </v-icon>
        Terug naar overzicht
      </v-btn>

      <div class="button-container">
        <v-btn
          :disabled="!selected"
          color="primary"
          depressed
          @click="dialog = true"
        >
          Factureren {{ selected ? `(${selected})` : '' }}
        </v-btn>
      </div>
    </div>
  </v-layout>
</template>

<script>
import SemenCostsDetailsTable from '@/components/StorageCosts/SemenCostsDetailsTable.vue';

export default {
  name: 'SemenStorageDetail',
  components: { SemenCostsDetailsTable },
  data: () => ({
    selected: 0,
    dialog: false,
  }),
};
</script>

<style scoped>
.button-container {
  align-self: flex-end;
  display: flex;
  gap: 1rem;

  button {
    width: fit-content;
  }
}
</style>
