<template>
  <v-dialog
    v-model="detailDialog"
    persistent
    max-width="800px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Details van de stockwijzigingen aan dit lot</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="semenCollection.modifications"
          sort-by="date"
        >
          <template #no-data>
            Nog geen wijzigingen aan dit lot gevonden
          </template>
          <template #item="props">
            <tr>
              <td>{{ props.item.type }}</td>
              <td>{{ ownerName(props.item.owner) }}</td>
              <td>{{ props.item.action === 'subtract' ? '- ' : '+ ' }}{{ props.item.amount }}</td>
              <td>{{ horseName(props.item.stallion) }}</td>
              <td class="text-end">
                {{ formatDate(props.item.date) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="closeDialog"
        >
          Sluiten
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { formatDate, ownerName, horseName } from '@/Helpers';

export default {
  props: {
    semenCollection: { type: Object, required: true },
    detailDialog: { type: Boolean },
  },
  data() {
    return {
      headers: [
        { value: 'type', text: 'Type' },
        { value: 'client', text: 'Klant' },
        { value: 'straws', text: 'Aantal rietjes' },
        { value: 'horse', text: 'Paard' },
        { value: 'date', text: 'Datum', align: 'end' },
      ],
    };
  },
  methods: {
    horseName,
    ownerName,
    formatDate,
    closeDialog() {
      this.$emit('close-dialog', false);
    },
  },
};
</script>
