
/* eslint-disable no-nested-ternary */
import Vue from 'vue';
import DailyLiveStockTable from '@/components/livestock-register/DailyLiveStockTable.vue';
import YearlyLiveStockTable from '@/components/livestock-register/YearlyLiveStockTable.vue';
import DatePicker from '@/components/DateSelector/DatePicker.vue';
import livestockAPI from '@/services/LivestockAPI';
import { months } from '@/consts';

export default Vue.extend({
  name: 'LiveStockRegisterOverview',
  components: { DailyLiveStockTable, YearlyLiveStockTable, DatePicker },
  data: () => ({
    date: '',
    livestock: [],
    loading: false,
    yearlyData: {},
    year: 0,
    years: [],
    months: [],
  }),
  watch: {
    date: {
      handler() {
        this.date = new Date(this.date).toUTCString();
        this.getLiveStock(this.date);
      },
      deep: true,
    },
  },
  beforeMount() {
    this.months = months;
    this.initOptionsAndData();
  },
  methods: {
    async initOptionsAndData(): Promise<any> {
      await this.getYearOptions();
      await this.getYearlyData(this.year);
    },
    async getLiveStock(date: string): Promise<any> {
      try {
        this.loading = true;
        const { data } = await livestockAPI.getDailyLivestock(date);

        this.livestock = data
          .sort((a, b) => a._id.toLowerCase().localeCompare(b._id.toLowerCase()))
          .map((group) => {
            group.horses.sort((a, b) => a._id.toLowerCase().localeCompare(b._id.toLowerCase()));
            return group;
          });
      } catch (error) {
        await this.$store.dispatch('errorMessage/setErrorMessage', error.message);
      } finally {
        this.loading = false;
      }
    },
    async getYearlyData(year: number): Promise<any> {
      try {
        this.loading = true;
        const { data } = await livestockAPI.getYearlyLivestock(year);
        this.yearlyData = data;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setErrorMessage', error.message);
      } finally {
        this.loading = false;
      }
    },
    async getYearOptions(): Promise<any> {
      try {
        this.loading = true;
        const { data } = await livestockAPI.getYears();
        this.years = data;
        // eslint-disable-next-line prefer-destructuring
        this.year = data[0];
      } catch (error) {
        await this.$store.dispatch('errorMessage/setErrorMessage', error.message);
      } finally {
        this.loading = false;
      }
    },
  },
});
