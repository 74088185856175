<template>
  <v-row class="ma-5">
    <v-col
      v-if="loading"
      cols="12"
    >
      Ophalen van gegevens...
    </v-col>
    <template v-else>
      <v-col
        v-for="stable of Object.keys(availableLocations)"
        :key="stable"
        cols="12"
        md="4"
      >
        <available-locations-table
          :stable="stable"
          :available-locations="availableLocations[stable].locations"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { locationAPI } from '@/services/index.ts';
import AvailableLocationsTable from '@/components/availableLocation/Table';
import Vue from 'vue';

export default Vue.extend({
  name: 'AvailableLocationsOverview',
  components: { AvailableLocationsTable },
  data: () => ({
    availableLocations: {},
    headers: [],
    loading: false,
  }),
  beforeMount() {
    this.getAvailableLocations();
  },
  methods: {
    async getAvailableLocations() {
      this.loading = true;
      try {
        const { data } = await locationAPI.getAvailableLocations();
        this.availableLocations = data;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setErrorMessage', error);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style scoped></style>
