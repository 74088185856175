import {
  get, post, put, deleteR,
} from '@/services/API';

export default {
  postICSI: (icsi) => post('/api/icsi', {
    icsi,
  }),
  deleteICSI: (id) => deleteR(`/api/icsi/${id}`),
  getAllICSI: (params) => get(`/api/icsi?${new URLSearchParams(params)}`),
  getICSI: (id) => get(`/api/icsi/${id}`),
  putICSI: (icsi) => put(`/api/icsi/${icsi._id}`, {
    icsi,
  }),

  getEmbryo: (id) => get(`/api/icsi/embryo/${id}`),
  getAllEmbryos: (params) => get(`/api/icsi/embryos?${new URLSearchParams(params)}`),
  getAvailableEmbryos: (params) => get(`/api/icsi/embryos?active=true&${new URLSearchParams(params)}`),

  transferEmbryo: (data) => post('/api/icsi/transfer', data),
  exportEmbryo: (data) => post('/api/icsi/export', data),

  getEmbryosByCustomer: (id, params) => get(`/api/customer/${id}/embryos?${new URLSearchParams(params)}`),
  getEmbryosByHorse: (id, params) => get(`/api/horse/${id}/embryos?${new URLSearchParams(params)}`),

  deactivate: (id, embryo, note) => put(`/api/icsi/${id}/${embryo._id}/deactivate`, {
    embryo, note,
  }),
  reactivate: (id, embryo, note) => put(`/api/icsi/${id}/${embryo._id}/reactivate`, {
    embryo, note,
  }),
};
