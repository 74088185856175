import Vue from 'vue';
import Vuex from 'vuex';
import { errorMessage, YearlyReport, CompanyInfo } from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    errorMessage,
    yearlyReport: YearlyReport,
    companyInfo: CompanyInfo,
  },
});

export default store;
