<template>
  <v-layout
    column
    fill-height
    justify-space-between
  >
    <products-table
      title="Producten"
      :headers="headers"
      :filters="filters"
    />
    <v-btn
      style="width: fit-content; align-self: flex-end"
      color="primary"
      class="mr-5 mb-5"
      depressed
      @click="() => openProductPage()"
    >
      product toevoegen
      <v-icon right>
        mdi-plus
      </v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import productsTable from '@/components/products/Table';
import { configAPI } from '@/services';

export default {
  components: { productsTable },
  data() {
    return {
      types: [],
      headers: [
        { text: 'Productnaam', value: 'name', sortable: true, selected: true },
        { text: 'Type', value: 'type', sortable: true, selected: true },
        { text: 'CNK', value: 'CNK', sortable: true, selected: true },
        { text: 'Aantal/batch', value: 'dosage', sortable: false, selected: true },
        { text: 'Eenheid', value: 'outgoingUnit', sortable: false, selected: true },
        { text: 'Wachttijd (dagen)', value: 'waitingTime', sortable: false, selected: true },
        { text: 'BTW', value: 'tax', align: 'left', sortable: false, selected: true },
        { text: 'Supplement (€)', value: 'supplementAdministration', align: 'end', sortable: false, selected: true },
        { text: 'Bewerken', value: 'action', align: 'end', sortable: false, selected: true },
      ],
    };
  },
  computed: {
    filters() {
      return {
        type: {
          vTag: 'select',
          label: 'Filter op type',
          items: this.types,
          options: { multiple: true },
        },
      };
    },
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    openProductPage(id) { this.$router.push({ path: `/settings/product/${id}` }); },
    async getConfig() {
      try {
        const { data: { types } } = await configAPI.getProductConfig();
        this.types = types;
      } catch (err) {
        await this.setError(err.message);
      }
    },
  },
};
</script>
