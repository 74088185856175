<template>
  <v-data-table
    v-if="protocol"
    :headers="headers"
    :items="protocol.products"
    :item-key="protocol.products._id"
    hide-default-header
    hide-default-footer
    class="products-table"
    :items-per-page="protocol.products ? protocol.products.length : 0"
  >
    <template #no-data>
      Geen producten in dit protocol
    </template>
    <template #[`item.amount`]="props">
      <v-edit-dialog
        v-if="!disabled"
        :return-value.sync="props.item.amount"
      >
        {{ productFormatAmount(props.item) }}
        <template #input>
          <v-text-field
            v-model="props.item.amount"
            type="number"
            label="Aanpassen"
            single-line
            autofocus
            :disabled="disabled"
          />
        </template>
      </v-edit-dialog>
      <div v-else>
        {{ productFormatAmount(props.item) }}
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { productFormatAmount } from '@/Helpers';

export default {
  props: ['protocol', 'disabled'],
  data: () => ({
    headers: [
      { text: 'Productnaam', value: 'product.name', width: '100px' },
      { text: 'Aantal', value: 'amount', align: 'end' },
    ],
  }),
  methods: {
    productFormatAmount,
  },
};
</script>

<style scoped>
  .products-table {
    width: 100% !important;
    background-color: #f9f9f9;
  }
</style>
