<template>
  <v-app>
    <app-navigation />
    <v-main class="light-grey">
      <router-view />
      <error-message />
    </v-main>
  </v-app>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import AppNavigation from '@/components/AppNavigation';
import ErrorMessage from '@/components/ErrorMessage';
import { computed, watch } from 'vue';
import { useIdle, useTimestamp } from '@vueuse/core';

export default {
  name: 'Equibase',
  components: {
    AppNavigation,
    ErrorMessage,
  },
  setup() {
    const { idle, lastActive } = useIdle(5 * 60 * 1000);
    const now = useTimestamp({ interval: 1000 });
    const idledFor = computed(() => Math.floor((now.value - lastActive.value) / 1000));
    watch(idle, (isIdle) => {
      if (!isIdle) {
        window.location.reload();
      }
    });
    return {
      idle,
      idledFor,
    };
  },
  data: () => ({
    //
  }),
};
</script>

<style>
@import './assets/styles/app.scss';
</style>

<style scoped>
@media print {
  main {
    padding: 0 !important;
  }
}
</style>
