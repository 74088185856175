import { IHorse } from '@/models/horse';
import JSPDF from 'jspdf';

function createHorseResearchSheetPage(researchSheet: JSPDF, horse: IHorse) {
  researchSheet.line(0, 30, 210, 30);
  researchSheet.line(25, 0, 25, 297);
  researchSheet.setFontSize(14);
  researchSheet.text(new Date().getFullYear().toString(), 6, 26);
  researchSheet.text(new Date(horse.date_of_birth).getFullYear().toString(), 205, 10, { align: 'right' });
  researchSheet.text(horse.microchip, 205, 18, { align: 'right' });
  researchSheet.text((horse.surrogate_uid || '').toString(), 205, 26, { align: 'right' });
  researchSheet.setFontSize(20);
  researchSheet.setFont('helvetica', 'bold');
  researchSheet.text(horse.name.toUpperCase(), 30, 26);
  researchSheet.setFont('helvetica', 'normal');
}

export function downloadResearchSheetPDF(name: string, horses: IHorse[], sheetsPerHorse: number = 2) {
  const researchSheet = new JSPDF();
  [...horses]
    .sort((h1, h2) => h1.name.toLowerCase().localeCompare(h2.name.toLowerCase()))
    .forEach((horse, index) => {
      for (let i = 1; i <= sheetsPerHorse; i += 1) {
        createHorseResearchSheetPage(researchSheet, horse);
        if (i !== sheetsPerHorse) {
          // Do not add a new page after the last page
          researchSheet.addPage();
        }
      }
      if (index !== horses.length - 1) {
        // Do not add a page when looping over the last horse
        researchSheet.addPage();
      }
    });
  researchSheet.save(`${name}.pdf`);
}
