<template>
  <div>
    <v-toolbar flat>
      <v-col>
        <date-picker
          label="Van"
          mode="date"
          :date="dateRange.from"
          dense
          outlined
          clearable
          hide-details
          close-on-input
          :max="dateRange.to"
          @select-date="updateDate('from', $event)"
        />
      </v-col>
      -
      <v-col>
        <date-picker
          label="Tot"
          mode="date"
          :date="dateRange.to"
          dense
          outlined
          clearable
          hide-details
          close-on-input
          :min="dateRange.from"
          @select-date="updateDate('to', $event)"
        />
      </v-col>
      <v-spacer />
      <v-col>
        <search-customer
          :id="$route.query.owner"
          :clearable="true"
          :outlined="true"
          :dense="true"
          @fetched-item="owner = $event"
          @emit-customer="updateSearch('owner', $event)"
        />
      </v-col>
      <columns :headers="headers" @emit-headers="filteredHeaders = $event" />
    </v-toolbar>
    <v-data-table
      class="ma-5"
      :headers="filteredHeaders"
      :items="costs"
      :server-items-length="totalCosts"
      :options.sync="options"
      :loading="loading"
      :sort-by="options.sortBy"
      :sort-desc="options.sortDesc"
      loading-text="Bezig met laden..."
    >
      <template #no-data> Geen kosten in de database </template>
      <template #item="props">
        <tr class="clickable" @click="openStockDetailPage(props.item.customer._id)">
          <td v-if="showColumn('name')">
            {{ ownerName(props.item.customer) }}
          </td>
          <td v-if="showColumn('address')">
            {{ formatAddress(props.item.customer) }}
          </td>
          <td v-if="showColumn('billed')" class="text-end">
            <Tag
              :normal-size="true"
              :color="props.item.billed ? 'grey' : 'success'"
              :text="props.item.billed ? 'Gefactureerd' : 'Te factureren'"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Columns from '@/components/filters/Columns.vue';
import SearchCustomer from '@/components/search/SearchCustomer.vue';
import { compareRouteAndPush, formatAddress, ownerName, formatPrice } from '@/Helpers';
import { storageAPI } from '@/services';
import DatePicker from '@/components/DateSelector/DatePicker.vue';
import Tag from '@/components/common/Tag';

export default {
  name: 'IcsiCostsTable',
  components: {
    Columns,
    SearchCustomer,
    DatePicker,
    Tag,
  },
  data: (data) => ({
    owner: null,
    costs: [],
    totalCosts: 0,
    loading: false,
    options: {
      page: Number(data.$route.query.page) || 1,
      limit: Number(data.$route.query.limit) || 10,
    },
    headers: [
      { text: 'Klant', value: 'name', selected: true },
      { text: 'Adres', value: 'address', selected: true },
      { text: 'Status', value: 'billed', selected: true, align: 'end' },
    ],
    filteredHeaders: [],
    dateRange: {
      from: data.$route.query.from || null,
      to: data.$route.query.to || null,
    },
  }),
  computed: {
    startOfPreviousYear() {
      const date = new Date();
      return new Date(date.getFullYear() - 1, 0, 1);
    },
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler() {
        this.compareRouteAndPush(
          {
            ...this.$route.query,
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            from: this.dateRange.from || undefined,
            to: this.dateRange.to || undefined,
          },
          this.$route,
          this.$router,
        );
      },
    },
    '$route.query': {
      deep: true,
      handler() {
        if (!this.$route.query.page && !this.$route.query.limit) {
          this.$router.replace({
            query: {
              page: 1,
              limit: this.options.itemsPerPage,
              sortBy: this.options.sortBy,
              sortDesc: this.options.sortDesc,
              ...this.$route.query,
            },
          });
        } else if (this.$route.query.type === 'icsi') this.getICSIStockage();
      },
    },
  },
  mounted() {
    if (!this.dateRange.from && !this.$route.query.from) {
      this.dateRange.from = this.startOfPreviousYear.toISOString();
    }
  },
  methods: {
    formatPrice,
    formatAddress,
    ownerName,
    compareRouteAndPush,
    showColumn(col) {
      return this.headers.find((header) => header.value === col)
        ? this.headers.find((header) => header.value === col).selected
        : undefined;
    },
    openStockDetailPage(id) {
      document.body.style.cursor = 'default';
      this.$router.push(
        `/fertility/${this.$route.query.type}/stock-costs-details/${id}?from=${this.dateRange.from || ''}&to=${
          this.dateRange.to || ''
        }`,
      );
    },
    updateDate(key, date) {
      this.dateRange[key] = date;
      this.compareRouteAndPush(
        {
          ...this.$route.query,
          [key]: date || undefined,
          page: 1,
        },
        this.$route,
        this.$router,
      );
    },
    updateSearch(key, value) {
      if (key === 'owner') this.owner = value;
      this.compareRouteAndPush(
        {
          ...this.$route.query,
          [key]: value ? value._id : undefined,
          page: 1,
        },
        this.$route,
        this.$router,
      );
    },
    async getICSIStockage() {
      try {
        const {
          data: { results, total },
        } = await storageAPI.getAllICSIStorage(this.$route.query);
        this.costs = results;
        this.totalCosts = total;
        await this.$store.dispatch('errorMessage/resetError');
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
  },
};
</script>
