export const semenGainType = 'semen-gain';
export const semenImportExportType = 'semen-import-export';
export const ovumGainType = 'ovum-gain';
export const embryoTransplantation = 'embryo-transplantation';

export const yearlyReportTitles = {
  [semenGainType]: 'Sperma gewonnen op het centrum',
  [semenImportExportType]: 'Handel in sperma',
  [ovumGainType]: 'Eicelwinning',
  [embryoTransplantation]: 'Embryotransplantaties',
};
