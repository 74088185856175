<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-left="10"
    offset-x
    left
    min-width="750"
    max-width="750"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        class="mr-2"
        v-bind="attrs"
        v-on="on"
      >
        <v-badge
          overlap
          dot
          :value="notifications.length"
          color="error"
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card class="pa-2 d-flex flex-column align-end">
      <v-list
        two-line
        class="py-0"
        width="100%"
        max-height="550"
        style="overflow-y: auto"
      >
        <v-list-item v-if="!notifications.length">
          <v-list-item-content>
            <v-list-item-title>Geen nieuwe notificaties</v-list-item-title>
            <v-list-item-subtitle>Woohoo!</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <template v-for="(notification, index) in notifications">
          <v-list-item
            v-if="notification.active"
            :key="index"
            @mouseenter="hovered = notification"
            @mouseleave="hovered = false"
          >
            <v-list-item-content>
              <v-list-item-title v-text="notification.text" />
              <v-list-item-subtitle class="text--primary">
                {{ formatDate(notification.expirationDate) + ' ' + checkExpirationDate(notification.expirationDate).text }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="d-flex flex-row justify-end align-center">
              <v-chip
                v-for="(tag, i) in notification.tags"
                :key="i"
                label
                :link="!!tag.element && !!tag.elementModel"
                :href="tag.element && tag.elementModel ? `/${tag.elementModel.toLowerCase()}/${tag.element._id}` : ''"
                :color="checkExpirationDate(notification.expirationDate).color"
                :text-color="checkExpirationDate(notification.expirationDate).textColor"
                class="transition-fast-in-fast-out mr-2"
              >
                {{ printName(tag) }}
              </v-chip>
              <v-btn
                v-if="hovered === notification"
                icon
                small
                color="error"
                @click="deleteNotification(notification)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <v-layout v-if="totalNotifications > 5">
        <v-btn
          :disabled="notificationShown <= 5"
          text
          color="primary"
          @click="notificationShown = 5"
        >
          Minder laden
        </v-btn>
        <v-btn
          :disabled="totalNotifications <= notificationShown"
          text
          color="primary"
          @click="notificationShown += 5"
        >
          Meer laden
        </v-btn>
      </v-layout>
    </v-card>
  </v-menu>
</template>

<script>
import { notificationAPI } from '@/services';
import { formatDate, addDays, differenceDays, printName } from '@/Helpers';

export default {
  name: 'NotificationDialog',
  data: () => ({
    notificationShown: 5,
    hovered: false,
    menu: false,
    notifications: [],
    totalNotifications: '',
  }),
  watch: {
    notificationShown() { this.getAllNotifications(); },
  },
  mounted() { this.getAllNotifications(); },
  methods: {
    formatDate,
    printName,
    checkExpirationDate(expDate) {
      const today = new Date().getTime();
      const exp = new Date(expDate).getTime();
      if (exp < today) {
        return { color: 'red lighten-5', textColor: 'red darken-4', text: '(Vervallen)' };
      }
      if (exp < addDays(today, 10)) {
        return { color: 'orange lighten-5', textColor: 'orange darken-4', text: `(${differenceDays(today, exp)} dagen)` };
      }
      return { color: 'secondary', textColor: 'primary', text: `(${differenceDays(today, exp)} dagen)` };
    },
    async deleteNotification(notification) {
      try {
        await notificationAPI.put({ ...notification, active: false });
        const index = this.notifications.findIndex((noti) => noti._id === notification._id);
        this.notifications.splice(index, 1);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
    async getAllNotifications() {
      try {
        const { data: { results, total } } = await notificationAPI.getAll({
          active: true,
          'sortBy[]': 'expirationDate',
          'sortDesc[]': false,
          limit: this.notificationShown,
        });
        this.notifications = results;
        this.totalNotifications = total;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
  },
};
</script>
