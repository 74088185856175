<template>
  <v-layout
    column
    fill-height
    justify-space-between
  >
    <horse-table
      title="Paarden met sperma loten"
      :headers="headers"
    />
    <v-row
      class="my-5 mr-5"
      justify="end"
    >
      <v-btn
        style="width: fit-content; align-self: end"
        color="success"
        class="mr-5"
        tag="a"
        depressed
        :disabled="!downloadFreeSpaces"
        :loading="!downloadFreeSpaces"
        :href="downloadFreeSpaces?.dataUri"
        :download="downloadFreeSpaces?.name"
      >
        Vrije plaatsen CSV
        <v-icon right>
          mdi-file-download
        </v-icon>
      </v-btn>
      <v-btn
        style="width: fit-content; align-self: end"
        color="success"
        class="mr-5"
        tag="a"
        depressed
        :disabled="!downloadActiveSemenColls"
        :loading="!downloadActiveSemenColls"
        :href="downloadActiveSemenColls?.dataUri"
        :download="downloadActiveSemenColls?.name"
      >
        CSV actieve loten
        <v-icon right>
          mdi-file-download
        </v-icon>
      </v-btn>

      <v-btn
        style="width: fit-content; align-self: end"
        color="primary"
        depressed
        @click="() => openSemenForm()"
      >
        Sperma lot toevoegen
        <v-icon right>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-row>
  </v-layout>
</template>

<script>
import HorseTable from '@/components/semenCollection/HorseTable';
import { nitrogenContainerAPI, semenAPI } from '@/services';
import { generateCsvFromTable } from '@/Helpers/table.helper';

export default {
  components: { HorseTable },
  data: () => ({
    createDialog: false,
    headers: [
      { text: 'Naam paard', value: 'horse' },
      { text: 'initial', value: 'initial' },
      { text: 'current', value: 'current' },
      { text: 'mods', value: 'mods' },
    ],
    downloadActiveSemenColls: null,
    downloadFreeSpaces: null,
    remainingSpaces: null,
  }),
  mounted() {
    this.getRemainingSpaces();
    this.getActiveSemenStockDownloadData();
  },
  methods: {
    openSemenForm(id) {
      this.$router.push({ path: `/semen/create/${id}` });
    },
    async getRemainingSpaces() {
      const {
        data: { remainingSpaces },
      } = await nitrogenContainerAPI.getFreeContainerSpace();

      this.remainingSpaces = remainingSpaces;

      this.downloadFreeSpaces = {
        dataUri: encodeURI(
          generateCsvFromTable(
            this.remainingSpaces.map((space) => ({
              'Naam container': space.container_name,
              'Vrije plaatsen': space.remaining_space,
            })),
          ),
        ),
        name: 'vrije-plaatsen.csv',
      };
    },
    async getActiveSemenStockDownloadData() {
      const {
        data: { collections },
      } = await semenAPI.getActiveSemenStockWithLocation();

      const csvData = generateCsvFromTable(collections);

      this.downloadActiveSemenColls = {
        dataUri: encodeURI(csvData),
        name: 'active_semen_stock.csv',
      };
    },
  },
};
</script>
