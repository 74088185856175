<template>
  <v-layout column>
    <v-card
      flat
      outlined
    >
      <v-toolbar
        flat
        dense
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          v-if="sheet && sheet.billable"
          color="primary"
          icon
          @click="openProtocolDialog"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider v-if="data && data.length" />
      <v-data-table
        v-if="data && data.length"
        class="ma-5 small-first"
        :headers="headers"
        :items="data"
        item-key="_id"
        :items-per-page="5"
        hide-default-header
        :hide-default-footer="!showFooter && data.length < 6"
        :expanded.sync="expanded"
        show-expand
        single-expand
      >
        <template #no-data>
          Geen {{ title }} toegevoegd
        </template>
        <template #[`item.name`]="props">
          {{
            getProtocolName(props.item.protocol)
          }}
        </template>
        <template #[`item.date`]="props">
          <span class="grey--text">{{ formatDateFull(props.item.date) }}</span>
        </template>
        <template
          v-if="sheet && sheet.billable"
          #[`item.action`]="{ item }"
        >
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon
                dark
                color="primary"
                @click="() => openDeleteDialog(item)"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Protocol verwijderen</span>
          </v-tooltip>
        </template>
        <template #expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="pa-0 subitem"
          >
            <div
              v-if="item.protocol.invoiceDescription"
              class="invoice-description"
            >
              Factuur beschrijving: {{ item.protocol.invoiceDescription }}
            </div>
            <mini-products-table
              v-if="item.protocol.products.length || !item.protocol.parameters.length"
              class="border-top"
              :protocol="item.protocol"
              :disabled="!sheet || !sheet.billable"
            />
            <mini-parameters-table
              v-if="item.protocol.parameters.length || item.protocol.simple_parameters.length"
              class="border-top"
              :protocol="item.protocol"
              :disabled="!sheet || !sheet.billable"
            />
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="protocolsDialog"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>{{ `${ title } ${ editQueue ? 'aanpassen' : 'toevoegen' }` }}</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            :disabled="!sheet || !sheet.billable"
          >
            <v-row
              dense
              class="mb-2"
            >
              <v-col cols="12">
                <search-protocol
                  type="selecteren"
                  :protocol="editQueue.protocol"
                  :clearable="true"
                  :autofocus="true"
                  :dense="true"
                  :outlined="true"
                  @emit-protocol="selectProtocol($event)"
                />
              </v-col>
            </v-row>
            <v-row v-if="!editQueue.protocol">
              <v-col
                dense
                cols="12"
              >
                <p class="text-center">
                  Niets geselecteerd
                </p>
              </v-col>
            </v-row>
            <template v-if="editQueue.protocol">
              <v-form>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="editQueue.protocol.amount"
                      :disabled="!amountChangable"
                      type="Number"
                      label="Aantal"
                      outlined
                      dense
                      hide-details
                      class="mb-2"
                      min="1"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-switch
                      v-model="amountChangable"
                      class="mt-1"
                      inset
                    />
                  </v-col>
                </v-row>
                <v-row v-if="editQueue.protocol.hasCustomDescription">
                  <v-col cols="12">
                    <v-text-field
                      v-model="editQueue.protocol.invoiceDescription"
                      outlined
                      dense
                      hide-details
                      class="mb-2"
                      label="Factuur beschrijving"
                      placeholder="Vul hier uw factuur beschrijving in"
                    />
                  </v-col>
                </v-row>
              </v-form>
              <mini-products-table
                :protocol="editQueue.protocol"
                :disabled="!sheet || !sheet.billable"
                class="mb-3"
              />
              <simple-parameter-form
                v-if="editQueue.protocol.simple_parameters.length"
                :protocol="editQueue.protocol"
              />
              <parameter-form
                v-if="editQueue.protocol.parameters.length"
                :protocol="editQueue.protocol"
                @emit-select="valid = $event"
                @push-collection="pushCollection(editQueue.protocol, $event)"
              />
            </template>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeProtocolDialog"
          >
            Annuleren
          </v-btn>
          <v-btn
            color="success"
            :disabled="!valid"
            text
            @click="addProtocol(editQueue)"
          >
            Toevoegen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog
      :delete-dialog="deleteDialog"
      item="Protocol"
      :delete-queue="deleteQueue"
      @delete="removeProtocol"
      @close="closeDeleteDialog"
    />
  </v-layout>
</template>

<script>
import searchProtocol from '@/components/search/SearchProtocol';
import deleteDialog from '@/components/common/DeleteDialog';
import { formatDateFull, getProtocolName } from '@/Helpers';
import SimpleParameterForm from '@/components/researchSheet/SimpleParameterForm';
import miniProductsTable from './miniTables/MiniProductsTable';
import miniParametersTable from './miniTables/MiniParametersTable';
import parameterForm from './ParameterForm';

export default {
  components: { searchProtocol, deleteDialog, miniProductsTable, miniParametersTable, parameterForm, SimpleParameterForm },
  props: {
    sheet: { type: [Object || undefined] },
    title: { type: String, default: '' },
    data: { type: Array, required: true },
    caseId: { type: String, default: undefined },
    showFooter: { type: Boolean },
    showDate: { type: Boolean },
  },
  data: () => ({
    productHeaders: [
      { text: 'Productnaam', value: 'product.name', divider: true, width: '100px' },
      { text: 'Aantal', value: 'amount', align: 'center' },
    ],
    protocolsDialog: false,
    editQueue: {},
    deleteDialog: false,
    deleteQueue: {},
    valid: false,
    expanded: [],
    amountChangable: false,
  }),
  computed: {
    headers() {
      if (this.showDate) {
        return [
          { text: 'Protocolnaam', value: 'name' },
          { text: 'Datum', value: 'date', align: 'end' },
        ];
      }
      return [
        { text: 'Protocolnaam', value: 'name' },
        { text: '', value: 'action', align: 'end', width: '110px' },
      ];
    },
    parameters() {
      return this.editQueue.protocol.parameters.map((param) => param.elementModel);
    },
  },
  methods: {
    getProtocolName,
    formatDateFull,
    selectProtocol(protocol) {
      if (protocol) {
        this.valid = !protocol.parameters.length;
      }
      this.editQueue = { protocol };
    },
    addProtocol(item) {
      this.sheet.protocols.push(item);
      this.editQueue = {};
      this.protocolsDialog = false;
      this.amountChangable = false;
    },
    removeProtocol(item) {
      const index = this.sheet.protocols.findIndex((prot) => prot.protocol === item.protocol);
      this.sheet.protocols.splice(index, 1);
      this.closeDeleteDialog(false);
    },
    openProtocolDialog(item) {
      if (item.protocol) this.editQueue = item;
      this.protocolsDialog = true;
    },
    closeProtocolDialog() {
      this.editQueue = {};
      this.protocolsDialog = false;
      this.amountChangable = false;
    },
    openDeleteDialog(item) {
      this.deleteQueue = item;
      this.deleteDialog = true;
    },
    closeDeleteDialog(state) {
      this.deleteQueue = {};
      this.deleteDialog = state;
    },
    pushCollection(protocol, collection) {
      protocol.parameters.push(collection);
    },
  },
};
</script>

<style scoped>
.subitem .v-data-table {
  border-radius: unset !important;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, .12);
}

.invoice-description{
  width: 100% !important;
  background-color: #f9f9f9;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 0.875rem;
}
</style>
