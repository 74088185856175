<template>
  <v-layout
    column
    fill-height
    justify-space-between
  >
    <ICSITable
      title="ICSI Loten"
      :headers="headers"
      :filters="filters"
    />
    <v-btn
      style="width: fit-content; align-self: flex-end"
      color="primary"
      class="mr-5 mb-5"
      depressed
      @click="openCreateICSIPage"
    >
      {{ $route.name }} lot toevoegen
      <v-icon right>
        mdi-plus
      </v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import ICSITable from '@/components/icsi/IcsiTable.vue';
import { horseName, tubesAvailable } from '@/Helpers';
import { nitrogenContainerAPI } from '@/services';

export default {
  components: { ICSITable },
  data: () => ({
    nitrogenContainers: [],
    headers: [
      { selected: true, text: 'Code', value: 'code' },
      { selected: true, text: 'Donor Merrie', value: 'donor_mare' },
      { selected: true, text: 'Dekhengst', value: 'donor_stallion' },
      { selected: true, text: "Aantal embryo's", value: 'amount' },
      { selected: true, text: 'TRACES', value: 'traces' },
      { align: 'end', selected: true, text: 'Gewijzigd op', value: 'updatedAt' },
      { align: 'end', selected: true, text: 'Aangemaakt op', value: 'createdAt' },
    ],
  }),
  computed: {
    filters() {
      return {
        donor_mare: { vTag: 'searchHorse', horseType: 'Merrie' },
        donor_stallion: { vTag: 'searchHorse', horseType: 'Hengst' },
        container: {
          vTag: 'select',
          label: 'Container',
          items: this.nitrogenContainers,
          cols: 6,
          objectItems: {
            text: horseName,
            value: '_id',
          },
        },
        tube: {
          vTag: 'select',
          label: 'Koker',
          items: tubesAvailable(this.nitrogenContainers.filter((container) => container._id === this.$route.query.container)),
          cols: 6,
          objectItems: {
            text: 'text',
            value: (item) => item.value.toString(),
          },
          hideIf: {
            target: 'container',
            condition: undefined,
          },
        },
        onlyActive: {
          vTag: 'switch',
          label: "Enkel loten met actieve embryo's",
        },
      };
    },
  },
  created() {
    this.getNitrogenContainers();
  },
  methods: {
    openCreateICSIPage() {
      this.$router.push({ path: '/icsi/create' });
    },
    async getNitrogenContainers() {
      try {
        const {
          data: { results },
        } = await nitrogenContainerAPI.getNitrogenContainers();
        this.nitrogenContainers = results;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      }
    },
  },
};
</script>
