<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title>Stockage {{ type === 'ICSI' ? 'embryo' : 'sperma' }} factureren - {{ ownerName(client) }}</v-card-title>
      <v-card-text style="max-height: 600px">
        Je staat op het punt
        <b>{{ selected.length }} {{ type === 'ICSI' ? "embryo's" : 'sperma collecties' }} te factureren voor {{ formatPrice(totalCost) }}</b>. Ben je zeker dat je deze {{ type === 'ICSI' ? "embryo's" : 'sperma collecties' }} wil factureren?
        <v-row class="mt-10">
          <v-col>
            <date-picker
              :date="endInvoiceDate"
              label="Kies de einddatum van de facturatie periode"
              hide-details
              close-on-input
              dense
              clearable
              :min="getMostRecentDate(storages)"
              @select-date="setInvoiceDates"
            />
          </v-col>
        </v-row>
        <v-form
          ref="form"
          v-model="valid"
          class="mt-10"
        >
          <v-row
            v-for="(storage, index) of storages"
            :key="`${storage.embryo ? storage.embryo.code : storage.semenCollection.lotNumber}-${storage.from}-${storage.to}`"
          >
            <v-col
              cols="2"
              class="d-flex align-center"
            >
              <v-text-field
                v-if="storage.embryo"
                :value="storage.embryo.code"
                label="code"
                hide-details
                dense
                disabled
              />
              <v-text-field
                v-else
                :value="storage.semenCollection.lotNumber"
                label="Lot"
                hide-details
                dense
                disabled
              />
            </v-col>
            <v-col cols="5">
              <date-picker
                :date="storage.from"
                label="van *"
                disabled
                hide-details
                close-on-input
                required
                dense
              />
            </v-col>
            <v-col cols="5">
              <date-picker
                :date="storage.to"
                label="tot *"
                hide-details
                close-on-input
                required
                :disabled="!!selected[index].to"
                dense
                :min="storage.from"
                @select-date="(date) => setInvoiceDate(date, storage, index)"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          Annuleren
        </v-btn>
        <v-btn
          color="success"
          text
          :loading="executing"
          :disabled="!valid"
          @click="invoice"
        >
          Afrekenen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from '@/components/DateSelector/DatePicker.vue';
import { formatPrice, ownerName } from '@/Helpers';
import { teamleaderAPI } from '@/services';
import Vue from 'vue';

export default Vue.extend({
  components: { DatePicker },
  props: {
    dialog: { type: Boolean },
    selected: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedStorages: this.selected,
      valid: false,
      executing: false,
      endInvoiceDate: new Date(),
    };
  },
  computed: {
    storages() {
      return this.selectedStorages.map((e) => {
        const { from, to } = e;
        const endInvoice = to ?? this.endInvoiceDate;
        return {
          ...e,
          from,
          endInvoice,
        };
      });
    },
    client() {
      return this.selected?.[0]?.owner || null;
    },
    totalCost() {
      return this.storages.reduce((acc, e) => acc + e.cost, 0);
    },
  },
  methods: {
    formatPrice,
    ownerName,
    close() {
      this.$refs.form.reset();
      this.$emit('close', !this.dialog);
    },
    setInvoiceDates(date) {
      this.selectedStorages = this.selected.map((e) => {
        if (e.to) {
          return e;
        }

        return {
          ...e,
          endInvoice: date,
          to: date,
        };
      });
    },
    setInvoiceDate(date, storage, index) {
      this.storages[index] = {
        ...storage,
        endInvoice: date,
        to: date,
      };
    },
    async invoice() {
      this.executing = true;
      try {
        await this.$store.dispatch('errorMessage/resetError');
        if (this.type === 'ICSI') {
          await teamleaderAPI.postICSIInvoice(this.storages);
        } else {
          await teamleaderAPI.postSemenInvoice(
            this.storages.map((batch) => ({
              ...batch,
              lotNumber: batch.semenCollection.lotNumber,
              startInvoice: batch.from,
            })),
          );
        }
        this.$emit('executed');
        this.close();
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      } finally {
        this.executing = false;
      }
    },
    getMostRecentDate(storages) {
      const mostRecentItem = storages.reduce((acc, currentItem) => {
        const accDate = new Date(acc.from);
        const currentDate = new Date(currentItem.from);

        let returnValue;
        if (currentDate > accDate) {
          returnValue = currentItem;
        } else {
          returnValue = acc;
        }
        return returnValue;
      }, storages[0]);
      return mostRecentItem.from;
    },
  },
});
</script>
