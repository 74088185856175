<template>
  <horse-table
    :headers="headers"
    :filters="filters"
  />
</template>
<script>
import horseTable from '@/components/horse/Table';
import { displayLocation } from '@/Helpers/location.helper';
import { locationAPI } from '@/services';

export default {
  components: {
    horseTable,
  },
  data: () => ({
    locations: [],
    headers: [
      { text: 'Naam', value: 'name', selected: true },
      { text: 'Brandnummer', value: 'surrogate_uid', sortable: false, selected: false },
      { text: 'Type', value: 'type', sortable: false, selected: true },
      { text: 'Locatie', value: 'location', selected: true },
      { text: 'Eigenaar', value: 'owner', selected: true },
      { text: 'Draagmoeder', value: 'surrogate', selected: false },
      { text: 'Dekhengst', value: 'stud_horse', selected: false },
      { text: 'Microchip', value: 'microchip', selected: false },
      { text: 'Aangemaakt op', align: 'end', value: 'createdAt', selected: false },
      { text: 'Gewijzigd op', align: 'end', value: 'updatedAt', selected: true },
      { text: 'Geboortedatum', align: 'end', value: 'date_of_birth', selected: false },
    ],
    sortBy: 'updatedAt',
  }),
  computed: {
    filters() {
      return {
        type: {
          vTag: 'select',
          label: 'Filter op geslacht',
          items: ['Merrie', 'Hengst'],
        },
        owner: { vTag: 'searchCustomer' },
        location: {
          vTag: 'select',
          label: 'Filter op locatie',
          items: this.locations,
          objectItems: {
            text: (item) => displayLocation(item),
            value: '_id',
          },
        },
        surrogate: {
          vTag: 'switch',
          label: 'Toon enkel draagmoeders',
          hideIf: {
            target: 'stud_horse',
            condition: 'true',
          },
        },
        stud_horse: {
          vTag: 'switch',
          label: 'Toon enkel dekhengsten',
          hideIf: {
            target: 'surrogate',
            condition: 'true',
          },
        },
      };
    },
  },
  mounted() { this.getLocations(); },
  methods: {
    async getLocations() {
      try {
        const { data: { locations } } = await locationAPI.getLocations();
        this.locations = locations;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      }
    },
  },
};
</script>
