
import Vue from 'vue';

export default Vue.extend({
  name: 'AvailableLocationsTable',
  props: {
    stable: { type: String, default: '' },
    availableLocations: { type: Array, default: () => ([]) },
    loading: { type: Boolean, default: false },
  },
  data: () => ({
    headers: [
      { text: 'Naam locatie', value: 'name', sortable: false },
      { text: 'Vrije plaatsen', value: 'remainingPlaces', sortable: false },
    ],
  }),
});
