<template>
  <v-dialog
    v-model="dialog"
    max-width="690"
    persistent
  >
    <v-card>
      <v-card-title>Product aanpassen - type: {{ mod.type }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row class="ml-5 mr-5">
              <v-col cols="6">
                <v-select
                  v-model="mod.type"
                  :rules="required"
                  :items="modsTypes"
                  disabled
                  label="type *"
                  :loading="loading"
                  :menu-props="{ offsetY: true }"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="mod.amount"
                  min="0"
                  :rules="requiredNumber"
                  type="number"
                  label="aantal *"
                  :loading="loading"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="mod.type === 'Verkoop'"
              class="ml-5 mr-5"
            >
              <v-col>
                <search-customer
                  :customer="mod.client"
                  :required="true"
                  :clearable="true"
                  @emit-customer="updateCustomer"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="mod.type === 'Toediening'"
              class="ml-5 mr-5"
            >
              <v-col>
                <search-horse
                  :horse="mod.horse"
                  :required="true"
                  :clearable="true"
                  @emit-horse="updateHorse"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          text
          @click="close"
        >
          Annuleer
        </v-btn>
        <v-btn
          color="success"
          :disabled="!valid"
          text
          @click="edit"
        >
          Opslaan
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, requiredNumber } from '@/Helpers';
import { configAPI, stockAPI } from '@/services';
import SearchHorse from '@/components/search/SearchHorse';
import SearchCustomer from '@/components/search/SearchCustomer';

export default {
  name: 'CreateStockBatchDialog',
  components: { SearchHorse, SearchCustomer },
  props: {
    productId: { type: String, required: true },
    dialog: { type: Boolean, default: false },
    currentBatch: { type: Object, default: () => ({}) },
  },
  data: () => ({
    valid: false,
    mod: {},
    modsTypes: [],
    loading: false,
  }),
  computed: { required, requiredNumber },
  watch: {
    currentBatch: {
      deep: true,
      handler() { this.mod = this.currentBatch; },
    },
    dialog() { if (!this.modsTypes.length) this.getModsConfig(); },
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.$emit('close', false);
    },
    updateCustomer(searchResult) { this.mod.client = searchResult ? searchResult._id : undefined; },
    updateHorse(searchResult) { this.mod.horse = searchResult ? searchResult._id : undefined; },
    async edit() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        this.loading = true;
        await stockAPI.putStock(this.productId, { ...this.mod }, this.mod.id);
        this.$emit('edited', true);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      } finally {
        this.close();
        this.selectedBatches = [];
        this.loading = false;
      }
    },
    async getModsConfig() {
      try {
        const { data: { types } } = await configAPI.getModConfig();
        this.modsTypes = types;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      }
    },
  },
};
</script>
