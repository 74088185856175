import yearlyReportAPI from '../../services/YearlyReportAPI';

export const yearlyReport = {
  namespaced: true,

  state: () => ({
    year: 0,
    semenByStallion: {
      data: [],
      year: undefined,
      loading: false,
    },
    semenByBreed: {
      data: [],
      year: undefined,
      loading: false,
    },
    ovumByBreed: {
      data: [],
      year: undefined,
      loading: false,
    },
    icsiEmbryoByBreed: {
      data: [],
      year: undefined,
      loading: false,
    },
    rinsedEmbryoByBreed: {
      data: [],
      year: undefined,
      loading: false,
    },
  }),

  actions: {
    setYear({ commit }, { year }) {
      commit('setStateYear', { year });
    },
    async getSemenByStallion({ commit, state }) {
      try {
        commit('setStateSemenByStallion', { loading: true, data: [], year: state.year });
        const result = await yearlyReportAPI.semenByStallion({ year: state.year });
        commit('setStateSemenByStallion', { loading: false, data: result.data, year: state.year });
      } catch (error) {
        // Set error
        commit('setStateSemenByStallion', { loading: false, data: [] });
      }
    },
    async getSemenByBreed({ commit, state }) {
      try {
        commit('setStateSemenByBreed', { loading: true, data: [], year: state.year });
        const result = await yearlyReportAPI.semenByBreed({ year: state.year });
        commit('setStateSemenByBreed', { loading: false, data: result.data, year: state.year });
      } catch (error) {
        // Set error
        commit('setStateSemenByBreed', { loading: false, data: [] });
      }
    },
    async getOvumByBreed({ commit, state }) {
      try {
        commit('setStateOvumByBreed', { loading: true, data: [], year: state.year });
        const result = await yearlyReportAPI.ovumByBreed({ year: state.year });
        commit('setStateOvumByBreed', { loading: false, data: result.data, year: state.year });
      } catch (error) {
        // Set error
        commit('setStateOvumByBreed', { loading: false, data: [] });
      }
    },
    async getIcsiEmbryoByBreed({ commit, state }) {
      try {
        commit('setStateIcsiEmbryoByBreed', { loading: true, data: [], year: state.year });
        const result = await yearlyReportAPI.icsiEmbryoByBreed({ year: state.year });
        commit('setStateIcsiEmbryoByBreed', { loading: false, data: result.data, year: state.year });
      } catch (error) {
        // Set error
        commit('setStateIcsiEmbryoByBreed', { loading: false, data: [] });
      }
    },
    async getRinsedEmbryoByBreed({ commit, state }) {
      try {
        commit('setStateRinsedEmbryoByBreed', { loading: true, data: [], year: state.year });
        const result = await yearlyReportAPI.rinsedEmbryoByBreed({ year: state.year });
        commit('setStateRinsedEmbryoByBreed', { loading: false, data: result.data, year: state.year });
      } catch (error) {
        // Set error
        commit('setStateRinsedEmbryoByBreed', { loading: false, data: [] });
      }
    },
  },

  mutations: {
    setStateYear(state, { year }) {
      state.year = year;
    },
    setStateSemenByStallion(state, { loading, data, year }) {
      state.semenByStallion.loading = loading;
      state.semenByStallion.data = data;
      state.semenByStallion.year = year;
    },
    setStateSemenByBreed(state, { loading, data, year }) {
      state.semenByBreed.loading = loading;
      state.semenByBreed.data = data;
      state.semenByBreed.year = year;
    },
    setStateOvumByBreed(state, { loading, data, year }) {
      state.ovumByBreed.loading = loading;
      state.ovumByBreed.data = data;
      state.ovumByBreed.year = year;
    },
    setStateIcsiEmbryoByBreed(state, { loading, data, year }) {
      state.icsiEmbryoByBreed.loading = loading;
      state.icsiEmbryoByBreed.data = data;
      state.icsiEmbryoByBreed.year = year;
    },
    setStateRinsedEmbryoByBreed(state, { loading, data, year }) {
      state.rinsedEmbryoByBreed.loading = loading;
      state.rinsedEmbryoByBreed.data = data;
      state.rinsedEmbryoByBreed.year = year;
    },
  },
};
