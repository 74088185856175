
import { formatDate, printName } from '@/Helpers';
import notificationAPI from '@/services/NotificationAPI';
import Vue from 'vue';

export default Vue.extend({
  name: 'ToExpireLabsBlock',
  data: () => ({
    headers: [
      { text: 'Labo', value: 'name', sortable: false },
      { text: 'Paard', value: 'horse', sortable: false },
      { text: 'Klant', value: 'owner', sortable: false },
      { text: 'Vervaldatum', value: 'expirationDate', sortable: false },
      { sortable: false },
    ],
    labNotifications: [],
  }),
  beforeMount() {
    this.getNotifications();
  },
  methods: {
    formatDate,
    printName,
    async getNotifications() {
      try {
        const { data: { results } } = await notificationAPI.getAll({
          active: true,
          'sortBy[]': 'expirationDate',
          'sortDesc[]': false,
          limit: 5,
        });

        this.labNotifications = results;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setErrorMessage', error);
      }
    },
    async deleteNotification(notification) {
      try {
        await notificationAPI.put({ ...notification, active: false });
        await this.getNotifications();
      } catch (error) {
        await this.$store.dispatch('errorMessage/setErrorMessage', error);
      }
    },
  },
});
