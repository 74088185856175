<template>
  <v-dialog
    v-model="infoDialog"
    persistent
    max-width="850px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ selectedLocation.stable }}: {{ selectedLocation.name }}</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="selectedLocation.horses"
        >
          <template #no-data>
            Geen paarden gevonden
          </template>
          <template #item="props">
            <tr
              class="clickable"
              @click="openDetailPage(props.item._id)"
            >
              <td>
                {{ props.item.name }}
              </td>
              <td>
                {{ props.item.type }}
              </td>
              <td>
                {{ props.item.surrogate_uid }}
              </td>
              <td>
                {{ props.item.microchip }}
              </td>
              <td>
                {{ ownerName(props.item.owner) }}
              </td>
              <td>
                {{ formatDate(props.item.date_of_birth) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          Sluiten
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { formatDate, ownerName } from '@/Helpers';

export default {
  name: 'LocationInfoDialog',
  props: {
    infoDialog: { type: Boolean },
    selectedLocation: { type: Object, default: undefined },
  },
  data: () => ({
    headers: [
      { text: 'Naam', value: 'name' },
      { text: 'Type', value: 'type', sortable: false },
      { text: 'Brandnummer', value: 'surrogate_uid' },
      { text: 'Microchip', value: 'microchip' },
      { text: 'Eigenaar', value: 'owner' },
      {
        text: 'Geboortedatum',
        value: 'date_of_birth',
      },
    ],
  }),
  methods: {
    formatDate,
    ownerName,
    close() {
      this.$emit('close', false);
    },
    openDetailPage(id) {
      document.body.style.cursor = 'default';
      this.$router.push(`/horse/${id}`);
    },
  },
};
</script>
