<template>
  <div>
    <tag
      v-for="(param) in data.parameters"
      :key="param._id"
      :text="generateHighlightText(param)"
      background-color="indigo lighten-5"
      :normal-size="true"
      :no-ripple="true"
      color="primary"
    />
  </div>
</template>

<script>
import { horseName, semenDescription } from '@/Helpers';
import Tag from '@/components/common/Tag';

export default {
  components: { Tag },
  props: {
    data: { type: Object, default: () => ({}) },
  },
  methods: {
    generateHighlightText(param) {
      switch (param.elementModel.toLowerCase()) {
      case 'embryo':
        return `Embryo: ${horseName(param.element.donor_mare)} x ${horseName(param.element.donor_stallion)} / ${param.element.code}`;
      case 'semencollection':
        return `Sperma: ${semenDescription(param.element, true)}`;
      default:
        return `${this.checkType(param)}: ${horseName(param.element)}`;
      }
    },
    checkType(param) {
      switch (param.elementType) {
      case 'Merrie':
        return param.elementSpecification ? 'Draagmoeder' : 'Merrie';
      case 'Hengst':
        return param.elementSpecification ? 'Dekhengst' : 'Hengst';
      default:
        return '';
      }
    },
  },
};
</script>
