<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title> Embryos van {{ ownerName(owner) }} </v-toolbar-title>
    </v-toolbar>

    <v-row class="mr-5 ml-2 d-flex align-center">
      <v-col>
        <date-picker
          label="Van"
          mode="date"
          :date="dateRange.from"
          dense
          outlined
          clearable
          hide-details
          close-on-input
          :max="dateRange.to"
          @select-date="updateDate('from', $event)"
        />
      </v-col>
      -
      <v-col>
        <date-picker
          label="Tot"
          mode="date"
          :date="dateRange.to"
          dense
          outlined
          clearable
          hide-details
          close-on-input
          :min="dateRange.from"
          @select-date="updateDate('to', $event)"
        />
      </v-col>
      <v-spacer />
      <v-col>
        <search-horse
          :id="$route.query.horse"
          clearable
          outlined
          dense
          @emit-horse="updateSearch('horse', $event)"
        />
      </v-col>
      <columns
        :headers="headers"
        @emit-headers="filteredHeaders = $event"
      />
    </v-row>

    <v-data-table
      v-model="selected"
      item-key="_id"
      class="ma-5"
      :headers="filteredHeaders"
      :items="costs"
      :server-items-length="totalCosts"
      :options.sync="options"
      :loading="loading"
      :sort-by="options.sortBy"
      :sort-desc="options.sortDesc"
      loading-text="Bezig met laden..."
      show-select
      :single-select="disabledCount === costs?.length"
      @toggle-select-all="selectAllToggle"
    >
      <template #no-data>
        Geen kosten in de database
      </template>
      <template #item="props">
        <tr>
          <td>
            <v-checkbox
              class="ma-0 py-0"
              hide-details
              dense
              :readonly="!!props.item.billed"
              :disabled="!!props.item.billed"
              :input-value="props.isSelected"
              @change="props.select($event)"
              @click.stop="(event) => event.stopPropagation()"
            />
          </td>
          <td v-if="showColumn('embryo.code')">
            {{ props.item.embryo.code }}
          </td>
          <td v-if="showColumn('donor_mare')">
            {{ props.item.donor_mare.name }}
          </td>
          <td v-if="showColumn('donor_stallion')">
            {{ props.item.donor_stallion.name }}
          </td>
          <td v-if="showColumn('from')">
            {{ formatDate(props.item.from) }}
          </td>
          <td v-if="showColumn('to')">
            {{ formatDate(props.item.to) }}
          </td>
          <td v-if="showColumn('cost')">
            {{ formatPrice(props.item.cost) }}
          </td>
          <td
            v-if="showColumn('status')"
            class="text-end"
          >
            <Tag
              :normal-size="true"
              :color="props.item.billed ? 'grey' : 'success'"
              :text="props.item.billed ? 'Gefactureerd' : 'Factureerbaar'"
            />
          </td>
        </tr>
      </template>
    </v-data-table>

    <invoice-dialog
      v-if="dialog"
      :dialog="dialog"
      :selected="selected"
      type="ICSI"
      @executed="stockBilled"
      @close="toggleDialog($event)"
    />
    <snackbar
      :snackbar="snackbar"
      :color="snackColor"
      :text="snackText"
      @timedout="snackbar = $event"
    />
  </div>
</template>

<script>
import Columns from '@/components/filters/Columns.vue';
import { compareRouteAndPush, formatAddress, ownerName, formatPrice, formatDate } from '@/Helpers';
import { storageAPI } from '@/services';
import Snackbar from '@/components/common/Snackbar';
import DatePicker from '@/components/DateSelector/DatePicker.vue';
import Tag from '@/components/common/Tag';
import SearchHorse from '@/components/search/SearchHorse';
import InvoiceDialog from '@/components/StorageCosts/InvoiceDialog.vue';

export default {
  name: 'IcsiCostsDetailsTable',
  components: {
    Columns,
    SearchHorse,
    DatePicker,
    Tag,
    InvoiceDialog,
    Snackbar,
  },
  props: {
    dialog: { type: Boolean, default: false },
  },
  data: (data) => ({
    owner: null,
    horse: null,
    costs: [],
    totalCosts: 0,
    loading: false,
    options: {
      page: Number(data.$route.query.page) || 1,
      limit: Number(data.$route.query.limit) || 10,
    },
    headers: [
      { text: 'Code', value: 'embryo.code', selected: true },
      { text: 'Donor Merrie', value: 'donor_mare', selected: true },
      { text: 'Donor Hengst', value: 'donor_stallion', selected: true },
      { text: 'Van', value: 'from', selected: true },
      { text: 'Tot', value: 'to', selected: true },
      { text: 'Kost', value: 'cost', selected: true },
      { text: 'Status', value: 'status', selected: true, align: 'center' },
    ],
    filteredHeaders: [],
    selected: [],
    dateRange: {
      from: data.$route.query.from || null,
      to: data.$route.query.to || null,
    },
    snackbar: false,
    snackColor: '',
    snackText: '',
  }),
  computed: {
    disabledCount() {
      let count = 0;
      this.costs.forEach((cost) => {
        if (cost.billed) count += 1;
      });
      return count;
    },
    startOfTheYear() {
      const date = new Date();
      return new Date(date.getFullYear(), 0, 1);
    },
  },
  watch: {
    selected() {
      this.$emit('select', this.selected?.length || 0);
    },
    options: {
      deep: true,
      immediate: true,
      handler() {
        this.compareRouteAndPush(
          {
            ...this.$route.query,
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            from: this.dateRange.from || undefined,
            to: this.dateRange.to || undefined,
          },
          this.$route,
          this.$router,
          true,
        );
      },
    },
    '$route.query': {
      deep: true,
      handler() {
        if (!this.$route.query.page && !this.$route.query.limit) {
          this.$router.replace({
            query: {
              page: 1,
              limit: this.options.itemsPerPage,
              sortBy: this.options.sortBy,
              sortDesc: this.options.sortDesc,
              ...this.$route.query,
            },
          });
        } else this.getICSIStockageDetail();
      },
    },
  },
  mounted() {
    if (!this.dateRange.from && !this.$route.query.from) {
      this.dateRange.from = this.startOfTheYear.toISOString();
    }
  },
  methods: {
    formatPrice,
    formatAddress,
    formatDate,
    ownerName,
    compareRouteAndPush,
    selectAllToggle(props) {
      const availableItems = props.items.filter((item) => !item.billed);

      if (availableItems.every((item) => this.selected.some((s) => s._id === item._id))) {
        const { selected } = this;
        this.selected = selected.filter((item) => !availableItems?.some((avItem) => avItem._id === item._id));
      } else {
        this.selected = [...this.selected, ...availableItems];
      }
    },
    toggleDialog(state) {
      this.$emit('toggle-dialog', state);
    },
    stockBilled() {
      this.showSnackbar('success', 'Factuur is succesvol opgemaakt!');
      this.toggleDialog(false);
      this.selected = [];
      this.getICSIStockageDetail();
    },
    showSnackbar(color, text) {
      this.snackbar = true;
      this.snackColor = color;
      this.snackText = text;
    },
    showColumn(col) {
      return this.headers.find((header) => header.value === col) ? this.headers.find((header) => header.value === col).selected : undefined;
    },
    updateDate(key, date) {
      this.dateRange[key] = date;
      this.compareRouteAndPush(
        {
          ...this.$route.query,
          [key]: date || undefined,
          page: 1,
        },
        this.$route,
        this.$router,
      );
    },
    updateSearch(key, value) {
      if (key === 'horse') this.horse = value;
      this.compareRouteAndPush(
        {
          ...this.$route.query,
          [key]: value ? value._id : undefined,
          page: 1,
        },
        this.$route,
        this.$router,
      );
    },
    async getICSIStockageDetail() {
      try {
        // remove empty values from query
        const query = Object.entries(this.$route.query).reduce((acc, [key, value]) => {
          // eslint-disable-next-line no-param-reassign
          if (value) acc[key] = value;
          return acc;
        }, {});

        const {
          data: { results, total, owner },
        } = await storageAPI.getAllICSICasesByOwner({
          ...query,
          storageOwner: this.$route.params.id,
        });
        this.costs = results;
        this.owner = owner;
        this.totalCosts = total;
        await this.$store.dispatch('errorMessage/resetError');
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
  },
};
</script>
