import { get, post, put, deleteR } from '@/services/API';

export default {
  postLabo: (labo) => post('/api/labo', labo),
  getAllResults: (params) => get(`/api/labo?${new URLSearchParams(params)}`),
  getLabo: (id) => get(`/api/labo/${id}`),
  putLabo: (labo) => put(`/api/labo/${labo._id}`, labo),
  deleteLabo: (id) => deleteR(`/api/labo/${id}`),
  searchLabo: (searchValue, params) => get(`/api/labo/search?searchValue=${searchValue}&${new URLSearchParams(params)}`),
};
