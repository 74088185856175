<template>
  <v-layout
    fill-height
    column
  >
    <div :class="[isPrint ? 'print-only' : 'ma-5']">
      <v-data-table
        :items="ovumByBreed.data"
        :headers="subheaders"
        :options.sync="options"
        :loading="ovumByBreed.loading"
        :hide-default-footer="hideFooter"
        :disable-pagination="disablePagination"
        hide-default-header
      >
        <template #no-data>
          Geen data beschikbaar
        </template>
        <template #header>
          <thead>
            <tr>
              <th
                v-for="head in headers"
                :key="head.value"
                :colspan="head.colspan"
              >
                {{ head.text }}
              </th>
            </tr>
            <tr>
              <th
                v-for="subheader in subheaders"
                :key="subheader.value"
              >
                {{ subheader.text }}
              </th>
            </tr>
          </thead>
        </template>
        <template #item="props">
          <tr>
            <td
              v-for="subheader of subheaders"
              :key="subheader.value"
            >
              <template v-if="subheader.value === 'breed'">
                {{ props.item[subheader.value] || 'Onbekend' }}
              </template>
              <template v-else>
                {{ props.item[subheader.value] }}
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-layout>
</template>

<script>
import { generateCsvData } from '@/Helpers/yearly-report.helper';

export default {
  props: {
    isPrint: { type: Boolean, default: true },
    disablePagination: { type: Boolean, default: true },
    hideFooter: { type: Boolean, default: true },
  },
  data: (data) => ({
    options: {
      page: 1,
      itemsPerPage: data.disablePagination ? -1 : 10,
    },
    ovumByBreed: data.$store.state.yearlyReport.ovumByBreed,
    headers: [
      { value: 'mares', text: 'Merries', colspan: 2 },
      { value: 'gain', text: 'Eicellen gewonnen/ontvangen', colspan: 2 },
      { value: 'use', text: 'Eicellen gebruikt', colspan: 3 },
    ],
    subheaders: [
      { value: 'breed', text: 'Ras' },
      { value: 'horseCount', text: 'Aantal' },
      { value: 'gainedOvum', text: 'Aantal gewonnen' },
      { value: 'receivedOvum', text: 'Aantal ontvangen' },
      { value: 'frozenOvum', text: 'Aantal ingevroren' },
      { value: 'sentOvum', text: 'Aantal verzonden' },
      {
        value: 'icsiUsedOvum',
        text: 'Aantal gebruikt voor ICSI',
      },
    ],
  }),
  computed: {
    year() {
      return this.$store.state.yearlyReport.year;
    },
  },
  watch: {
    year: {
      immediate: true,
      handler(newYear) {
        if (newYear) {
          this.fetchOvumByBreed();
        }
      },
    },
    'ovumByBreed.data': {
      immediate: true,
      handler(newData) {
        const csvData = generateCsvData(this.headers, this.subheaders, newData);
        this.$emit('update-download-data', { dataUri: encodeURI(csvData), name: 'winnen-eicellen-per-ras.csv' });
      },
    },
  },
  methods: {
    async fetchOvumByBreed() {
      if (!this.ovumByBreed.data.length || this.ovumByBreed.year !== this.year) {
        this.$store.dispatch('yearlyReport/getOvumByBreed');
      }
    },
  },
};
</script>
