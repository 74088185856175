<template>
  <v-layout
    column
    fill-height
    justify-space-between
  >
    <v-card
      class="ma-5"
      outlined
    >
      <v-toolbar
        color="primary"
        flat
        dark
      >
        <v-toolbar-title>{{ capitalize(ownerName(customer)) }}</v-toolbar-title>
      </v-toolbar>
      <v-tabs v-model="tab">
        <v-tab
          key="general"
          href="#general"
          class="d-print-none"
        >
          <v-icon left>
            mdi-book-open-variant
          </v-icon>
          Algemene gegevens
        </v-tab>
        <v-tab
          v-if="customer.type === 'bedrijf'"
          key="contacts"
          href="#contacts"
          :disabled="checkId"
          class="d-print-none"
        >
          <v-icon left>
            mdi-account-box
          </v-icon>
          Contacten
        </v-tab>
        <v-tab
          key="horses"
          :disabled="checkId"
          href="#horses"
          class="d-print-none"
        >
          <v-icon left>
            mdi-horseshoe
          </v-icon>
          Paarden
        </v-tab>
        <v-tab
          key="embryos"
          :disabled="checkId"
          class="d-print-none"
          href="#embryos"
        >
          <v-icon left>
            mdi-sprout
          </v-icon>
          Embryos
        </v-tab>
        <v-tab
          key="semen"
          href="#semen"
          :disabled="checkId"
          class="d-print-none"
        >
          <v-icon left>
            mdi-water
          </v-icon>
          Sperma
        </v-tab>
        <v-tab
          key="research"
          href="#research"
          :disabled="checkId"
          class="d-print-none"
        >
          <v-icon left>
            mdi-stethoscope
          </v-icon>
          Onderzoeksfiches
        </v-tab>
        <v-tab-item
          value="general"
          class="ma-5"
        >
          <v-card flat>
            <customer-form
              :customer="customer"
              :loading="loading"
              @update-customer="updateCustomer"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item
          v-if="customer.type === 'bedrijf'"
          value="contacts"
          class="ma-5"
        >
          <v-card flat>
            <customer-contacts :customer="customer" />
          </v-card>
        </v-tab-item>
        <v-tab-item
          value="horses"
          class="ma-5"
        >
          <v-card flat>
            <customer-horses :customer="$route.params.id" />
          </v-card>
        </v-tab-item>
        <v-tab-item value="embryos">
          <embryo-table
            :customer-id="$route.params.id"
            :tab="true"
            :show-donors="true"
            :sort-by="['donor_mare', 'donor_stallion', 'code']"
            :sort-desc="[false, false, false]"
            :headers="embryoHeaders"
            :fetch="fetchEmbryos"
          />
        </v-tab-item>
        <v-tab-item value="semen">
          <semen-collection
            :customer="$route.params.id"
            :tab="true"
          />
        </v-tab-item>
        <v-tab-item
          value="research"
          class="ma-5"
        >
          <v-card flat>
            <expandable-table
              :headers="headers"
              :customer="customer._id"
              :cases="cases"
              :total-cases="totalCases"
              :loading="loading"
              :options="options"
              @update-options="options = $event"
            />
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-btn
      v-if="$route.query.tab === 'embryos' || $route.query.tab === 'semen'"
      style="width: fit-content; align-self: flex-end"
      class="mr-5 mb-5"
      depressed
      color="primary"
      @click="$router.push(`/fertility/stock-costs?type=${$route.query.tab === 'embryos' ? 'icsi' : 'semen'}&owner=${$route.params.id}`)"
    >
      Bekijk stockage kosten
    </v-btn>
  </v-layout>
</template>

<script>
import { ownerName, capitalize, compareRouteAndPush } from '@/Helpers';
import ExpandableTable from '@/components/case/ExpandableTable';
import customerAPI from '@/services/CustomerAPI.js';
import CustomerForm from '@/components/customer/Form';
import CustomerHorses from '@/components/customer/Horses';
import CustomerContacts from '@/components/customer/Contacts';
import EmbryoTable from '@/components/icsi/EmbryoTable';
import SemenCollection from '@/components/semenCollection/SemenTable';
import { CaseAPI, icsiAPI } from '@/services';

export default {
  components: { SemenCollection, EmbryoTable, CustomerForm, CustomerHorses, CustomerContacts, ExpandableTable },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data: (data) => ({
    customer: {},
    loading: false,
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || 10,
    },
    cases: [],
    totalCases: 0,
    headers: [
      { text: 'Klant', value: 'customer', selected: true },
      { text: 'Paard', value: 'horse', selected: true },
      { text: 'Extra', value: 'stallion', selected: true },
      { text: 'Aantal', value: 'length', selected: true },
      { text: 'status', value: 'status', selected: true, width: '150', align: 'end' },
      { text: 'Gewijzigd', value: 'updatedAt', selected: false },
      { text: 'Gemaakt', value: 'createdAt', align: 'end', selected: true },
    ],
    embryoHeaders: [
      { text: 'Code', value: 'code', align: 'left', selected: true },
      { text: 'Donor Merrie', value: 'donor_mare', align: 'left', selected: true },
      { text: 'Donor Hengst', value: 'donor_stallion', align: 'left', selected: true },
      { text: 'locatie & kleur', value: 'location', selected: true },
      { text: 'Eigenaar', value: 'owner', selected: true },
      { text: 'Getransfereerd op', align: 'end', value: 'transferDate', selected: false },
      { text: 'Geëxporteerd op', align: 'end', value: 'exportDate', selected: false },
      { text: 'Geïmporteerd op', align: 'end', value: 'importedAt', selected: false },
      { text: 'Laatste facturatie', align: 'end', value: 'lastInvoiceDate', selected: false },
      { text: 'Gewijzigd op', align: 'end', value: 'updatedAt', selected: false },
      { text: 'Aangemaakt op', align: 'end', value: 'createdAt', selected: false },
      { text: 'Historiek', align: 'end', value: 'action', sortable: false, class: 'd-print-none', selected: true },
    ],
  }),
  computed: {
    checkId() {
      return this.id === 'undefined';
    },
    tab: {
      set(tab) {
        if (this.$route.query.tab !== tab) {
          this.$router.replace({ query: {
            ...this.$route.query,
            hideEmpty: tab === 'semen' ? true : undefined,
            tab,
          } });
        }
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
  watch: {
    '$route.params.id': function (newId) {
      this.loadCustomer(newId);
    },
    options: {
      deep: true,
      immediate: true,
      handler() {
        this.compareRouteAndPush({
          ...this.$route.query,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        }, this.$route, this.$router);
        this.getCases(this.id);
      },
    },
  },
  mounted() {
    if (this.id !== 'undefined') {
      this.loadCustomer(this.id);
      this.getCases(this.id);
    } else this.customer = {};
  },
  methods: {
    ownerName,
    capitalize,
    compareRouteAndPush,
    updateCustomer(customer) { this.customer = customer; },
    fetchEmbryos(URLparams) { return icsiAPI.getEmbryosByCustomer(this.$route.params.id, URLparams); },
    async loadCustomer(id) {
      try {
        this.loading = true;
        const { data } = await customerAPI.getCustomer(id);
        this.customer = data;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      } finally {
        this.loading = false;
      }
    },
    async getCases(customerId) {
      try {
        this.loading = true;
        const caseQuery = { ...this.$route.query };
        delete caseQuery.tab;
        const { data: { results, total } } = await CaseAPI.getAll({ customer: customerId, ...caseQuery });
        this.cases = results;
        this.totalCases = total;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="css">
@media print {
  .v-tabs-bar {
    display: none !important;
    height: 0 !important;
  }
}
</style>
