<template>
  <div>
    <v-btn
      v-if="filters"
      color="primary"
      depressed
      class="ml-4 d-print-none"
      @click="filterDialog = true"
    >
      <v-icon left>
        mdi-filter
      </v-icon>
      Filters
    </v-btn>
    <v-dialog
      v-if="filters"
      v-model="filterDialog"
      max-width="490"
    >
      <v-card>
        <v-card-title>Filters</v-card-title>
        <v-form class="px-5 pb-5">
          <v-row dense>
            <template v-for="(filter, key, idx) in filters">
              <v-col
                :key="idx"
                cols="12"
                :md="filter.cols"
              >
                <v-switch
                  v-if="filter.vTag === 'switch' && showFilter(filter.hideIf)"
                  :value="$route.query[key]"
                  :input-value="$route.query[key]"
                  :label="filter.label"
                  hide-details
                  inset
                  @change="updateUrlQuery(key, $event)"
                />
                <v-select
                  v-if="filter.vTag === 'select' && showFilter(filter.hideIf)"
                  :value="$route.query[key]"
                  :label="filter.label"
                  :items="filter.items"
                  :menu-props="{ offsetY: true }"
                  :item-text="filter.objectItems ? filter.objectItems.text : undefined"
                  :item-value="filter.objectItems ? filter.objectItems.value : undefined"
                  hide-details
                  clearable
                  :multiple="filter.options && filter.options.multiple"
                  outlined
                  @change="updateUrlQuery(key, $event)"
                />
                <basic-search
                  v-if="filter.vTag === 'basicSearch'"
                  :endpoint="filter.searchFunction"
                  :get-specific="filter.getSpecific"
                  :label="filter.label"
                  :search-key="key"
                  :outlined="true"
                  :clearable="true"
                  :format="filter.format"
                  @select="updateSearch(filter.customUrlKey || key, $event, filter.returnValue)"
                />
                <search-location
                  v-if="filter.vTag === 'searchLocation'"
                  :id="$route.query[key]"
                  @emit-location="updateSearch(key, $event)"
                />
                <location-filter
                  v-if="filter.vTag === 'locationFilter'"
                  stable="$route.query.stable"
                  prefix="$route.query.prefix"
                  @emit-stable="updateUrlQuery('stable', $event)"
                  @emit-prefix="updateUrlQuery('prefix', $event)"
                  @emit-location-name="updateUrlQuery('location-name', $event)"
                />
                <search-horse
                  v-if="filter.vTag === 'searchHorse'"
                  :id="$route.query[key]"
                  :horse-type="filter.horseType ? capitalize(filter.horseType) : undefined"
                  :clearable="true"
                  :outlined="true"
                  @emit-horse="updateSearch(key, $event)"
                />
                <search-customer
                  v-if="filter.vTag === 'searchCustomer'"
                  :id="$route.query[key]"
                  :clearable="true"
                  :outlined="true"
                  :label="filter.label"
                  @emit-customer="updateSearch(key, $event)"
                />
                <search-product
                  v-if="filter.vTag === 'searchProduct'"
                  :id="$route.query[key]"
                  :clearable="true"
                  :outlined="true"
                  @emit-product="updateSearch(key, $event)"
                />
                <date-picker
                  v-if="filter.vTag === 'dateRange'"
                  :label="filter.label"
                  :range="true"
                  :date="{
                    start: $route.query[filter.from],
                    end: $route.query[filter.to],
                  }"
                  :expanded="true"
                  :no-border="true"
                  :close-on-input="true"
                  :hide-details="true"
                  :outlined="true"
                  :clearable="true"
                  @select-date="updateRange(filter.from, filter.to, $event)"
                />
              </v-col>
            </template>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SearchHorse from '@/components/search/SearchHorse';
import SearchCustomer from '@/components/search/SearchCustomer';
import SearchProduct from '@/components/search/SearchProduct';
import BasicSearch from '@/components/search/BasicSearch';
import DatePicker from '@/components/DateSelector/DatePicker';
import SearchLocation from '@/components/search/SearchLocation';
import LocationFilter from '@/components/filters/LocationFilter';
import { capitalize, objectToQueryString, isEmpty, queryStringToArray } from '@/Helpers';

export default {
  components: { SearchHorse, SearchCustomer, BasicSearch, SearchProduct, DatePicker, SearchLocation, LocationFilter },
  props: {
    filters: { type: Object, required: true },
    options: {
      type: Object,
      default: () => ({
        page: Number(this.$route.query.page) || 1,
        limit: Number(this.$route.query.limit) || 10,
      }),
    },
    fetcher: { type: Function, required: true },
  },
  data: () => ({
    filterDialog: false,
  }),
  watch: {
    '$route.query': {
      deep: true,
      handler(newQuery, oldQuery) {
        if (!this.$route.query.page && !this.$route.query.limit) {
          this.$router.replace({
            query: {
              page: 1,
              limit: Number(this.options.itemsPerPage),
              sortBy: this.options.sortBy,
              sortDesc: this.options.sortDesc,
              ...this.$route.query,
            },
          });
        } else if (
          this.options.page !== 1 &&
          Object.keys(newQuery).some(
            (key) => !['page', 'limit', 'sortBy', 'sortDesc'].includes(key) && !this.areQueryValuesEqual(newQuery[key], oldQuery[key]),
          )
        ) {
          // Reset page when the filters are changed
          this.options.page = 1;
        } else {
          this.fetchData();
        }
      },
    },
  },
  methods: {
    isEmpty,
    capitalize,
    objectToQueryString,
    queryStringToArray,
    updateRange(from, to, { start, end }) {
      this.updateUrlQuery(from, start);
      this.updateUrlQuery(to, end);
    },
    updateSearch(key, value, rv) {
      let v;
      if (!value) {
        v = undefined;
      } else if (value && rv) {
        v = rv(value).toString();
      } else if (Array.isArray(value)) {
        v = value.map((val) => val._id);
      } else {
        v = value._id;
      }

      this.updateUrlQuery(key, v);
      this.updateUrlQuery('page', 1);
    },
    updateUrlQuery(key, value) {
      const query = {
        ...this.$route.query,
        [key]: value !== null || (Array.isArray(value) && value.length) ? value : undefined,
      };

      if (this.$route.fullPath === this.$router.resolve({ query }).route.fullPath) {
        return;
      }
      this.$router.push({
        path: this.$route.path,
        query,
      });
    },
    showFilter(hideIf) {
      if (hideIf) {
        if (this.$route.query[hideIf.target] === hideIf.condition) return false;
      }
      return true;
    },
    async fetchData() {
      try {
        this.$emit('fetching', true);
        const res = await this.fetcher({ ...this.$route.query, tab: undefined });
        if (res) {
          const {
            data: { results, total, ...rest },
          } = res;
          this.$emit('fetchedResults', results);
          this.$emit('fetchedTotal', total);
          this.$emit('fetchedRestProps', rest);
        } else {
          this.$emit('fetchedResults', []);
          this.$emit('fetchedTotal', 0);
          this.$emit('fetchedRestProps', {});
        }
      } catch (err) {
        console.error('Filter fetch error= ', err);
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      } finally {
        this.$emit('fetching', false);
      }
    },
    areQueryValuesEqual(value1, value2) {
      if (!value1 || !value2 || typeof value1 === 'string' || typeof value2 === 'string') {
        return value1 === value2;
      }
      return JSON.stringify(value1) === JSON.stringify(value2);
    },
  },
};
</script>
