<template>
  <v-card outlined>
    <v-toolbar
      flat
      dense
    >
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />
      <v-btn
        v-if="combinedStorage?.length"
        color="primary"
        text
        @click="selectBillable"
      >
        {{ selectAll ? 'selecteer alle stockage' : 'deselecteer alle stockage' }}
      </v-btn>
    </v-toolbar>
    <v-divider v-if="combinedStorage?.length" />
    <v-data-table
      v-if="combinedStorage?.length"
      v-model="selectedStorage"
      item-key="_id"
      class="ma-5"
      :headers="headers"
      :items="combinedStorage"
      :loading="loading"
      loading-text="Bezig met laden..."
      :items-per-page="itemsPerPage"
      :options.sync="options"
      :single-select="false"
      hide-default-header
      :hide-default-footer="hideFooter"
    >
      <template #no-data>
        Geen {{ title }} gevonden
      </template>
      <template #item="props">
        <tr @click="$router.push(`/case/${kees}?tab=${props.item._id}`)">
          <td
            style="width: 30px"
            @click.stop="(event) => event.stopPropagation()"
          >
            <v-checkbox
              class="ma-0 py-0"
              hide-details
              dense
              :disabled="props.item.billed"
              :input-value="props.isSelected"
              @change="props.select($event)"
              @click.stop="(event) => event.stopPropagation()"
            />
          </td>
          <td>
            <div
              v-if="props.item.embryo"
              class="d-flex align-center"
              style="gap: 0.5rem"
            >
              <Tag
                text="ICSI"
                background-color="secondary"
              />
              {{ props.item.embryo.code }}
            </div>
            <div
              v-else
              class="d-flex align-center"
              style="gap: 0.5rem"
            >
              <Tag
                text="SEMEN"
                background-color="secondary"
              />
              {{ props.item.semenCollection?.lotNumber || '' }}
            </div>
          </td>
          <td>van {{ formatDate(props.item.from) }} tot {{ formatDate(props.item.to) }}</td>
          <td>
            {{ formatPrice(props.item.cost) }}
          </td>
          <td class="text-end">
            <Tag
              normal-size
              :color="props.item.billed ? 'grey' : 'success'"
              :text="props.item.billed ? 'Gefactureerd' : 'Factureerbaar'"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ownerName, formatDateFull, formatDate, formatPrice } from '@/Helpers';
import Tag from '@/components/common/Tag.vue';

export default {
  components: { Tag },
  props: {
    icsiStorage: { type: Array, required: true },
    semenStorage: { type: Array, required: true },
    title: { type: String, default: 'Onderzoeksfiches' },
    itemsPerPage: { type: Number, default: undefined },
    kees: { type: String, default: '' },
    success: { type: Boolean },
    hideFooter: { type: Boolean },
  },
  data: () => ({
    options: {},
    sortBy: 'name',
    sortDesc: false,
    filters: {},
    toFilter: ['to'],
    loading: false,
    selectedStorage: [],
    selectAll: true,
    headers: [
      { text: 'Embryo', value: 'embryo' },
      { text: 'Type', value: 'type' },
      { text: 'Datums', value: 'from' },
      { text: 'Price', value: 'cost' },
      { text: 'Factureerbaar', value: 'billed' },
    ],
  }),
  computed: {
    combinedStorage() {
      return [...this.icsiStorage, ...this.semenStorage].sort((a, b) => new Date(b.from) - new Date(a.from));
    },
  },
  watch: {
    selectedStorage() {
      this.$emit('select-storage', {
        storageICSI: this.selectedStorage.filter((storage) => storage.embryo).map((storage) => storage._id),
        storageSemen: this.selectedStorage.filter((storage) => storage.semenCollection).map((storage) => storage._id),
      });
    },
    success() {
      if (this.success) {
        this.selectedStorage = [];
      }
    },
  },
  methods: {
    formatPrice,
    formatDate,
    ownerName,
    formatDateFull,
    selectBillable() {
      this.selectedStorage = this.selectAll
        ? [...this.icsiStorage.filter((rs) => !rs.billed), ...this.semenStorage.filter((storage) => !storage.billed)]
        : [];
      this.selectAll = !this.selectAll;
    },
  },
};
</script>
