import { get, post, put, deleteR } from '@/services/API';

export default {
  postProduct: (product) => post('/api/product', { product }),
  getAllProducts: (params) => get(`/api/product?${new URLSearchParams(params)}`),
  getProduct: (id) => get(`/api/product/${id}`),
  putProduct: (product) => put(`/api/product/${product._id}`, { product }),
  deleteProduct: (id) => deleteR(`/api/product/${id}`),
  searchProduct: (searchValue, params) => get(`/api/product/search?searchValue=${searchValue}&${new URLSearchParams(params)}`),
  getToExpireProducts: (params) => get(`/api/product/to-expire?${new URLSearchParams(params)}`),
};
