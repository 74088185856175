<template>
  <v-card
    class="ma-5"
    outlined
  >
    <v-toolbar
      flat
      color="primary"
      dark
    >
      <v-toolbar-title>
        {{ horse.name }}
        <v-icon v-if="horse.death">
          mdi-christianity
        </v-icon>
        <v-icon v-if="horse.stud_horse">
          mdi-arm-flex
        </v-icon>
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs
      v-model="tab"
      show-arrows
      center-active
    >
      <!-- HORSE GENERAL -->
      <v-tab
        key="general"
        href="#general"
        class="d-print-none"
      >
        <v-icon left>
          mdi-alpha-a-circle
        </v-icon>
        Algemene gegevens
      </v-tab>
      <v-tab-item
        class="ma-5"
        value="general"
        eager
      >
        <horse-form
          :horse="horse"
          :loading="loading"
          :stud="$route.query.stud"
          :surr="$route.query.surrogate"
          :type="$route.query.type"
          @update-horse="horse = $event"
        />
      </v-tab-item>

      <!-- LAB RESULTS -->
      <v-tab
        v-if="horse._id"
        key="lab"
        href="#lab"
        class="d-print-none"
      >
        <v-icon left>
          mdi-alpha-l-circle
        </v-icon>
        Labo-resultaten
      </v-tab>
      <v-tab-item value="lab">
        <lab-results
          :horse="horse"
          @update-lab="data => {
            if (data) {
              getHorse(id);
            }
          }"
        />
      </v-tab-item>

      <!-- PASSPORT -->
      <v-tab
        v-if="horse._id"
        key="passport"
        href="#passport"
        class="d-print-none"
      >
        <v-icon left>
          mdi-alpha-p-circle
        </v-icon>
        Paspoort
      </v-tab>
      <v-tab-item
        class="ma-5"
        value="passport"
      >
        <v-card flat>
          <horse-passport
            :horse="horse"
            @update-passport="horse = $event"
          />
        </v-card>
      </v-tab-item>

      <!-- EMBRYO -->
      <v-tab
        v-if="horse._id && horse.surrogate"
        key="embryo"
        href="#embryo"
        class="d-print-none"
      >
        <v-icon left>
          mdi-sprout
        </v-icon>
        Embryos
      </v-tab>
      <v-tab-item
        v-if="horse.surrogate"
        value="embryo"
      >
        <embryo-table
          :horse-id="$route.params.id"
          :show-donors="true"
          :show-inactive="true"
          :transfer="transfer"
          action-label="Transfer"
          :tab="true"
          :headers="embryoHeaders"
          :fetch="fetchEmbryos"
          :filters="filters"
        />
      </v-tab-item>

      <!-- SEMEN -->
      <v-tab
        v-if="horse._id && horse.stud_horse"
        key="semen"
        href="#semen"
        class="d-print-none"
      >
        <v-icon left>
          mdi-alpha-s-circle
        </v-icon>
        Sperma
      </v-tab>
      <v-tab-item
        v-if="horse.stud_horse"
        value="semen"
        class="ma-5"
      >
        <v-card flat>
          <semen-collection
            :id="horse._id"
            :horse="horse"
            :tab="true"
          />
        </v-card>
      </v-tab-item>

      <!-- LODGING -->
      <v-tab
        v-if="horse._id"
        key="lodging"
        href="#lodging"
        class="d-print-none"
      >
        <v-icon left>
          mdi-alpha-v-circle
        </v-icon>
        Verblijven
      </v-tab>
      <v-tab-item
        value="lodging"
        class="ma-5"
      >
        <v-card flat>
          <lodging :horse="horse" />
        </v-card>
      </v-tab-item>

      <!-- TRANSACTIONS -->
      <v-tab
        v-if="horse._id"
        key="transactions"
        href="#transactions"
        class="d-print-none"
      >
        <v-icon left>
          mdi-alpha-t-circle
        </v-icon>
        Transacties
      </v-tab>
      <v-tab-item
        class="ma-5"
        value="research"
      >
        <v-card flat>
          <expandable-table
            :headers="headers"
            :horse="horse._id"
            :cases="cases"
            :total-cases="totalCases"
          />
        </v-card>
      </v-tab-item>

      <!-- RESEARCH -->
      <v-tab
        v-if="horse._id"
        key="research"
        href="#research"
        class="d-print-none"
      >
        <v-icon left>
          mdi-stethoscope
        </v-icon>
        Onderzoeksfiches
      </v-tab>
      <v-tab-item
        class="ma-5"
        value="transactions"
      >
        <v-card flat>
          <transactions-table :horse="horse" />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>
import ExpandableTable from '@/components/case/ExpandableTable';
import horseForm from '@/components/horse/Form';
import lodging from '@/components/horse/Lodging';
import horsePassport from '@/components/horse/Passport';
import semenCollection from '@/components/semenCollection/SemenTable';
import { CaseAPI, horseAPI, icsiAPI } from '@/services';
import LabResults from '@/components/horse/LabResults';
import TransactionsTable from '@/components/horse/TransactionsTable';
import EmbryoTable from '../../components/icsi/EmbryoTable';

export default {
  components: {
    EmbryoTable,
    horseForm,
    horsePassport,
    semenCollection,
    lodging,
    ExpandableTable,
    LabResults,
    TransactionsTable,
  },
  props: {
    id: { type: String, default: '' },
  },
  data: () => ({
    horse: {
      location: {
        stable: '',
        name: '',
      },
    },
    cases: [],
    totalCases: 0,
    loading: false,
    transfer: { title: 'Transfer', type: 'Transfer', action: (type) => this.openActionDialog(type) },
    headers: [
      { text: 'Klant', value: 'customer', selected: true },
      { text: 'Paard', value: 'horse', selected: true },
      { text: 'Extra', value: 'stallion', selected: true },
      { text: 'Aantal', value: 'length', selected: true },
      { text: 'status', value: 'status', selected: true, width: '150', align: 'end' },
      { text: 'Gewijzigd', value: 'updatedAt', selected: false },
      { text: 'Gemaakt', value: 'createdAt', align: 'end', selected: true },
    ],
    embryoHeaders: [
      { text: 'Code', value: 'code', align: 'left', selected: true },
      { text: 'Donor Merrie', value: 'donor_mare', align: 'left', selected: true },
      { text: 'Donor Hengst', value: 'donor_stallion', align: 'left', selected: true },
      { text: 'Locatie & kleur', value: 'location', selected: true },
      { text: 'Eigenaar', value: 'owner', selected: true },
      { text: 'Getransfereerd op', align: 'end', value: 'transferDate', selected: false },
      { text: 'Geëxporteerd op', align: 'end', value: 'exportDate', selected: false },
      { text: 'Geïmporteerd op', align: 'end', value: 'importedAt', selected: false },
      { text: 'Gewijzigd op', align: 'end', value: 'updatedAt', selected: false },
      { text: 'Aangemaakt op', align: 'end', value: 'createdAt', selected: false },
      { text: 'Historiek', align: 'end', value: 'action', sortable: false, class: 'd-print-none', selected: true },
    ],
  }),
  computed: {
    tab: {
      set(tab) {
        if (this.$route.query.tab !== tab) {
          this.$router.replace({ query: {
            ...this.$route.query,
            inactive: this.horse.surrogate ? true : undefined,
            hideEmpty: this.horse.stud_horse ? true : undefined,
            tab,
          } });
        }
      },
      get() {
        return this.$route.query.tab;
      },
    },
    filters() {
      return {
        donor_mare: { vTag: 'searchHorse', horseType: 'Merrie' },
        donor_stallion: { vTag: 'searchHorse', horseType: 'Hengst' },
      };
    },
  },
  watch: {
    '$route.params.id': function (newId) {
      if (newId && newId !== 'create') {
        this.getHorse(newId);
      }
    },
    '$route.query.tab': function () {
      if (this['$route.params.id'] && this['$route.params.id'] !== 'create') {
        this.getHorse(this.$route.params.id);
      }
    },
  },
  beforeMount() {
    if (this.id && this.id !== 'create') {
      this.getHorse(this.id);
      this.getCases(this.id);
    }
  },
  methods: {
    fetchEmbryos(URLparams) { return icsiAPI.getEmbryosByHorse(this.id, URLparams); },
    async getHorse(id) {
      this.loading = true;
      try {
        const { data } = await horseAPI.getHorse(id);
        this.horse = { ...data };
        if (!this.horse.location) {
          this.horse.location = { location: { stable: '', name: '' } };
        }
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      } finally {
        this.loading = false;
      }
    },
    async getCases(horseId) {
      try {
        const { data: { results, total } } = await CaseAPI.getAll({ horse: horseId });
        this.cases = results;
        this.totalCases = total;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
  },
};
</script>
