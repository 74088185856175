import { checkSession, getAccessToken } from './auth';

const defaultConfig: RequestInit = {
  mode: 'cors', // no-cors, cors, same-origin
  headers: {},
};
const baseUrl = process.env.VUE_APP_API_BASE_URL;

const makeFetchCall = async (url: string, config: RequestInit): Promise<any> => {
  const fetchConfig: RequestInit = { ...defaultConfig, ...config };
  if (typeof config.body === 'string') {
    fetchConfig.headers['Content-Type'] = 'application/json';
  }
  fetchConfig.headers = {
    ...fetchConfig.headers,
    Authorization: `Bearer ${getAccessToken()}`,
  };
  try {
    const response = await fetch(url.startsWith('/') ? baseUrl + url : url, fetchConfig);
    if (response.ok) {
      if (response.status !== 204) {
        const data = await response.json();
        return { data };
      }
      return response;
    }
    const data = await response.json();
    throw { statusCode: response.status, ...data };
  } catch (err) {
    if (err.status === 401 && !err.config._retry) {
      try {
        await checkSession();
        return makeFetchCall(url, {
          ...err.config,
          _retry: true,
        });
      } catch (e) {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

export const get = async (url: string, config?: RequestInit) =>
  makeFetchCall(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    ...config,
  });

export const post = async (url: string, data: any, config?: RequestInit) =>
  makeFetchCall(url, {
    method: 'POST',
    body: JSON.stringify(data),
    ...config,
  });

export const postMultiPart = async (url: string, data: any, config?: RequestInit) =>
  makeFetchCall(url, {
    method: 'POST',
    body: data,
    ...config,
  });

export const deleteR = async (url: string, data?: any, config?: RequestInit) =>
  makeFetchCall(url, {
    method: 'DELETE',
    body: JSON.stringify(data),
    ...config,
  });

export const put = async (url: string, data: any, config?: RequestInit) =>
  makeFetchCall(url, {
    method: 'PUT',
    body: JSON.stringify(data),
    ...config,
  });
