<template>
  <v-snackbar
    v-model="active"
    :timeout="timeout"
    :color="color"
    :bottom="!top"
    :top="top"
    outlined
    text
  >
    {{ text.message || text }}
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  props: {
    top: Boolean,
    snackbar: Boolean,
    color: { type: String, default: 'success' },
    text: { type: [String, Object], required: true },
  },
  data: () => ({
    active: false,
    timeout: 6000,
  }),
  watch: {
    snackbar() {
      this.active = this.snackbar;
      setTimeout(() => { this.$emit('timedout', false); }, this.timeout);
    },
  },
};
</script>
