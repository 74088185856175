import { get, post } from '@/services/API';

const handleUnauthorized = (error) => {
  if (error.statusCode === 401) {
    get('/api/teamleader/authorizeUrl').then((response) => {
      window.location.href = response.data.url;
    });
  }
};

export const getTL = async (url: string, config?: RequestInit) => {
  try {
    const response = await get(url, config);
    return response;
  } catch (error) {
    handleUnauthorized(error);
    return {};
  }
};

export const postTL = async (url: string, data: any, config?: RequestInit) => {
  try {
    const response = await post(url, data, config);
    return response;
  } catch (error) {
    handleUnauthorized(error);
    return {};
  }
};

export default {
  postInvoice: (kees, sheets) => postTL(`/api/teamleader/invoiceCase/${kees._id}`, sheets),
  postSemenInvoice: (selected) => postTL('/api/teamleader/invoiceSemen', { selected }),
  postICSIInvoice: (selected) => postTL('/api/teamleader/invoiceICSI', { selected }),
  syncCustomers: (type) => getTL(`/api/teamleader/${type}`),
  syncAccounts: () => getTL('/api/teamleader/ledgers'),
  postReceivedCode: (code) => post('/api/teamleader/receiveCode', { code }),
  getAuthUrl: () => get('/api/teamleader/authorizeUrl'),
};
