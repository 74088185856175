<template>
  <div>
    <v-row
      v-for="(parameter, idx) in protocol.parameters"
      :key="parameter.elementModel + '-' + idx"
      class="d-flex justify-center"
    >
      <span class="mt-5">{{ printTitle(parameter.elementModel) }}</span>
      <v-col
        v-if="parameter.elementModel === 'Customer'"
        cols="12"
      >
        <search-customer
          :clearable="true"
          :outlined="true"
          :dense="true"
          @emit-customer="customer => {
            selectedCustomer = customer;
            parameter.element = customer;
            $emit('emit-select', !!parameter.element);
          }"
        />
      </v-col>
      <v-col
        v-if="parameter.elementModel === 'Horse'"
        cols="12"
      >
        <search-horse
          :customer="selectedCustomer"
          :horse-type="parameter.elementType || undefined"
          :surrogate-only="parameter.elementType === 'Merrie' && parameter.elementSpecification"
          :stud-only="parameter.elementType === 'Hengst' && parameter.elementSpecification"
          :clearable="true"
          :outlined="true"
          :dense="true"
          @emit-horse="horse => {
            parameter.element = horse;
            $emit('emit-select', !!parameter.element)
          }"
        />
      </v-col>
      <select-semen-form
        :parameter="parameter"
        :check-type="checkType"
        @select-param="select($event, parameter)"
      />
      <v-col
        v-if="parameter.elementModel === 'SemenCollection' && idx === protocol.parameters.length -1"
        cols="12"
        class="pt-0"
      >
        <v-btn
          color="blue darken-1"
          text
          block
          @click="addCollectionParam()"
        >
          Voeg spermalot toe
        </v-btn>
      </v-col>
      <v-col
        v-if="parameter.elementModel === 'Embryo'"
        cols="12"
        class="pt-0"
      >
        <search-horse
          :horse-type="checkType(parameter.elementModel)"
          :clearable="true"
          :show-stock="true"
          :outlined="true"
          :dense="true"
          @emit-horse="selectedDonor = $event"
        />
      </v-col>
      <v-col
        v-if="parameter.elementModel === 'Embryo'"
        cols="12"
        class="pt-0"
      >
        <v-select
          v-model="selected"
          label="Embryo selecteren"
          :items="embryos"
          item-value="_id"
          :item-text="item => embryoDescription(item)"
          no-data-text="Geen embryos in stock van deze donor"
          :menu-props="{ offsetY: true, closeOnContentClick: true }"
          :disabled="selectedDonor && !selectedDonor.name"
          :loading="elementLoading"
          return-object
          hide-details
          clearable
          outlined
          dense
        >
          <template #item="{ item }">
            <v-list-item @click="()=>select(item, parameter)">
              <v-list-item-content>
                <v-list-item-title>{{ embryoDescription(item) }}</v-list-item-title>
                <v-list-item-subtitle>{{ horseName(item.donor_stallion) }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ ownerName(item.owner) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SelectSemenForm from '@/components/researchSheet/SelectSemenForm';
import { icsiAPI } from '@/services';
import SearchHorse from '@/components/search/SearchHorse';
import SearchCustomer from '@/components/search/SearchCustomer.vue';
import { embryoDescription, ownerName, horseName } from '@/Helpers';

export default {
  components: { SelectSemenForm, SearchHorse, SearchCustomer },
  props: { protocol: { type: Object, required: true } },
  data: () => ({
    semen: [],
    embryos: [],
    selectedDonor: {},
    selectedCustomer: {},
    selected: null,
    elementLoading: false,
  }),
  watch: {
    protocol() {
      this.selectedDonor = {};
    },
    selectedDonor() {
      if (this.selectedDonor && this.selectedDonor.type === 'Merrie') this.getAllEmbryos(this.selectedDonor._id);
    },
  },
  methods: {
    horseName,
    ownerName,
    embryoDescription,
    select(element, parameter) {
      this.selected = element;
      // eslint-disable-next-line no-param-reassign
      parameter.element = element;
      this.$emit('emit-select', !!parameter.element);
    },
    printTitle(model) {
      switch (model) {
      case 'Horse':
        return 'Parameter: paard';
      case 'SemenCollection':
        return 'Sperma locatie';
      case 'Embryo':
        return 'Embryo locatie';
      case 'Customer':
        return 'Parameter: Klant';
      default:
        return 'Extra parameter';
      }
    },
    addCollectionParam() {
      this.$emit('push-collection', { elementModel: 'SemenCollection', element: '', elementAmount: 1 });
    },
    checkType(model) {
      if (model === 'SemenCollection') return 'Hengst';
      if (model === 'Embryo') return 'Merrie';
      return undefined;
    },
    async getAllEmbryos(id) {
      try {
        await this.$store.dispatch('errorMessage/resetError');
        this.elementLoading = true;
        const { data: { results } } = await icsiAPI.getAvailableEmbryos({ donor_mare: id });
        this.embryos = results;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      } finally {
        this.elementLoading = false;
      }
    },
  },
};
</script>

<style scoped>
button {
  background-color: #FFFFFF !important;
}
</style>
