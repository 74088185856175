<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="nitrogen.name"
                  :rules="required"
                  label="Naam vat"
                  hide-details
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="nitrogen.available_places"
                  :rules="required"
                  type="number"
                  label="Aantal kokers"
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          Annuleer
        </v-btn>
        <v-btn
          color="success"
          :disabled="!valid"
          text
          @click="save"
        >
          Opslaan
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from '@/Helpers';
import nitrogenContainerAPI from '@/services/NitrogenContainerAPI';

export default {
  name: 'NitrogenDialog',
  props: {
    edit: { type: Boolean },
    dialog: { type: Boolean },
    selectedNitrogen: { type: Object, default: undefined },
  },
  data: () => ({
    nitrogen: {},
    valid: false,
  }),
  computed: {
    required,
    formTitle() { return !this.edit ? 'Nieuwe container' : 'Container bewerken'; },
  },
  watch: {
    edit() {
      if (!this.edit) {
        this.nitrogen = {};
        this.$refs.form.resetValidation();
      }
    },
    selectedNitrogen: {
      deep: true,
      handler() { this.nitrogen = this.selectedNitrogen || {}; },
    },
  },
  methods: {
    close() { this.$emit('close', false); },
    async save() {
      try {
        this.loading = true;
        await this.$store.dispatch('errorMessage/resetError');
        if (this.edit) {
          await nitrogenContainerAPI.putNitrogenContainer(this.nitrogen);
        } else {
          await nitrogenContainerAPI.postNitrogenContainer(this.nitrogen);
        }
        this.$emit('saved', this.nitrogen);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      } finally {
        this.close();
        this.loading = false;
      }
    },
  },
};
</script>
