<template>
  <v-layout column>
    <print-header
      :title="title"
      class="print-only"
    />
    <SemenStockTotals
      v-if="!!$route.query.owner && !!$route.query.stallion"
      :initial="calculations.total_init_inventory"
      :current="calculations.total_curr_inventory"
      :reduction="calculations.total_stock_reduction"
    />
    <v-card
      class="ma-5 no-border-print"
      outlined
    >
      <v-toolbar
        flat
        class="no-print"
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-switch
          v-model="showModifications"
          class="mt-5 mr-2"
          inset
          label="Toon stockwijzigingen"
        />
        <filters
          :filters="filters"
          :options="options"
          :fetcher="fetchSemenCollections"
          @fetching="loading = $event"
          @fetchedResults="semenCollections = $event"
          @fetchedTotal="totalSemenCollections = $event"
          @fetchedRestProps="calculations = $event.calculations || {}"
        />
      </v-toolbar>
      <v-data-table
        :items="semenCollections"
        item-key="name"
        :headers="headers"
        :loading="loading"
        loading-text="Bezig met laden..."
        class="ma-5 semen-stock-table"
        :server-items-length="totalSemenCollections"
        :options.sync="options"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
      >
        <template #no-data>
          {{ noDataText }}
        </template>
        <template #item="props">
          <tr
            class="main-tr"
            :class="{'dotted': showModifications && getModificationsToShow(props.item).length}"
          >
            <td>
              <tag
                :text="props.item.type"
                :one-size="true"
              />
            </td>
            <td>{{ props.item.stallion.name }}</td>
            <td>{{ props.item.lotNumber }}</td>
            <td :colspan="showModifications ? 2 : 1">
              {{ props.item.initial_inventory }}
            </td>
            <td><b>{{ props.item.current_inventory }}</b></td>
            <td class="text-end">
              <template v-if="props.item.type === 'Productie' && props.item.production_date">
                {{ formatDate(props.item.production_date) }}
              </template>
              <template v-else-if="props.item.type === 'Import' && props.item.import_date">
                {{ formatDate(props.item.import_date) }}
              </template>
              <template v-else>
                -
              </template>
            </td>
          </tr>
          <template v-if="showModifications">
            <tr
              v-for="(modification, index) of getModificationsToShow(props.item)"
              :key="modification._id"
              class="sub-tr"
              :class="{'dotted': getModificationsToShow(props.item).length - 1 !== index }"
            >
              <td colspan="2">
                <tag
                  :text="modification.type"
                  :color="colorModType(modification.type)"
                  :one-size="true"
                />
              </td>
              <td colspan="2">
                <v-icon
                  v-if="modification.type.toLowerCase() === 'verbruik'
                    || modification.type.toLowerCase() === 'export'
                    || modification.type.toLowerCase() === 'change'"
                  left
                  small
                  style="color: #999"
                >
                  mdi-arrow-right
                </v-icon>
                {{ stockModInfo(modification) }}
              </td>
              <td colspan="2">
                {{ modification.action === 'subtract' ? '- ' : '+ ' }}{{ modification.amount }}
              </td>
              <td class="text-right">
                {{ formatDate(modification.date) }}
              </td>
            </tr>
          </template>
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
import { formatDate, horseName, ownerName, compareRouteAndPush } from '@/Helpers';
import SemenStockTotals from '@/components/report/SemenStockTotals';
import Tag from '@/components/common/Tag';
import semenAPI from '@/services/SemenAPI';
import PrintHeader from '@/components/common/PrintHeader';
import Filters from '@/components/filters/Filters';

export default {
  name: 'SemenStockTable',
  components: { Filters, Tag, SemenStockTotals, PrintHeader },
  props: {
    filters: { type: Object, required: true },
    owner: { type: [Object, undefined], default: undefined },
    stallion: { type: [Object, undefined], default: undefined },
  },
  data: (data) => ({
    loading: true,
    calculations: {},
    semenCollections: [],
    totalSemenCollections: 0,
    showModifications: false,
    sortBy: 'production_date',
    sortDesc: false,
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || -1,
    },
  }),
  computed: {
    headers() {
      return this.showModifications ?
        [
          { text: 'Stockwijziging', width: 130 },
          { text: 'Hengst', width: 200 },
          { text: 'lotnummer / info' },
          { text: 'Start stock', width: 120 },
          { text: 'Wijziging', width: 120 },
          { text: 'Resterend', width: 120 },
          { text: 'Datum', align: 'end' },
        ] :
        [
          { text: 'Stockwijziging', width: 130 },
          { text: 'Hengst', width: 200 },
          { text: 'lotnummer / info' },
          { text: 'Start stock', width: 120 },
          { text: 'Resterend', width: 120 },
          { text: 'Datum', align: 'end' },
        ];
    },
    title() {
      if (this.owner && this.stallion) {
        return `Stock rapport ${ownerName(this.owner)} & ${horseName(this.stallion)}`;
      }
      if (this.owner) {
        return `Stock rapport ${ownerName(this.owner)}`;
      }
      return 'Stock rapport';
    },
    noDataText() {
      return (!!this.$route.query.owner && !!this.$route.query.stallion)
        ? 'Geen stock gevonden'
        : 'Selecteer een klant & een paard voor het rapport';
    },
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.compareRouteAndPush({
          ...this.$route.query,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
        }, this.$route, this.$router);
      },
    },
  },
  mounted() {
    this.getSemenCollections();
  },
  methods: {
    horseName,
    ownerName,
    formatDate,
    compareRouteAndPush,
    colorModType(type) {
      switch (type) {
      case 'Verbruik':
        return 'teal';
      case 'Export':
        return 'purple';
      case 'Change':
        return 'brown';
      case 'Destroy':
        return 'red';
      case 'Damaged':
        return 'orange';
      case 'Control':
        return 'blue-grey';
      default:
        return 'primary';
      }
    },
    stockModInfo(mod) {
      if (mod.type.toLowerCase() === 'export' || mod.type.toLowerCase() === 'change') {
        return ownerName(mod.owner);
      }
      if (mod.type.toLowerCase() === 'verbruik') {
        return `${ownerName(mod.owner)} - ${horseName(mod.stallion)}`;
      }
      return '';
    },
    fetchSemenCollections(URLparams) {
      return URLparams.owner || URLparams.stallion
        ? semenAPI.getAllSemenCollection(URLparams)
        : null;
    },
    async getSemenCollections() {
      this.loading = true;
      try {
        if (this.$route.query.stallion && this.$route.query.owner) {
          const { data: { results, calculations, total } } = await semenAPI.getAllSemenCollection(this.$route.query);
          this.semenCollections = results;
          this.calculations = calculations;
          this.totalSemenCollections = total;
        }
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      } finally {
        this.loading = false;
      }
    },
    getModificationsToShow(semenCollection) {
      return semenCollection.modifications.filter((mod) => mod.type.toLowerCase() !== 'create');
    },
  },
};
</script>

<style lang="scss">
@media print {
  .v-card { margin: 0 !important; }
  .v-data-footer { display: none !important; }
  .main-tr td { height: 32px !important; }
  .sub-tr td { height: 32px !important; font-size: 12px !important; }
}

.theme--light.v-data-table.semen-stock-table > .v-data-table__wrapper > table > tbody {
  tr.sub-tr {
    background-color: #fafafa;
    td {
      height: 34px;
    }
  }
  > tr.dotted {
    td {
      border-bottom: thin dotted #E0E0E0;
    }
  }
}

</style>
