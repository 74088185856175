<template>
  <v-layout
    column
    align-center
    class="pa-2"
    style="border: 1px solid black; width: 230px;"
  >
    <h4>{{ stamp.title }}</h4>
    <span>{{ stamp.line1 }}</span>
    <span>{{ stamp.line2 }}</span>
    <span>{{ stamp.line3 }}</span>
  </v-layout>
</template>

<script>
export default {
  name: 'Stamp',
  props: {
    stamp: { type: Object,
      default: () => ({
        title: 'Hof Ter Leeuwe bv',
        line1: 'Ganzenkooi 2',
        line2: '3440 Zoutleeuw',
        line3: 'BE0441535387',
      }) },
  },
};
</script>
