<template>
  <v-chip
    label
    :small="!normalSize"
    :class="`${!normalSize ? 'mr-1' : ''} ${oneSize ? 'oneSize' : ''}`"
    :outlined="!backgroundColor"
    :color="backgroundColor ? backgroundColor : color"
    :text-color="backgroundColor ? color : color"
    :ripple="!noRipple"
  >
    {{ text }}
  </v-chip>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    text: { type: String, required: true },
    color: { type: String, default: 'primary' },
    backgroundColor: { type: String, default: undefined },
    noRipple: { type: Boolean },
    oneSize: { type: Boolean },
    normalSize: { type: Boolean },
  },
};
</script>

<style scoped>
.oneSize {
  min-width: 75px;
  justify-content: center;
}
</style>
