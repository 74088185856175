<template>
  <vc-date-picker
    v-model="datePicker"
    :model-config="modelConfig"
    :mode="mode || 'date'"
    locale="nl"
    :rules="rules"
    :is24hr="mode === 'dateTime'"
    :minute-increment="5"
    :attributes="attributes"
    :max-date="max"
    :min-date="min"
    :masks="{ title: 'MMM YYYY' }"
    :is-range="!!range"
    :class="noBorder ? 'no-border' : ''"
    :is-expanded="!!expanded"
    @input="closeOnInput ? $emit('close-menu', false) : ''"
  />
</template>

<script>
import { formatDate, formatDateFull } from '@/Helpers';

export default {
  props: {
    date: { type: [Date, String, Object], default: undefined },
    max: { type: [Date, String], default: undefined },
    min: { type: [Date, String], default: undefined },
    mode: { type: String, default: 'date' },
    range: { type: Boolean },
    noBorder: { type: Boolean },
    expanded: { type: Boolean },
    required: { type: Boolean },
    closeOnInput: { type: Boolean },
  },
  data: () => ({
    dateMenu: false,
    datePicker: new Date(),
    attributes: [
      {
        key: 'today',
        highlight: {
          color: 'gray',
          fillMode: 'light',
        },
        dates: new Date(),
      },
    ],
  }),
  computed: {
    modelConfig() {
      return {
        type: 'string',
        mask: 'iso',
        timeAdjust: this.mode !== 'dateTime' ? '12:00:00' : undefined,
      };
    },
    rules() {
      return [
        (value) => (this.required ? !!value || 'dit veld is required' : true),
      ];
    },
  },
  watch: {
    date() {
      this.datePicker = this.date;
    },
    datePicker() {
      this.$emit('select-date', this.datePicker);
    },
  },
  mounted() {
    this.datePicker = this.date;
  },
  methods: {
    formatDate,
    formatDateFull,
  },
};
</script>

<style scoped>
.no-border {
  border: unset
}
</style>
