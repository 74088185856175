<template>
  <v-dialog
    v-if="verificationDialog"
    v-model="verificationDialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <p>Bent u zeker dat u {{ item }} wil {{ action }}?</p>
        <slot name="description" />
        <v-text-field
          v-model="note"
          label="Notities"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="$emit('close', false)"
        >
          Annuleren
        </v-btn>
        <v-btn
          :color="submitColor"
          text
          @click="$emit('submit', { embryos: queue, note })"
        >
          {{ capitalize(action) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { capitalize } from '@/Helpers';

export default {
  name: 'VerificationDialog',
  props: {
    verificationDialog: {
      type: Boolean,
      required: true,
    },
    queue: Array,
    item: {
      type: String,
      default: 'dit item',
    },
    title: {
      type: String,
      default: 'Bevestiging',
    },
    action: {
      type: String,
      default: 'verwijderen',
    },
    submitColor: {
      type: String,
      default: 'error',
    },
  },
  data: () => ({
    note: '',
  }),
  methods: {
    capitalize,
  },
};
</script>
