<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-container fluid>
      <v-row dense>
        <v-col
          cols="12"
          md="12"
          sm="12"
        >
          <v-radio-group
            v-model="customer.type"
            row
            :rules="required"
          >
            <v-radio
              label="Particulier"
              value="particulier"
            />
            <v-radio
              label="Bedrijf"
              value="bedrijf"
            />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="customer.type === 'bedrijf'"
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="customer.company"
            :counter="64"
            :rules="required64"
            label="Bedrijfsnaam *"
            :loading="loading"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="customer.last_name"
            :counter="64"
            :rules="required64"
            label="Achternaam"
            :loading="loading"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="customer.first_name"
            :counter="64"
            :rules="required64"
            label="Voornaam"
            :loading="loading"
          />
        </v-col>
        <v-col
          v-if="customer.type === 'bedrijf'"
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="customer.tva"
            label="BTW-nummer"
            persistent-hint
            hint="voorbeeld: BE0123456789"
            :loading="loading"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-select
            v-model="customer.vat_liability"
            label="BTW-plichtigheid"
            :items="vatLiabllity"
            item-text="text"
            item-value="value"
            :loading="loading"
            clearable
            :menu-props="{ offsetY: true}"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-select
            v-model="customer.language"
            :rules="required"
            :items="languages"
            label="Taal *"
            :loading="loading"
            :menu-props="{ offsetY: true}"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="customer.email"
            :counter="64"
            :rules="required64"
            label="E-mail adres"
            :loading="loading"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="customer.telephone_mobile"
            :counter="16"
            label="Telefoonnummer - mobiel"
            persistent-hint
            hint="voorbeeld: +32486688502"
            :loading="loading"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="customer.telephone_fixed"
            :counter="16"
            label="Telefoonnummer - vast"
            persistent-hint
            hint="voorbeeld: +3211781113"
            :loading="loading"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="customer.addressLine"
            label="Adres Regel *"
            :loading="loading"
            :rules="required"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="customer.zip"
            :counter="16"
            label="Postcode"
            :loading="loading"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="customer.city"
            :counter="32"
            label="Gemeente"
            :loading="loading"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="customer.country"
            :rules="required"
            label="Land *"
            :loading="loading"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="customer.teamleader_id"
            label="Teamleader ID"
            :loading="loading"
          />
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-btn
          depressed
          color="primary"
          class="mr-4"
          @click="$router.back();"
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          terug
        </v-btn>
        <v-btn
          v-if="!customer._id"
          :disabled="!valid"
          color="success"
          depressed
          class="mr-4"
          @click="createCustomer()"
        >
          Klant opslaan
        </v-btn>
        <v-btn
          v-if="customer._id"
          :disabled="!valid"
          color="success"
          depressed
          class="mr-4"
          @click="updateCustomer()"
        >
          Klant bijwerken
        </v-btn>
        <v-btn
          v-if="customer._id"
          color="warning"
          depressed
          @click="deleteDialog = true"
        >
          Klant verwijderen
        </v-btn>
      </v-row>
      <v-dialog
        v-model="deleteDialog"
        persistent
        max-width="350"
      >
        <v-card>
          <v-card-title class="headline">
            Klant verwijderen?
          </v-card-title>
          <v-card-text>
            Ben je zeker dat je de klant <b>{{ customer.first_name }} {{ customer.last_name }}</b> wilt
            verwijderen? Dit kan niet meer ongedaan gemaakt worden
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="success"
              depressed
              @click="deleteDialog = false"
            >
              Annuleren
            </v-btn>
            <v-btn
              color="error"
              depressed
              @click="deleteCustomer()"
            >
              Verwijderen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <snackbar
        :snackbar="snackbar"
        color="success"
        text="Klant is succesvol opgeslaan"
        @timeout="snackbar = $event"
      />
    </v-container>
  </v-form>
</template>
<script>
import { configAPI } from '@/services';
import customerAPI from '@/services/CustomerAPI.js';
import { required, required64 } from '@/Helpers';
import { mapActions } from 'vuex';
import Snackbar from '@/components/common/Snackbar';

export default {
  components: { Snackbar },
  props: {
    customer: { type: Object, default: () => ({}) },
    loading: { type: Boolean },
  },
  emits: ['update-horse', 'close-dialog', 'update-customer'],
  data: () => ({
    deleteDialog: false,
    languages: ['NL', 'FR', 'EN'],
    snackbar: false,
    valid: false,
    vatLiabllity: [],
  }),
  computed: { required, required64 },
  mounted() { this.getCustomerConfig(); },
  methods: {
    ...mapActions({
      resetError: 'errorMessage/resetError',
      setError: 'errorMessage/setErrorMessage',
    }),
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    },
    async getCustomerConfig() {
      try {
        const { data: { vatLiability } } = await configAPI.getCustomerConfig();
        this.vatLiabllity = vatLiability;
      } catch (err) {
        await this.setError(err.message);
      }
    },
    async createCustomer() {
      await this.resetError();
      try {
        const response = await customerAPI.postCustomer(this.customer);
        this.$emit('update-horse', response.data);
        await this.$router.push(`/customer/${response.data._id}`);
        this.snackbar = true;
      } catch (err) {
        await this.setError(err.message);
      }
    },
    async deleteCustomer() {
      await this.resetError();
      try {
        await customerAPI.deleteCustomer(this.customer._id);
        this.$emit('close-dialog', false);
        await this.$router.back();
      } catch (err) {
        await this.setError(err.message);
      }
    },
    async updateCustomer() {
      await this.resetError();
      try {
        const response = await customerAPI.putCustomer(this.customer);
        this.$emit('update-customer', response.data);
        this.snackbar = true;
      } catch (err) {
        await this.setError(err.message);
      }
    },
  },
};
</script>
