<template>
  <v-card
    outlined
    class="ma-5"
  >
    <v-toolbar
      flat
      dense
      light
    >
      <v-toolbar-title>Pension informatie</v-toolbar-title>
    </v-toolbar>
    <v-divider />
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-row class="ma-5">
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="pension.name"
            :rules="required"
            label="Pensionnaam - NL *"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="pension.translations.en"
            label="Pensionnaam - EN"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="pension.translations.fr"
            label="Pensionnaam - FR"
          />
        </v-col>
      </v-row>
      <v-row class="ma-5">
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="pension.account"
            :rules="required"
            :items="accounts"
            :item-text="accountName"
            item-value="_id"
            label="Pension account *"
            :menu-props="{ offsetY: true }"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="pension.tax"
            :rules="required"
            :items="taxes"
            label="Pension tax *"
            :menu-props="{ offsetY: true }"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="pension.pricePerDay"
            :rules="required"
            type="number"
            label="Prijs per dag*"
            prefix="€"
            placeholder="0.00"
          />
        </v-col>
      </v-row>
      <v-row class="ma-5">
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="pension.availableFor"
            :items="horseTypes"
            :menu-props="{ offsetY: true }"
            label="Beschikbaar voor"
          />
        </v-col>
      </v-row>
    </v-form>
    <div class="mr-2 mb-5">
      <v-row
        justify="end"
        dense
      >
        <v-btn
          depressed
          color="primary"
          class="mr-4"
          @click="$router.back()"
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          terug
        </v-btn>
        <v-btn
          v-if="!pension._id"
          :disabled="!valid"
          color="success"
          class="mr-4"
          depressed
          @click="createPension"
        >
          <v-icon left>
            mdi-content-save-outline
          </v-icon>
          Pension opslaan
        </v-btn>
        <v-btn
          v-if="pension._id"
          :disabled="!valid"
          color="success"
          class="mr-4"
          depressed
          @click="editPension"
        >
          <v-icon left>
            mdi-content-save-outline
          </v-icon>
          Pension aanpassen
        </v-btn>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { accountName, required } from '@/Helpers';
import { pensionAPI, configAPI, accountAPI } from '@/services';

export default {
  props: ['pension', 'loading', 'disabled'],
  data: () => ({
    valid: false,
    horseTypes: [],
    taxes: [],
    accounts: [],
  }),
  computed: { required },
  created() {
    Promise.all([this.getProductConfig(), this.getAccounts(), this.getHorseTypes()]);
  },
  methods: {
    accountName,
    async handler(data) {
      this.$emit('update-pension', data);
      await this.$router.back();
    },
    async createPension() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data } = await pensionAPI.post(this.pension);
        await this.handler(data);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
    async editPension() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data } = await pensionAPI.put(this.pension);
        await this.handler(data);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
    async getHorseTypes() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data: { types } } = await configAPI.getHorseTypes();
        this.horseTypes = types;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
    async getProductConfig() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data: { tax } } = await configAPI.getProductConfig();
        this.taxes = tax;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
    async getAccounts() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data: { results } } = await accountAPI.getAllAccounts();
        this.accounts = results;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
  },
};
</script>
