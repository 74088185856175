<template>
  <v-row>
    <v-col cols="4">
      <v-select
        v-model="selectedStable"
        :items="stables"
        label="Stal"
        outlined
        clearable
        :menu-props="{ offsetY: true }"
        @change="emitStable(selectedStable)"
      />
    </v-col>
    <v-col cols="4">
      <v-select
        v-model="selectedPrefix"
        :disabled="!selectedStable"
        :items="prefixes"
        label="Prefix"
        outlined
        clearable
        :menu-props="{ offsetY: true }"
        @change="emitPrefix(selectedPrefix)"
      />
    </v-col>
    <v-col cols="4">
      <v-select
        v-model="selectedBox"
        :disabled="!selectedStable && !selectedPrefix"
        :items="boxes"
        label="Naam"
        outlined
        clearable
        :menu-props="{ offsetY: true }"
        @change="emitLocationName(selectedBox)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import { locationAPI } from '@/services';

export default {
  name: 'LocationFilter',
  props: {
    stable: { type: String, default: undefined },
    prefix: { type: String, default: undefined },
  },
  data: (data) => ({
    locations: [],
    selectedStable: data.$route.query.stable || null,
    selectedPrefix: data.$route.query.prefix || null,
    selectedBox: data.$route.query['location-name'] || null,
  }),
  computed: {
    stables() {
      return this.locations.reduce((acc, curr) => {
        if (acc.includes(curr.stable)) {
          return acc;
        }

        return [...acc, curr.stable];
      }, []);
    },
    prefixes() {
      return this.locations
        .filter((location) => location.stable === this.selectedStable)
        .reduce((acc, curr) => {
          if (acc.includes(curr.prefix)) {
            return acc;
          }

          return [...acc, curr.prefix];
        }, []);
    },
    boxes() {
      return this.locations
        .filter((location) => location.stable === this.selectedStable && location.prefix === this.selectedPrefix)
        .reduce((acc, curr) => {
          if (acc.includes(curr.name)) {
            return acc;
          }

          return [...acc, curr.name];
        }, []);
    },
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    ...mapActions({
      resetError: 'errorMessage/resetError',
      setError: 'errorMessage/setErrorMessage',
    }),
    async getLocations() {
      try {
        const {
          data: { locations },
        } = await locationAPI.getLocations();

        this.locations = locations;
      } catch (error) {
        await this.setError(error);
      }
    },
    emitStable(stable) {
      this.selectedStable = stable;

      if (!stable) {
        this.selectedPrefix = undefined;
        this.selectedBox = undefined;
        this.$emit('emit-prefix', undefined);
        this.$emit('emit-location-name', undefined);
      }

      this.$emit('emit-stable', stable);
    },
    emitPrefix(prefix) {
      this.selectedPrefix = prefix;

      if (!prefix) {
        this.selectedBox = undefined;
        this.$emit('emit-location-name', undefined);
      }

      this.$emit('emit-prefix', prefix);
    },
    emitLocationName(name) {
      this.selectedBox = name;
      this.$emit('emit-location-name', name);
    },
  },
};
</script>
