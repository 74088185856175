import { get, post, put } from '@/services/API';

export default {
  getAllStock: (params) => get(`/api/stock?${new URLSearchParams(params)}`),
  getStockMods: (params) => get(`/api/stock/mods?${new URLSearchParams(params)}`),
  getRemainingProductStock: (params) => get(`/api/stock/remaining?${new URLSearchParams(params)}`),
  getStockProduct: (id, params) => get(`/api/stock/${id}?${new URLSearchParams(params)}`),
  getStockProductMods: (id, params) => get(`/api/stock/${id}/mods?${new URLSearchParams(params)}`),
  postStock: (batch) => post('/api/stock', batch),
  putStock: (productId, data, batchId) => put(`/api/stock/${productId}/${batchId || ''}`, data),
  deactivateBatch: (batch) => put(`/api/stock/${batch}/deactivate`, undefined),
  activateBatch: (batch) => put(`/api/stock/${batch}/activate`, undefined),
};
