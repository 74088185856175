<template>
  <div>
    <v-row
      v-for="(parameter) in protocol.simple_parameters"
      :key="parameter.elementType"
      class="d-flex justify-center"
    >
      <v-col
        v-if="parameter.elementType = 'Amount'"
        cols="12"
      >
        <v-text-field
          v-model="parameter.elementAmount"
          :label="`Aantal ${parameter.elementName || ''}`"
          type="number"
          :min="1"
          outlined
          dense
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SimpleParameterForm',
  props: {
    protocol: { type: Object, default: () => ({}) },
  },
};
</script>
