import { ownerName, horseName } from './names';

export function printName(tag) {
  switch (tag.elementModel) {
  case 'Customer':
    return ownerName(tag.element);
  case 'Horse':
    return horseName(tag.element);
  case 'Product':
    return tag.element ? tag.element.name : '-';
  case 'Protocol':
    return tag.element ? tag.element.name : '-';
  default:
    return '-';
  }
}
