import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

export const today = () => {
  const date = new Date();
  date.setUTCHours(12, 0, 0, 0);
  return date;
};

const isInvalidDate = (date) => Number.isNaN(new Date(date).getDate());

export const addDays = (date, amount) => {
  const inputDate = new Date(date);
  if (!date || !amount) {
    throw new Error('Missing input');
  }
  if (isInvalidDate(date) || Number.isNaN(parseInt(amount, 10))) {
    throw new Error('Invalid input');
  }
  return inputDate.setDate(inputDate.getDate() + parseInt(amount, 10));
};

export const differenceDays = (d1, d2) => {
  if (!d1 || !d2) {
    throw new Error('Missing input');
  }
  if (isInvalidDate(d1) || isInvalidDate(d2)) {
    throw new Error('Invalid date');
  }
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  return differenceInCalendarDays(date1, date2) + 1;
};

export const calculateAge = (birth) => {
  if (!birth) return '-';
  const birthDate = new Date(birth);
  const nowDate = new Date();
  return nowDate.getFullYear() - birthDate.getFullYear();
};

const getSplittedDate = (date) => {
  const newDate = new Date(date);
  if (isInvalidDate(newDate)) {
    throw new Error('Invalid Date');
  }
  const day = `${newDate.getDate()}`.padStart(2, '0');
  const month = `${newDate.getMonth() + 1}`.padStart(2, '0');
  const year = newDate.getFullYear();
  const hour = `${newDate.getHours()}`.padStart(2, '0');
  const minutes = `${newDate.getMinutes()}`.padStart(2, '0');
  return { day, month, year, hour, minutes };
};

export const formatDate = (date) => {
  if (!date) return '';
  const { day, month, year } = getSplittedDate(date);
  return `${day}/${month}/${year}`;
};
export const formatDateDMYHM = (date) => {
  if (!date) return '';
  const { day, month, year, hour, minutes } = getSplittedDate(date);
  return `${day}/${month}/${year.toString().slice(2)} ${hour}:${minutes}`;
};

export const formatDateMDY = (date) => {
  if (!date) return '';
  const { day, month, year } = getSplittedDate(date);
  return `${month}/${day}/${year}`;
};

export const formatDateFull = (date) => {
  if (!date) return '';
  const { year, month, day, hour, minutes } = getSplittedDate(date);
  return `${day}/${month}/${year} ${hour}:${minutes}`;
};

export const formatDateRef = (date) => {
  if (!date) return '';
  const { year, month, day, hour, minutes } = getSplittedDate(date);
  return `${year}${month}${day} - ${hour}:${minutes}`;
};

export const formatDateRefMDY = (date) => {
  if (!date) return '';
  const { year, month, day } = getSplittedDate(date);
  return `${month}${day}${year}`;
};
