<template>
  <v-card flat>
    <v-toolbar flat>
      <v-spacer />
      <filters
        :filters="filters"
        :options="options"
        :fetcher="fetchStockMods"
        @fetching="loading = $event"
        @fetchedResults="mods = $event"
        @fetchedTotal="totalMods = $event"
      />
      <columns
        :headers="headers"
        @emit-headers="filteredHeaders = $event"
      />
    </v-toolbar>
    <v-data-table
      :headers="filteredHeaders"
      :items="mods"
      :server-items-length="totalMods"
      :options.sync="options"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :loading="loading"
      loading-text="Bezig met laden..."
    >
      <template #no-data>
        Geen stock modificaties voor product {{ product.name }} gevonden.
      </template>
      <template #item="props">
        <tr>
          <td v-if="showColumn('type')">
            {{ props.item.type }}
          </td>
          <td v-if="showColumn('product')">
            {{ props.item.product.name }}
          </td>
          <td v-if="showColumn('batch')">
            {{ props.item.batch ? props.item.batch.lotNumber : '-' }}
          </td>
          <td v-if="showColumn('client')">
            {{ ownerName(props.item.client) }}
          </td>
          <td v-if="showColumn('horse')">
            {{ props.item.horse ? props.item.horse.name : '-' }}
          </td>
          <td v-if="showColumn('amount')">
            {{ props.item.amount }}
          </td>
          <td
            v-if="showColumn('createdAt')"
            class="text-right"
          >
            {{ formatDate(props.item.createdAt) }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { formatDate, ownerName, compareRouteAndPush } from '@/Helpers';
import { configAPI, stockAPI } from '@/services';
import Filters from '@/components/filters/Filters';
import Columns from '@/components/filters/Columns';

export default {
  name: 'BatchModsTable',
  components: { Filters, Columns },
  props: {
    id: { type: String, default: '' },
    product: { type: Object, required: true },
  },
  data: (data) => ({
    totalMods: 0,
    mods: [],
    loading: false,
    sortBy: 'createdAt',
    sortDesc: true,
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || 10,
    },
    headers: [
      { text: 'Type', value: 'type', selected: true, sortable: true },
      { text: 'Product', value: 'product', selected: true },
      { text: 'Lot nummer', value: 'batch', selected: true },
      { text: 'Klant', value: 'client', selected: true },
      { text: 'Paard', value: 'horse', selected: true },
      { text: 'Aantal', value: 'amount', selected: true },
      { text: 'Datum', align: 'end', value: 'createdAt', selected: true },
    ],
    filteredHeaders: [],
    modsTypes: [],
  }),
  computed: {
    filters() {
      return {
        type: {
          vTag: 'select',
          label: 'Filter op modificatie',
          items: this.modsTypes,
          options: {
            multiple: true,
          },
        },
      };
    },
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.compareRouteAndPush({
          ...this.$route.query,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
        }, this.$route, this.$router);
      },
    },
  },
  mounted() {
    this.getModsConfig();
  },
  methods: {
    ownerName,
    formatDate,
    compareRouteAndPush,
    showColumn(col) { return this.headers.find((header) => header.value === col).selected; },
    async getModsConfig() {
      try {
        const { data: { types } } = await configAPI.getModConfig();
        this.modsTypes = types;
      } catch (err) {
        await this.setError(err.message);
      }
    },
    fetchStockMods(URLparams) { return stockAPI.getStockProductMods(this.id, URLparams); },
  },
};
</script>
