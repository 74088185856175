<template>
  <v-card
    :class="tab ? '' : 'mt-5 mx-5'"
    :outlined="!tab"
    :flat="tab"
  >
    <v-toolbar flat>
      <v-toolbar-title v-if="!tab">
        Embryos {{ batch_code ? `- ${batch_code}` : '' }}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-title v-if="!showDonors">
        <v-icon medium>
          mdi-gender-female
        </v-icon>
        {{ horseName(donor_mare) }} -
        <v-icon medium>
          mdi-gender-male
        </v-icon>
        {{ horseName(donor_stallion) }}
      </v-toolbar-title>
      <v-spacer />
      <button-with-menu
        :hidden="!!transfer"
        :action-menu="actionMenu"
        :selected="selectedEmbryos"
      />
      <filters
        :filters="filters"
        :options="options"
        :fetcher="fetch"
        @fetching="loading = $event"
        @fetchedResults="embryos = $event"
        @fetchedTotal="total = $event"
        @fetchedRestProps="getMetaInfo"
      />
      <columns
        :headers="headers"
        @emit-headers="filteredHeaders = $event"
      />
    </v-toolbar>
    <v-data-table
      v-model="selectedEmbryos"
      :headers="filteredHeaders"
      :items="embryos"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      loading-text="Bezig met laden..."
      class="ma-5"
      item-key="_id"
      :show-select="!transfer"
      multi-sort
    >
      <template #no-data>
        Geen Embryos gevonden
      </template>
      <template #item="props">
        <tr>
          <td v-if="!transfer">
            <v-checkbox
              class="ma-0 py-0"
              dense
              hide-details
              :input-value="props.isSelected"
              @change="props.select($event)"
            />
          </td>
          <td v-if="showColumn('code')">
            {{ props.item.code }}
            <small
              v-if="!props.item.active || props.item.damaged"
              class="inactive"
            >({{ props.item.damaged ? 'beschadigd' : 'inactief' }})</small>
          </td>
          <td v-if="showColumn('donor_mare')">
            {{ props.item.donor_mare.name || '-' }}
          </td>
          <td v-if="showColumn('donor_stallion')">
            {{ props.item.donor_stallion.name || '-' }}
          </td>
          <td v-if="showColumn('location')">
            <v-icon
              v-if="props.item.color"
              small
              class="mr-2"
              :class="strawColor(props.item.color)"
            >
              mdi-checkbox-blank-circle
            </v-icon>
            {{ props.item.location.container.name }} - Koker {{ props.item.location.tube }} -
            {{ props.item.location.position }}
          </td>
          <td v-if="showColumn('owner')">
            {{ ownerName(props.item.owner) }}
          </td>
          <td
            v-if="showColumn('transferDate')"
            class="text-right"
          >
            {{ formatDate(props.item.date_transferred) || '-' }}
          </td>
          <td
            v-if="showColumn('exportDate')"
            class="text-right"
          >
            {{ formatDate(props.item.date_exported) || '-' }}
          </td>
          <td
            v-if="showColumn('importedAt')"
            class="text-right"
          >
            {{ formatDate(props.item.date_imported) || '-' }}
          </td>
          <td
            v-if="showColumn('lastInvoiceDate')"
            class="text-right"
          >
            {{ formatDate(props.item.last_invoice_date) || '-' }}
          </td>
          <td
            v-if="showColumn('createdAt')"
            class="text-right"
          >
            {{ formatDate(props.item.createdAt) }}
          </td>
          <td
            v-if="showColumn('updatedAt')"
            class="text-right"
          >
            {{ formatDate(props.item.updatedAt) }}
          </td>
          <td
            v-if="showColumn('traces')"
            class="text-right"
          >
            <v-icon
              v-if="props.item.traces"
              color="success"
              class="mr-2"
            >
              mdi-check
            </v-icon>
          </td>

          <td
            v-if="showColumn('action')"
            class="d-print-none text-right"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  color="primary"
                  class="mr-2"
                  @click="historyDialog = props.item"
                  v-on="on"
                >
                  mdi-history
                </v-icon>
              </template>
              <span>Stockage&nbsp;historiek</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-btn
      v-if="transfer"
      fixed
      right
      bottom
      depressed
      color="primary"
      class="ma-2 white--text"
      @click="openActionDialog('Transfer')"
    >
      <v-icon left>
        mdi-plus
      </v-icon>
      {{ actionLabel }} embryo
    </v-btn>

    <action-dialog
      :dialog="actionDialog"
      :action="action"
      :selected-embryos="selectedEmbryos"
      @executed="actionExecuted"
      @close="actionDialog = $event"
    />

    <!--  DAMAGED VERIFICATION  -->
    <verification-dialog
      :verification-dialog="dialog === 'damaged'"
      :queue="selectedEmbryos"
      item="deze selectie"
      action="deactiveren"
      @submit="damaged"
      @close="dialog = null"
    />

    <!--  REACTIVATE VERIFICATION  -->
    <verification-dialog
      :verification-dialog="dialog === 'reactivate'"
      :queue="selectedEmbryos"
      :item="selectedEmbryos[0]?.code"
      action="heractiveren"
      submit-color="success"
      @submit="reactivate"
      @close="dialog = null"
    />

    <relocate-dialog
      :dialog="dialog === 'relocate'"
      :selected="selectedEmbryos"
      :relocate="relocate"
      @close="dialog = null"
    />

    <icsi-history-dialog
      v-if="historyDialog"
      :dialog="!!historyDialog"
      :embryo="historyDialog"
      dialog-type="ICSI"
      @close="closeHistoryDialog"
    />

    <snackbar
      :snackbar="snackbar"
      :color="snackColor"
      :text="snackText"
      @timedout="snackbar = $event"
    />
  </v-card>
</template>

<script>
import IcsiHistoryDialog from '@/components/icsi/HistoryDialog.vue';
import { ownerName, formatDate, horseName, backgroundColor, strawColor, compareRouteAndPush } from '@/Helpers';
import { icsiAPI } from '@/services';
import VerificationDialog from '@/components/common/VerificationDialog';
import ButtonWithMenu from '@/components/common/ButtonWithMenu';
import RelocateDialog from '@/components/common/RelocateDialog';
import ActionDialog from '@/components/icsi/ActionDialog';
import Snackbar from '@/components/common/Snackbar';
import Filters from '@/components/filters/Filters';
import Columns from '@/components/filters/Columns';

export default {
  components: {
    IcsiHistoryDialog,
    VerificationDialog,
    ActionDialog,
    Snackbar,
    RelocateDialog,
    ButtonWithMenu,
    Filters,
    Columns,
  },
  props: {
    id: { type: String, default: '' },
    horseId: { type: String, default: '' },
    customerId: { type: String, default: '' },
    transfer: { type: Object, default: undefined },
    sortDesc: { type: [Boolean, Array], default: false },
    sortBy: { type: [String, Array], default: 'code' },
    actionLabel: { type: String, default: '' },
    headers: { type: Array, required: true },
    title: { type: String, default: '' },
    showDonors: { type: Boolean },
    tab: { type: Boolean },
    filters: {
      type: Object,
      default: () => ({
        donor_mare: { vTag: 'searchHorse', horseType: 'Merrie' },
        donor_stallion: { vTag: 'searchHorse', horseType: 'Hengst' },
        inactive: { vTag: 'switch', label: 'Toon inactieve' },
      }),
    },
    fetch: { type: Function, required: true },
  },
  emits: ['submit', 'close'],
  data: (data) => ({
    dialog: null,
    executingAction: false,
    filterDialog: false,
    columnDialog: false,
    verificationDialog: false,
    relocateDialog: false,
    total: 0,
    selectedEmbryos: [],
    filteredHeaders: [],
    donor_mare: null,
    donor_stallion: null,
    batch_code: '',
    embryos: [],
    loading: true,
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || 10,
    },
    action: {
      exec: null,
      type: '',
    },
    actionDialog: false,
    snackbar: false,
    snackColor: '',
    snackText: '',
    loadingHistory: false,
    historyDialog: null,
  }),
  computed: {
    actionMenu() {
      return [
        { title: 'Export', type: 'Export', action: (type) => this.openActionDialog(type) },
        { title: 'Verander eigenaar', type: 'Verplaatsen', action: (type) => this.openActionDialog(type) },
        { title: 'Locatie wijzigen', type: 'relocate', action: (type) => this.openDialog(type) },
        {
          title: 'Beschadigd',
          type: 'damaged',
          action: (type) => this.openDialog(type),
        },
        {
          title: 'Heractivatie',
          type: 'reactivate',
          action: (type) => this.openDialog(type),
          disabled: this.selectedEmbryos?.length > 1 || this.selectedEmbryos?.some((em) => em.active),
        },
        {
          title: 'TRACES toevoegen',
          type: 'traces',
          action: () => this.addTraces(),
          disabled: this.selectedEmbryos?.some((em) => em.traces),
        },
      ];
    },
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.compareRouteAndPush(
          {
            ...this.$route.query,
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
          },
          this.$route,
          this.$router,
        );
      },
    },
  },
  methods: {
    strawColor,
    horseName,
    ownerName,
    formatDate,
    backgroundColor,
    compareRouteAndPush,
    openActionDialog(type) {
      this.actionDialog = true;
      this.action.type = type;
      if (this.action.type === 'Transfer') {
        this.action.exec = this.transferEmbryo;
      } else {
        this.action.exec = this.exportEmbryo;
      }
    },
    getMetaInfo({ donor_mare, donor_stallion, batch_code }) {
      this.donor_mare = donor_mare;
      this.donor_stallion = donor_stallion;
      this.batch_code = batch_code;
    },
    openDialog(type) {
      this.dialog = type;
    },
    closeActionDialog() {
      this.actionDialog = false;
    },
    closeHistoryDialog() {
      this.historyDialog = null;
    },
    showSnackbar(color, text) {
      this.snackbar = true;
      this.snackColor = color;
      this.snackText = text;
    },
    actionExecuted(action) {
      this.showSnackbar('success', `${action.type} succesvol.`);
      this.dialog = null;
      this.selectedEmbryos = [];
      this.getEmbryos();
    },
    showColumn(col) {
      return this.headers.find((header) => header.value === col)?.selected;
    },
    async transferEmbryo(embryoId, { date }) {
      await icsiAPI.transferEmbryo({
        embryoId,
        surrogateId: this.horseId,
        transferDate: date,
      });
      this.selectedEmbryos = [];
    },
    async exportEmbryo(embryoId, { inHouse, customer, date, note }) {
      const { data } = await icsiAPI.exportEmbryo({
        embryoId,
        inHouse,
        customerId: customer,
        exportDate: date,
        note,
      });
      this.$router.push({ path: `/icsi/${data.id}/delivery-report`, query: { embryo: this.selectedEmbryos.map((em) => em._id) } });
      this.selectedEmbryos = [];
    },
    async damaged({ embryos, note }) {
      try {
        this.loading = true;
        await Promise.all(embryos.map((embryo) => icsiAPI.deactivate(this.id, embryo, note)));
        this.dialog = null;
        await this.getEmbryos();
        await this.$store.dispatch('errorMessage/resetError');
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      } finally {
        this.loading = false;
      }
    },
    async reactivate({ embryos, note }) {
      try {
        this.loading = true;
        await Promise.all(embryos.map((embryo) => icsiAPI.reactivate(this.id, embryo, note)));
        this.dialog = null;
        await this.getEmbryos();
        await this.$store.dispatch('errorMessage/resetError');
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      } finally {
        this.loading = false;
      }
    },
    async relocate(selected) {
      try {
        this.loading = true;
        await this.$store.dispatch('errorMessage/resetError');
        await Promise.all(selected.map((batch) => icsiAPI.putICSI(batch)));
        // Verplaasen?
        this.actionExecuted({ type: 'Verplaasen' });
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      } finally {
        this.loading = false;
      }
    },
    async getEmbryos() {
      this.loading = true;
      try {
        const {
          data: { donor_mare, donor_stallion, results, total },
        } = await this.fetch(this.$route.query);
        this.donor_mare = donor_mare;
        this.donor_stallion = donor_stallion;
        this.embryos = results;
        this.total = total;
        await this.$store.dispatch('errorMessage/resetError');
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      } finally {
        this.loading = false;
      }
    },
    async addTraces() {
      try {
        this.loading = true;
        await this.$store.dispatch('errorMessage/resetError');

        await Promise.all(this.selectedEmbryos.map((embryo) => icsiAPI.putICSI({ ...embryo, traces: true })));

        this.selectedEmbryos = [];
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      } finally {
        this.loading = false;
        await this.getEmbryos();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.inactive {
  opacity: 0.5;
  font-style: italic;
}
</style>
