import { get, post, put, deleteR } from '@/services/API';

export default {
  postCustomer: (customer) => post('/api/customer', { customer }),
  getCustomers: (params) => get(`/api/customer?${new URLSearchParams(params)}`),
  searchCustomer: (searchValue, params) => get(`/api/customer/search?searchValue=${searchValue}&${new URLSearchParams(params)}`),
  getCustomer: (id) => get(`/api/customer/${id}`),
  getCustomerHorses: (id, params) => get(`/api/customer/${id}/horse?${new URLSearchParams(params)}`),
  putCustomer: (customer) => put(`/api/customer/${customer._id}`, { customer }),
  deleteCustomer: (id) => deleteR(`/api/customer/${id}`),
  addContact: (customer, contact) => post(`/api/customer/${customer._id}/contact`, { contact }),
  updateContact: (customer, contact) => put(`/api/customer/${customer._id}/contact/${contact._id}`, { contact }),
  deleteContact: (customer, contact) => deleteR(`/api/customer/${customer._id}/contact/${contact._id}`),
};
