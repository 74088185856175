<template>
  <v-autocomplete
    v-model="selected"
    :label="type === 'selecteren' ? 'Protocol selecteren' : 'Protocol zoeken'"
    :menu-props="{closeOnContentClick:true}"
    prepend-inner-icon="mdi-magnify"
    :search-input.sync="search"
    :clearable="clearable"
    :autofocus="autofocus"
    :items="items"
    item-text="name"
    item-value="_id"
    :rules="rule"
    return-object
    hide-no-data
    hide-details
    :outlined="outlined"
    :dense="dense"
    @click:clear="clear"
  >
    <template #item="{ item }">
      <v-list-item @click="emitProtocol(item)">
        <v-list-item-action>
          <v-icon>mdi-pill</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>
import { protocolAPI } from '@/services';

export default {
  props: {
    protocol: { type: [Object, String, undefined] },
    type: { type: String, default: undefined },
    id: { type: String, default: undefined },
    clearable: { type: Boolean },
    autofocus: { type: Boolean },
    outlined: { type: Boolean },
    required: { type: Boolean },
    dense: { type: Boolean },
  },
  data: () => ({
    loading: false,
    entries: [],
    search: null,
    selected: null,
  }),
  computed: {
    rule() {
      return [(value) => (this.required ? !!value || 'dit veld is required' : true)];
    },
    items() {
      if (this.selected && this.selected._id && !this.entries.length) {
        return [this.selected];
      }
      return this.entries;
    },
  },
  watch: {
    search(val) { if (val && val !== this.selected) this.querySelections(val); },
    protocol() { this.selected = this.protocol; },
    $route: {
      immediate: true,
      deep: true,
      handler() {
        if (this.id && !this.entries.length) {
          this.getProtocolById();
        }
        if (!this.id && !this.protocol) {
          this.selected = {};
          this.entries = [];
        }
      },
    },
  },
  mounted() { this.selected = this.protocol; },
  methods: {
    async querySelections(v) {
      this.loading = true;
      try {
        await this.$store.dispatch('errorMessage/resetError');
        const { data } = await protocolAPI.searchProtocol(v, { limit: 5 });
        this.entries = data;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      } finally {
        this.loading = false;
      }
    },
    async getProtocolById() {
      this.loading = true;
      try {
        const { data } = await protocolAPI.getProtocol(this.id);
        this.selected = data;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.selected = {};
      this.$emit('emit-protocol', undefined);
    },
    emitProtocol(item) {
      this.$emit('emit-protocol', item);
      this.selected = item;
    },
  },
};
</script>
