<template>
  <v-card
    :class="!tab ? 'ma-5' : ''"
    :outlined="!tab"
    :flat="tab"
  >
    <v-toolbar flat>
      <div>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <span>{{ totalStraws >= 0 ? `Totaal: ${totalStraws} rietjes` : '' }}</span>
      </div>
      <v-spacer />
      <v-col class="pr-0">
        <search-customer
          v-if="!tab"
          :id="$route.query.owner"
          :clearable="true"
          :outlined="true"
          :dense="true"
          @emit-customer="updateSearch"
        />
      </v-col>
      <button-with-menu
        :action-menu="actionMenu"
        :selected="selected"
      />
      <filters
        :filters="filters"
        :options="options"
        :fetcher="fetchSemenCollections"
        @fetching="loading = $event"
        @fetchedResults="semenCollections = $event"
        @fetchedTotal="totalSemenCollections = $event"
      />
      <columns
        :headers="headers"
        @emit-headers="filteredHeaders = $event"
      />
    </v-toolbar>
    <v-data-table
      v-model="selected"
      item-key="_id"
      :headers="filteredHeaders"
      :items="semenCollections"
      :options.sync="options"
      :server-items-length="totalSemenCollections"
      :loading="loading"
      loading-text="Bezig met laden..."
      class="ma-5"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :show-select="!!semenCollections.length"
    >
      <template #no-data>
        Geen sperma loten gevonden
      </template>
      <template #item="props">
        <tr>
          <td>
            <v-checkbox
              v-if="props.item._id"
              :input-value="props.isSelected"
              class="ma-0 py-0"
              hide-details
              dense
              @change="props.select($event)"
              @click.stop="(event) => event.stopPropagation()"
            />
          </td>
          <td v-if="showColumn('lotNumber')">
            {{ props.item.lotNumber || '-' }}
          </td>
          <td v-if="showColumn('stallion')">
            {{ horseName(props.item.stallion) }}
          </td>
          <td v-if="showColumn('owner')">
            {{ ownerName(props.item.owner) }}
          </td>
          <td v-if="showColumn('initial_inventory')">
            {{ props.item.initial_inventory }}
          </td>
          <td v-if="showColumn('current_inventory')">
            {{ props.item.current_inventory }}
          </td>
          <td v-if="showColumn('production_date')">
            {{ props.item.production_date ? formatDate(props.item.production_date) : '-' }}
          </td>
          <td v-if="showColumn('import_date')">
            {{ props.item.import_date ? formatDate(props.item.import_date) : '-' }}
          </td>
          <td v-if="showColumn('export_date')">
            {{ props.item.export_date ? formatDate(props.item.export_date) : '-' }}
          </td>
          <td v-if="showColumn('last_invoice_date')">
            {{ props.item.last_invoice_date ? formatDate(props.item.last_invoice_date) : '-' }}
          </td>
          <td v-if="showColumn('location')">
            <v-icon
              v-if="props.item.color"
              small
              class="mr-2"
              :class="strawColor(props.item.color)"
            >
              mdi-checkbox-blank-circle
            </v-icon>
            {{ props.item.location.container.name }} - Koker {{ props.item.location.tube }} -
            {{ props.item.location.position }}
          </td>
          <td v-if="showColumn('export_type')">
            {{ props.item.export_type }}
          </td>
          <td v-if="showColumn('type')">
            {{ props.item.type }}
          </td>
          <td
            v-if="showColumn('action')"
            class="d-print-none text-right"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  color="primary"
                  class="mr-2"
                  @click="() => openPage(props.item._id)"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Lot bewerken</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-badge
                  overlap
                  dot
                  color="accent"
                  class="mr-2"
                  :value="!!(props.item.modifications.length - 1)"
                >
                  <v-icon
                    color="primary"
                    @click="openDialog('detail', props.item)"
                    v-on="on"
                  >
                    mdi-magnify
                  </v-icon>
                </v-badge>
              </template>
              <span>Bekijk&nbsp;stockwijzigingen</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  color="primary"
                  class="mr-2"
                  @click="openDialog('value', props.item)"
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>
              <span>Kwaliteitsparameters</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  color="primary"
                  @click="historyDialog = props.item"
                  v-on="on"
                >
                  mdi-history
                </v-icon>
              </template>
              <span>Stockage&nbsp;historiek</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <mod-dialog
      v-if="semenCollection"
      :semen-collection="semenCollection"
      :detail-dialog="dialogs.detail"
      @update-semen-collection="getSemenCollections"
      @close-dialog="dialogs.detail = false"
    />
    <value-dialog
      v-if="semenCollection"
      :semen-collection="semenCollection"
      :value-dialog="dialogs.value"
      @close-dialog="dialogs.value = false"
    />
    <action-dialog
      :dialog="actionDialog"
      :selected="selected"
      :action="action"
      :max-items="totalSemenCollections"
      @executed="actionExecuted"
      @close="actionDialog = $event"
    />
    <invoice-dialog
      :dialog="invoiceDialog"
      :selected="selected.length ? selected : []"
      :action="action"
      @executed="actionExecuted"
      @close="invoiceDialog = $event"
    />
    <relocate-dialog
      :dialog="relocateDialog"
      :selected="selected"
      :relocate="relocate"
      @close="closeRelocateDialog"
    />
    <export-type-dialog
      :selected-lots="selected"
      :open-dialog="exportDialog"
      :max-lots="totalSemenCollections"
      @success="actionExecuted($event)"
      @close-dialog="exportDialog = $event"
    />
    <history-dialog
      v-if="historyDialog"
      :dialog="!!historyDialog"
      dialog-type="Semen"
      :semen-collection="historyDialog"
      @close="closeHistoryDialog"
    />
    <snackbar
      :snackbar="snackbar"
      :color="snackColor"
      :text="snackText"
      @timedout="snackbar = $event"
    />
  </v-card>
</template>
<script>
import ButtonWithMenu from '@/components/common/ButtonWithMenu';
import RelocateDialog from '@/components/common/RelocateDialog';
import Snackbar from '@/components/common/Snackbar';
import Columns from '@/components/filters/Columns';
import Filters from '@/components/filters/Filters';
import SearchCustomer from '@/components/search/SearchCustomer';
import ActionDialog from '@/components/semenCollection/ActionDialog';
import ExportTypeDialog from '@/components/semenCollection/ExportTypeDialog';
import InvoiceDialog from '@/components/semenCollection/invoice/InvoiceDialog';
import ModDialog from '@/components/semenCollection/ModDialog';
import ValueDialog from '@/components/semenCollection/ValueDialog';
import { exportTypes } from '@/consts';
import { compareRouteAndPush, formatDate, horseName, ownerName, strawColor } from '@/Helpers';
import { configAPI } from '@/services';
import semenAPI from '@/services/SemenAPI.js';
import { mapActions } from 'vuex';
import HistoryDialog from '@/components/icsi/HistoryDialog.vue';

export default {
  components: {
    SearchCustomer,
    ModDialog,
    ValueDialog,
    ActionDialog,
    InvoiceDialog,
    RelocateDialog,
    ButtonWithMenu,
    Snackbar,
    Columns,
    Filters,
    ExportTypeDialog,
    HistoryDialog,
  },
  props: {
    title: { type: String, default: '' },
    horse: { type: Object, default: () => ({}) },
    customer: { type: String, default: '' },
    tab: { type: Boolean },
    id: { type: String, default: '' },
    sortBy: { type: [String, Array], default: 'lotNumber' },
    sortDesc: { type: [Boolean, Array, String], default: false },
  },
  data: (data) => ({
    selected: [],
    dialogs: {
      detail: false,
      value: false,
    },
    invoiceDialog: false,
    actionDialog: false,
    relocateDialog: false,
    exportDialog: false,
    action: {
      type: '',
      exec: null,
    },
    filteredHeaders: [],
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || 10,
    },
    loading: false,
    semenCollections: [],
    semenCollection: null,
    totalSemenCollections: 0,
    semenModTypes: [],
    filterDialog: false,
    showDetails: false,
    headers: [
      { text: 'Lot', value: 'lotNumber', selected: true },
      { text: 'Hengst', value: 'stallion', selected: true },
      { text: 'Eigenaar', value: 'owner', selected: true },
      { text: 'Initieel', value: 'initial_inventory', selected: false },
      { text: 'Resterend', value: 'current_inventory', selected: true },
      { text: 'Productiedatum', value: 'production_date', selected: false },
      { text: 'Importdatum', value: 'import_date', selected: false },
      { text: 'Exportdatum', value: 'export_date', selected: false },
      { text: 'Laatste facturatie', value: 'last_invoice_date', selected: false },
      { text: 'Locatie & kleur', value: 'location', selected: true },
      { text: 'Export type', value: 'export_type', selected: true, sortable: false },
      { text: 'Type', value: 'type', selected: false },
      { text: '', align: 'right', value: 'action', sortable: false, class: 'd-print-none', selected: true },
    ],
    snackbar: false,
    snackColor: '',
    snackText: '',
    selectedOwner: undefined,
    historyDialog: null,
  }),
  computed: {
    actionMenu() {
      return [
        { title: 'Export', type: 'Export', action: (type) => this.openActionDialog(type) },
        { title: 'Export type', type: 'ExportType', action: () => this.openExportDialog() },
        { title: 'Verander eigenaar', type: 'Change', action: (type) => this.openActionDialog(type) },
        { title: 'Locatie wijzigen', type: 'Relocate', action: () => this.openRelocateDialog() },
        { title: 'Beschadigd', type: 'Damaged', action: (type) => this.openActionDialog(type) },
        { title: 'Vernietigen', type: 'Destroy', action: (type) => this.openActionDialog(type) },
        { title: 'Controle', type: 'Control', action: (type) => this.openActionDialog(type) },
      ];
    },
    filters() {
      return {
        type: {
          vTag: 'select',
          label: 'Filter op type',
          items: ['Productie', 'Import'],
        },
        exportType: {
          vTag: 'select',
          label: 'Filter op export type',
          options: { multiple: true },
          items: [exportTypes.NATIONAL, exportTypes.EU, exportTypes.WORLDWIDE],
        },
        // TODO: filter bekijken, werkt niet
        // modificationType: {
        //   vTag: 'select',
        //   label: 'Filter op modificatietype',
        //   items: this.semenModTypes,
        // },
        hideEmpty: {
          vTag: 'switch',
          label: 'Lege loten verbergen',
        },
      };
    },
    totalStraws() {
      return this.semenCollections.reduce((prev, curr) => prev + curr.current_inventory, 0);
    },
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.compareRouteAndPush(
          {
            ...this.$route.query,
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
          },
          this.$route,
          this.$router,
        );
      },
    },
  },
  mounted() {
    this.getSemenMods();
    if (this.horse) {
      this.filters.horse = this.horse;
    }
    if (this.customer) {
      this.filters.owner = this.customer;
    }
  },
  methods: {
    horseName,
    ownerName,
    formatDate,
    strawColor,
    compareRouteAndPush,
    ...mapActions({
      resetError: 'errorMessage/resetError',
      setError: 'errorMessage/setErrorMessage',
    }),
    showColumn(col) {
      return this.headers.find((header) => header.value === col).selected;
    },
    openPage(id) {
      this.$router.push(`/semen/create/${id}`);
    },
    closeHistoryDialog() {
      this.historyDialog = null;
    },
    updateSearch(owner) {
      this.selectedOwner = owner;
      this.compareRouteAndPush(
        {
          ...this.$route.query,
          owner: owner ? owner._id : undefined,
        },
        this.$route,
        this.$router,
      );
    },
    closeRelocateDialog() {
      this.relocateDialog = false;
      this.getSemenCollections();
    },
    showSnackbar(color, text) {
      this.snackbar = true;
      this.snackColor = color;
      this.snackText = text;
    },
    openInvoiceDialog() {
      this.invoiceDialog = true;
    },
    openRelocateDialog() {
      this.relocateDialog = true;
    },
    openExportDialog() {
      this.exportDialog = true;
    },
    openActionDialog(type) {
      this.actionDialog = true;
      this.action.type = type;
      if (type === 'Change') {
        this.action.exec = this.changeOwnerAndSplit;
      } else {
        this.action.exec = this.exportSemen;
      }
    },
    actionExecuted(action) {
      this.showSnackbar('success', `${action.type} succesvol.`);
      this.actionDialog = false;
      this.invoiceDialog = false;
      this.relocateDialog = false;
      this.exportDialog = false;
      this.selected = [];
      this.getSemenCollections();
    },
    deleteSemenCollection(semenCollection) {
      const index = this.semenCollections.findIndex((x) => x._id === semenCollection._id);
      this.semenCollections.splice(index, 1);
    },
    openDialog(dialogType, semenCollection) {
      this.dialogs[dialogType] = true;
      this.semenCollection = semenCollection;
    },
    async getSemenMods() {
      try {
        const {
          data: { semenModTypes },
        } = await configAPI.getSemenMods();
        this.semenModTypes = semenModTypes;
      } catch (err) {
        await this.setError(err);
      }
    },
    async relocate(selected) {
      try {
        await this.$store.dispatch('errorMessage/resetError');
        await Promise.all(selected.map((batch) => semenAPI.putSemenCollection(batch)));
        this.actionExecuted({ type: 'Verplaasen' });
      } catch (err) {
        await this.setError(err);
      }
    },
    fetchSemenCollections(URLparams) {
      return semenAPI.getAllSemenCollection({ stallion: this.id, owner: this.customer, ...URLparams });
    },
    async getSemenCollections() {
      this.loading = true;
      try {
        const {
          data: { results, total },
        } = await semenAPI.getAllSemenCollection({
          stallion: this.id,
          ...this.$route.query,
        });
        this.semenCollections = results;
        this.totalSemenCollections = total;
      } catch (err) {
        await this.setError(err);
      } finally {
        this.loading = false;
      }
    },
    async exportSemen(semenId, { customer, amount, type, date, action }) {
      await semenAPI.postSemenCollectionModification(semenId, {
        owner: customer ? customer._id : undefined,
        amount,
        type,
        date,
        action,
      });
    },
    async changeOwnerAndSplit(semenId, { inHouse, customer, amount, type, date }) {
      await semenAPI.splitSemenCollection(semenId, {
        inHouse,
        customerId: customer ? customer._id : undefined,
        amount,
        type,
        date,
      });
    },
  },
};
</script>

<style>
tr.collection td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

tr.modification td {
  border-bottom: 1px dashed #e0e0e0 !important;
  height: 24px !important;
  color: grey;
}
</style>
