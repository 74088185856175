export const strings = {
  ENG: {
    titleA: 'Centre',
    titleB: 'Destination',
    titleC: 'Embryos',
    name: 'Name:',
    address: 'Address:',
    approvalNumber: 'Approval number:',
    exportDate: 'Export date:',
    batchCode: 'Batch code:',
  },
  NL: {
    titleA: 'Centrum',
    titleB: 'Bestemming',
    titleC: 'Embryo\'s',
    name: 'Naam:',
    address: 'Adres:',
    approvalNumber: 'Erkenningsnummer:',
    exportDate: 'Exportdatum:',
    batchCode: 'Batch code:',
  },
};
