<template>
  <div class="d-print-none">
    <v-navigation-drawer
      v-if="isLoggedIn()"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense>
        <template v-for="item in menu">
          <v-list-group
            v-if="item.submenu"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.icon"
          >
            <template #activator>
              <v-list-item class="pl-0">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(child, i) in item.submenu"
              :key="i"
              :to="child.url"
              class="pl-8"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="item.text"
            :to="item.url"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template #append>
        <div class="pa-2">
          <v-btn
            v-show="isLoggedIn()"
            depressed
            block
            @click="handleLogout()"
          >
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      flat
      dark
    >
      <v-app-bar-nav-icon
        v-show="isLoggedIn()"
        @click.stop="drawer = !drawer"
      />
      <v-toolbar-title
        style="width: 170px"
        class="ml-0 pl-3"
      >
        <router-link
          id="homelink"
          to="/"
        >
          {{ $appName }}
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <notification-menu v-if="isLoggedIn" />
      <search v-if="isLoggedIn" />
    </v-app-bar>
  </div>
</template>

<script>
import { isLoggedIn, login, logout } from '@/services/auth';
import search from '@/components/search/Search';
import NotificationMenu from '@/components/notification/NotificationMenu';

export default {
  name: 'Navigation',
  components: { search, NotificationMenu },
  data() {
    return {
      dialog: false,
      drawer: null,
      menu: [
        {
          text: 'Klanten',
          url: '/customer?type=particulier',
          // icon: "mdi-alpha-k-circle"
          // icon: "mdi-contacts",
        },
        {
          text: 'Fertiliteit',
          submenu: [
            {
              text: 'ICSI',
              url: '/icsi?onlyActive=true',
            },
            {
              text: 'Sperma',
              url: '/semen',
            },
            {
              text: 'Stockage kosten',
              url: '/fertility/stock-costs',
            },
          ],
        },
        {
          text: 'Stock',
          url: '/stock',
        },
        {
          text: 'Cases',
          url: '/cases?status=Factureerbaar&status=Open&hideSurrogate=true',
        },
        {
          text: 'Paarden',
          // icon: "mdi-alpha-p-circle",
          // icon: "mdi-horseshoe",
          url: '/horse?aliveOnly=true',
        },
        {
          text: 'Rapporten',
          // icon: "mdi-alpha-r-circle",
          // icon: "mdi-google-analytics",
          submenu: [
            {
              text: 'Overzicht vrije locaties',
              url: '/report/available-locations',
            },
            {
              text: 'Draagmoeders per locatie',
              url: '/report/location?type=Merrie&surrogate=true&aliveOnly=true',
            },
            {
              text: 'Overleden paarden',
              url: '/report/death-horses?death=true',
            },
            {
              text: 'Stock sperma',
              url: '/report/semen-stock',
            },
            {
              text: 'Geleverde producten',
              url: '/report/delivered-products?type=Aankoop',
            },
            {
              text: 'Uitgaande producten',
              url: '/report/outgoing-products?out=true',
            },
            {
              text: 'Toegediende medicatie',
              url: '/report/applied-medication?type=Toediening',
            },
            {
              text: 'Jaarlijks rapport',
              url: '/yearlyReport',
            },
            {
              text: 'Register aanwezige dieren',
              url: '/livestock',
            },
            {
              text: 'Lijst gedekte merries',
              url: '/covered-mares',
            },
            {
              text: 'Transacties per hengsthouder',
              url: '/transactions-per-stallion-owner',
            },
          ],
        },
        {
          text: 'Beheer',
          // icon: "mdi-alpha-i-circle",
          // icon: "mdi-settings",
          submenu: [
            {
              text: 'Locaties',
              url: '/settings/location',
            },
            {
              text: 'Stikstof vaten',
              url: '/settings/nitrogen-container',
            },
            {
              text: 'Onderzoeksfiches downloaden',
              url: '/settings/research-sheets',
            },
            {
              text: 'Producten',
              url: '/settings/products',
            },
            {
              text: 'Protocollen',
              url: '/settings/protocols',
            },
            {
              text: 'Labo-onderzoeken',
              url: '/settings/labos',
            },
            {
              text: 'Pension types',
              url: '/settings/pension',
            },
            {
              text: 'Upload',
              url: '/settings/uploadSemenStock',
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleLogin() {
      login();
    },
    handleLogout() {
      logout();
    },
    isLoggedIn() {
      return isLoggedIn();
    },
  },
};
</script>
<style lang="css">
a#homelink {
  color: #fff;
  text-decoration: none;
}
</style>
