<template>
  <v-card
    class="ma-5"
    outlined
  >
    <v-toolbar
      flat
      dense
      light
    >
      <v-toolbar-title>{{ product.name }}</v-toolbar-title>
    </v-toolbar>
    <v-divider />
    <v-tabs v-model="tab">
      <v-tab
        key="details"
        class="d-print-none"
        href="#details"
      >
        <v-icon left>
          mdi-alpha-d-circle
        </v-icon>
        Details
      </v-tab>
      <v-tab
        key="batches"
        class="d-print-none"
        href="#batches"
      >
        <v-icon left>
          mdi-alpha-l-circle
        </v-icon>
        Loten
      </v-tab>
      <v-tab
        key="mods"
        class="d-print-none"
        href="#mods"
      >
        <v-icon left>
          mdi-alpha-m-circle
        </v-icon>
        Mods
      </v-tab>
      <v-tab-item
        class="mb-5"
        value="details"
      >
        <product-form
          :product="product"
          :loading="loading"
          :disabled="true"
          :background="true"
          route="/stock"
          @update-product="updateProduct"
        />
      </v-tab-item>
      <v-tab-item
        class="ma-5"
        value="batches"
      >
        <batch-table
          :id="id"
          :headers="headers"
          :product="product"
        />
      </v-tab-item>
      <v-tab-item
        class="ma-5"
        value="mods"
      >
        <batch-mods-table
          :id="id"
          :product="product"
          @update-refresh="refresh = false"
        />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import BatchTable from '@/components/stock/BatchTable';
import ProductForm from '@/components/products/Form';
import BatchModsTable from '@/components/stock/BatchModsTable';
import { productsAPI } from '@/services';

export default {
  components: {
    BatchTable,
    ProductForm,
    BatchModsTable,
  },
  props: {
    id: { type: String, default: '' },
  },
  data: () => ({
    dialog: false,
    disabled: true,
    valid: false,
    product: {},
    totalBatches: 0,
    loading: false,
    editedIndex: -1,
    headers: [
      { text: 'lot nummer', value: 'lotNumber', selected: true },
      { text: 'vervaldatum', value: 'expirationDate', selected: true },
      { text: 'leveringsdatum', value: 'deliveryDate', selected: true },
      { text: 'leverancier', value: 'supplier', selected: true },
      { text: 'aankoopprijs', value: 'buyInPrice', align: 'end', selected: true },
      { text: 'verkoopprijs', value: 'sellingPrice', align: 'end', selected: true },
      { text: 'verkoopprijs/eenheid', value: 'sellingPricePerUnit', align: 'end', selected: true },
      { text: 'initieel aantal', value: 'initialAmount', align: 'end', selected: false },
      { text: 'resterend', value: 'remainingAmount', align: 'end', selected: true },
      { text: 'laatste update', value: 'updatedAt', align: 'end', selected: false },
    ],
    filters: {
      remaining: 'All',
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nieuwe batch' : 'batch bewerken';
    },
    tab: {
      set(tab) {
        if (this.$route.query.tab !== tab) {
          this.$router.replace({ query: {
            ...this.$route.query,
            tab,
            sortBy: this.setSortBy(tab),
            sortDesc: this.setSortDesc(tab),
          } });
        }
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
  mounted() {
    this.getProduct(this.id);
  },
  methods: {
    setSortBy(tab) {
      if (tab === 'batches') return 'expirationDate';
      if (tab === 'mods') return 'createdAt';
      return undefined;
    },
    setSortDesc(tab) {
      if (tab === 'batches') return 'false';
      if (tab === 'mods') return 'true';
      return undefined;
    },
    async getProduct(id) {
      this.loading = true;
      try {
        const { data } = await productsAPI.getProduct(id);
        this.product = data;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      } finally {
        this.loading = false;
      }
    },
    filterBatch(filter) {
      this.options.remaining = filter;
    },
    updateProduct(product) {
      this.product = product;
    },

  },
};
</script>
