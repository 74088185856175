var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.protocol.parameters),function(parameter,idx){return _c('v-row',{key:parameter.elementModel + '-' + idx,staticClass:"d-flex justify-center"},[_c('span',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.printTitle(parameter.elementModel)))]),(parameter.elementModel === 'Customer')?_c('v-col',{attrs:{"cols":"12"}},[_c('search-customer',{attrs:{"clearable":true,"outlined":true,"dense":true},on:{"emit-customer":customer => {
          _vm.selectedCustomer = customer;
          parameter.element = customer;
          _vm.$emit('emit-select', !!parameter.element);
        }}})],1):_vm._e(),(parameter.elementModel === 'Horse')?_c('v-col',{attrs:{"cols":"12"}},[_c('search-horse',{attrs:{"customer":_vm.selectedCustomer,"horse-type":parameter.elementType || undefined,"surrogate-only":parameter.elementType === 'Merrie' && parameter.elementSpecification,"stud-only":parameter.elementType === 'Hengst' && parameter.elementSpecification,"clearable":true,"outlined":true,"dense":true},on:{"emit-horse":horse => {
          parameter.element = horse;
          _vm.$emit('emit-select', !!parameter.element)
        }}})],1):_vm._e(),_c('select-semen-form',{attrs:{"parameter":parameter,"check-type":_vm.checkType},on:{"select-param":function($event){return _vm.select($event, parameter)}}}),(parameter.elementModel === 'SemenCollection' && idx === _vm.protocol.parameters.length -1)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"blue darken-1","text":"","block":""},on:{"click":function($event){return _vm.addCollectionParam()}}},[_vm._v(" Voeg spermalot toe ")])],1):_vm._e(),(parameter.elementModel === 'Embryo')?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('search-horse',{attrs:{"horse-type":_vm.checkType(parameter.elementModel),"clearable":true,"show-stock":true,"outlined":true,"dense":true},on:{"emit-horse":function($event){_vm.selectedDonor = $event}}})],1):_vm._e(),(parameter.elementModel === 'Embryo')?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Embryo selecteren","items":_vm.embryos,"item-value":"_id","item-text":item => _vm.embryoDescription(item),"no-data-text":"Geen embryos in stock van deze donor","menu-props":{ offsetY: true, closeOnContentClick: true },"disabled":_vm.selectedDonor && !_vm.selectedDonor.name,"loading":_vm.elementLoading,"return-object":"","hide-details":"","clearable":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-list-item',{on:{"click":()=>_vm.select(item, parameter)}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.embryoDescription(item)))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.horseName(item.donor_stallion)))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.ownerName(item.owner)))])],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e()],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }