<template>
  <embryo-table
    :id="$route.params.id"
    :headers="headers"
    :fetch="fetchEmbryos"
  />
</template>

<script>
import EmbryoTable from '@/components/icsi/EmbryoTable';
import { icsiAPI } from '@/services';

export default {
  components: {
    EmbryoTable,
  },
  data: () => ({
    headers: [
      { text: 'Code', value: 'code', align: 'left', selected: true },
      { text: 'Donor Merrie', value: 'donor_mare', align: 'left', selected: false },
      { text: 'Donor Hengst', value: 'donor_stallion', align: 'left', selected: false },
      { text: 'Locatie & kleur', value: 'location', selected: true },
      { text: 'Eigenaar', value: 'owner', selected: true },
      { text: 'Getransfereerd op', align: 'end', value: 'transferDate', selected: false },
      { text: 'Geëxporteerd op', align: 'end', value: 'exportDate', selected: false },
      { text: 'Geïmporteerd op', align: 'end', value: 'importedAt', selected: false },
      { text: 'Laatste facturatie', align: 'end', value: 'lastInvoiceDate', selected: false },
      { text: 'Gewijzigd op', align: 'end', value: 'updatedAt', selected: false },
      { text: 'Aangemaakt op', align: 'end', value: 'createdAt', selected: false },
      { text: 'TRACES', align: 'end', value: 'traces', selected: true },
      { text: 'Historiek', align: 'end', value: 'action', sortable: false, class: 'd-print-none', selected: true },
    ],
  }),
  methods: {
    fetchEmbryos(URLparams) {
      return icsiAPI.getAllEmbryos({ ...URLparams, batch_id: this.$route.params.id });
    },
  },
};
</script>
