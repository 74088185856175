import { capitalize } from '@/Helpers';
import VCalendar from 'v-calendar';
import Vue from 'vue';
import VueFilterDateFormat from 'vue-filter-date-format';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { clientId, domain } from '../auth_config.json';
import App from './App.vue';
import { Auth0Plugin } from './auth';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store/index';
import './registerSW';

Vue.prototype.$appName = 'Equibase';

Vue.config.productionTip = false;

Vue.use(Auth0Plugin, {
  clientId,
  domain,
  onRedirectCallback: async (appState) => {
    await router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  },
});

Vue.filter('capitalize', capitalize);

// Config of vuefilterdate
Vue.use(VueFilterDateFormat, {
  dayOfWeekNames: [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday',
    'Friday', 'Saturday',
  ],
  dayOfWeekNamesShort: [
    'Su', 'Mo', 'Tu', 'We', 'Tr', 'Fr', 'Sa',
  ],
  monthNames: [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ],
  monthNamesShort: [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ],
});

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

if (process.env.VUE_APP_ENV !== 'local') {
  Sentry.init({
    Vue,
    dsn: 'https://7574ec3a81b44c4f939a6c5f3107efd9@o1092247.ingest.sentry.io/6110398',
    environment: process.env.VUE_APP_ENV,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['equibase.app', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

new Vue({
  router,
  render: (h) => h(App),
  vuetify,
  store,
}).$mount('#app');
