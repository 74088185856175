<template>
  <v-layout
    column
    fill-height
    justify-space-between
  >
    <v-card
      class="ma-5"
      outlined
    >
      <v-tabs
        v-model="type"
        center-active
      >
        <!-- PRIVATE -->
        <v-tab
          key="particulier"
          href="#particulier"
        >
          Particulieren
        </v-tab>
        <v-tab-item value="particulier">
          <customer-table :headers="contactHeaders" />
        </v-tab-item>

        <!-- COMPANY -->
        <v-tab
          key="bedrijf"
          href="#bedrijf"
        >
          Bedrijven
        </v-tab>
        <v-tab-item value="bedrijf">
          <customer-table :headers="companyHeaders" />
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-btn
      style="width: fit-content; align-self: flex-end"
      color="primary"
      class="mr-5 mb-5"
      depressed
      @click="openCustomerPage()"
    >
      Klant toevoegen
      <v-icon
        right
        dark
      >
        mdi-plus
      </v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import customerTable from '@/components/customer/customerTable';

export default {
  components: { customerTable },
  data: () => ({
    contactHeaders: [
      { text: 'Bedrijf', value: 'company', selected: false },
      { text: 'Voornaam', value: 'first_name', selected: true },
      { text: 'Achternaam', value: 'last_name', selected: true },
      { text: 'Aangemaakt op', value: 'createdAt', align: 'end', sortable: false, selected: true },
    ],
    companyHeaders: [
      { text: 'Bedrijf', value: 'company', selected: true },
      { text: 'BTW-nr', value: 'tva', selected: true },
      { text: 'Voornaam', value: 'first_name', selected: false },
      { text: 'Achternaam', value: 'last_name', selected: false },
      { text: 'Aangemaakt op', value: 'createdAt', align: 'end', sortable: false, selected: true },
    ],
  }),
  computed: {
    type: {
      set(type) {
        if (this.$route.query.type !== type) {
          this.$router.replace({ query: {
            ...this.$route.query,
            first_name: undefined,
            last_name: undefined,
            company: undefined,
            type,
          } });
        }
      },
      get() {
        return this.$route.query.type;
      },
    },
  },
  methods: {
    openCustomerPage(id) {
      document.body.style.cursor = 'default';
      this.$router.push(`/customer/${id}`);
    },
  },
};
</script>
