export const months = [
  { id: 1, name: 'Januari' },
  { id: 2, name: 'Februari' },
  { id: 3, name: 'Maart' },
  { id: 4, name: 'April' },
  { id: 5, name: 'Mei' },
  { id: 6, name: 'Juni' },
  { id: 7, name: 'Juli' },
  { id: 8, name: 'Augustus' },
  { id: 9, name: 'September' },
  { id: 10, name: 'Oktober' },
  { id: 11, name: 'November' },
  { id: 12, name: 'December' },
];

export const exportTypes = {
  EU: 'Exporteerbaar binnen EU',
  NATIONAL: 'Exporteerbaar binnen België',
  WORLDWIDE: 'Wereldwijd exporteerbaar',
};

export enum LodgingStatus {
  INVOICEABLE = 'Factureerbaar',
  INVOICED = 'Gefactureerd',
  NOT_INVOICEABLE = 'Niet factureerbaar'
}

export const caseActivities = {
  BLANK: '-',
  DRAAGMOEDER: 'Draagmoeder',
  EMBRYOTRANSFER: 'Embryotransfer',
  ICSI_TRANSFER: 'ICSI-Embryotransfer',
  KUNSTMATIGE_INSEMINATIE: 'Kunstmatige inseminatie',
  OVUMPICK_UP: 'Ovum pick up',
  SPERMA_AFNAME: 'Sperma-afname',
};

export enum SearchResultTypes {
  HORSE = 'Horse',
  SEMEN = 'SemenCollection',
  EMBRYO = 'Embryo',
  PRODUCT = 'Product'
}

export enum AnnouncementTypes {
  INFO = 'Info',
  IMPORTANT = 'Important',
  RELEASE = 'Release'
}
