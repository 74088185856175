<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title>Stockage historiek voor {{ dialogType === 'ICSI' ? embryo?.code : semenCollection?.lotNumber }}</v-card-title>
      <v-card-text>
        <v-data-table
          :items="history"
          :headers="headers"
          :options.sync="options"
          loading-text="Bezig met laden..."
          :loading="loading"
          :server-items-length="totalHistory"
          :hide-default-header="false"
        >
          <template #no-data>
            Geen historiek voor dit lot gevonden
          </template>
          <template #item="props">
            <tr>
              <td>{{ formatDate(props.item.from) || '-' }}</td>
              <td>{{ formatDate(props.item.to) || '-' }}</td>
              <td>{{ ownerName(props.item.owner) }}</td>
              <td>{{ props.item.comment || '-' }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="$emit('close')"
        >
          Sluiten
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { storageAPI } from '@/services';
import { formatDate, ownerName } from '@/Helpers';

export default {
  name: 'IcsiHistoryDialog',
  props: {
    dialog: { type: Boolean },
    dialogType: { type: String, required: true },
    embryo: {
      type: Object,
      required: false,
      default: undefined,
    },
    semenCollection: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data: () => ({
    history: [],
    totalHistory: 0,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['from'],
      sortDesc: [true],
    },
    loading: false,
    headers: [
      { text: 'Van', value: 'from' },
      { text: 'Tot', value: 'to' },
      { text: 'Eigenaar', value: 'owner' },
      { text: 'Notities', value: 'note' },
    ],
  }),
  created() {
    this.fetchHistory();
  },
  methods: {
    ownerName,
    formatDate,
    async fetchHistory() {
      this.loading = true;
      try {
        await this.$store.dispatch('errorMessage/resetError');
        const { data } = this.embryo
          ? await storageAPI.getICSIStorageHistory(this.embryo._id, this.options)
          : await storageAPI.getSemenStorageHistory(this.semenCollection.id, this.options);
        this.history = data.results;
        this.totalHistory = data.total;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
