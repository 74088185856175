import { render, staticRenderFns } from "./ExportTypeDialog.vue?vue&type=template&id=8f80f410&scoped=true&"
import script from "./ExportTypeDialog.vue?vue&type=script&lang=ts&"
export * from "./ExportTypeDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f80f410",
  null
  
)

export default component.exports