<template>
  <v-card
    class="mx-5 mt-5 mb-12"
    outlined
  >
    <mods
      :title="title"
      :filters="filters"
      :headers="headers"
    />
  </v-card>
</template>

<script>
import mods from '@/components/stock/Mods';

export default {
  components: { mods },
  data: () => ({
    title: 'Toegediende medicatie',
    headers: [
      { text: 'Type', value: 'type', selected: true },
      { text: 'Product', value: 'product', selected: true },
      { text: 'Lot nummer', value: 'batch', selected: true },
      { text: 'Paard', value: 'horse', selected: true },
      { text: 'Aantal', value: 'amount', selected: true },
      { text: 'Datum', align: 'end', value: 'createdAt', selected: true },
    ],
  }),
  computed: {
    filters() {
      return {
        horse: { vTag: 'searchHorse' },
        product: { vTag: 'searchProduct' },
        dateRange: {
          vTag: 'dateRange',
          label: 'Datum bereik',
          from: 'from',
          to: 'to',
        },
      };
    },
  },
};
</script>
