<template>
  <v-layout
    column
    fill-height
    justify-space-between
  >
    <cases-table
      title="cases"
      :headers="headers"
    />
    <v-btn
      style="width: fit-content; align-self: flex-end"
      color="primary"
      class="mr-5 mb-5"
      depressed
      @click="newCaseDialog = true"
    >
      Case toevoegen
      <v-icon right>
        mdi-plus
      </v-icon>
    </v-btn>
    <new-case-dialog
      :new-case-dialog="newCaseDialog"
      @toggle-dialog="newCaseDialog = $event"
    />
  </v-layout>
</template>

<script>
import CasesTable from '@/components/case/Table';
import newCaseDialog from '@/components/case/CreateDialog';

export default {
  components: { CasesTable, newCaseDialog },
  data: () => ({
    headers: [
      { text: 'Ref', value: 'reference', selected: true },
      { text: 'Paard', value: 'horse', selected: true },
      { text: 'Klant', value: 'customer', selected: true },
      { text: 'Extra', value: 'extra', selected: true },
      { text: 'Hoofdactiviteit', value: 'mainActivity', selected: true },
      { text: 'Aantal', value: 'length', selected: true, sort: false },
      { text: 'status', value: 'status', selected: true, width: '150', align: 'end' },
      { text: 'Gewijzigd', value: 'updatedAt', selected: false },
      { text: 'Gemaakt', value: 'createdAt', align: 'end', selected: true },
    ],
    newCaseDialog: false,
  }),
};
</script>
