import { deleteR, get, post, postMultiPart, put } from '@/services/API';

export default {
  postHorse: (horse) => post('/api/horse/create', { horse }),
  getHorses: (params) => get(`/api/horse?${new URLSearchParams(params)}`),
  getHorseCount: () => get('/api/horse/count'),
  getHorse: (id) => get(`/api/horse/${id}`),
  putHorse: (horse) => put(`/api/horse/${horse._id}`, { horse }),
  deleteHorse: (id) => deleteR(`/api/horse/${id}`),
  postPassport: (id, formData) => postMultiPart(`/api/horse/${id}/passport`, formData),
  deletePassport: (horse) => deleteR(`/api/horse/${horse._id}/passport`),
  postLodging: (horseId, lodging, pensionType) => post(`/api/horse/${horseId}/lodging`, { lodging, pensionType }),
  putLodging: (horseId, lodging) => put(`/api/horse/${horseId}/lodging`, { lodging }),
  deleteLodging: (horse, lodging) => deleteR(`/api/horse/${horse._id}/lodging/${lodging._id}`, lodging),
  postLabResult: (id, data) => postMultiPart(`/api/horse/${id}/labo`, data),
  deleteLabResult: (horseId, labId, file) => deleteR(`/api/horse/${horseId}/labo/${labId}/${file}`),
  getBase64: (horseId, params) => get(`/api/horse/${horseId}/pdfBase64?${new URLSearchParams(params)}`),
  getPdfUrl: (horseId, params) => get(`/api/horse/${horseId}/storage?${new URLSearchParams(params)}`),
  searchHorse: (searchValue, params) => get(`/api/horse/search?searchValue=${searchValue}&${new URLSearchParams(params)}`),
  searchMicrochip: (searchValue, params) => get(`/api/horse/microchip?searchValue=${searchValue}&${new URLSearchParams(params)}`),
  searchSurrogateUID: (searchValue, params) => get(`/api/horse/surrogateuid?searchValue=${searchValue}&${new URLSearchParams(params)}`),
  relocateHorse: (data) => put('/api/horse/relocate', data),
  getCoveredMares: (year) => get(`/api/horse/covered-mares?year=${year}`),
  getYears: () => get('/api/horse/covered-mares-years'),
};
