export const formatPrice = (amount) => {
  const options = {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  };

  const formatter = new Intl.NumberFormat('nl-BE', options);

  return formatter.format(amount);
};
