export enum YearlyReportType {
  FRESH_SEMEN = 'Dosis zaad (vers)',
  FROZEN_SEMEN = 'Rietje zaad (diepvries)',
  ICSI_EMBRYO = 'Embryo ICSI',
  OVUM = 'Eicel',
  RINSED_EMBRYO = 'Embryo spoeling',
}

export enum YearlyReportAction {
  EXPORT = 'Export',
  FROZEN = 'Invriezen',
  GAIN = 'Winning',
  IMPORT = 'Import',
  IMPLANTED = 'Ingeplant',
  PREGNANT = 'Drachtig voor minstens 42 dagen',
  RECEIVED = 'Ontvangen',
  SENT = 'Verzending',
  USE = 'Gebruik',
}
