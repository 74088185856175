
import Vue, { PropType } from 'vue';
import { formatDate } from '@/Helpers';
import TransactionAPI from '@/services/TransactionAPI';
import { IDateRange } from '@/models/general';

export default Vue.extend({
  components: {},
  props: {
    horse: { type: Object, required: true },
    dateRange: { type: Object as PropType<IDateRange>, required: true },
  },
  data() {
    return {
      transactionSum: {},
    };
  },
  watch: {
    dateRange: {
      immediate: true,
      async handler() {
        const result = await this.fetchTransactionSum();
        if (result) {
          this.transactionSum = result;
        }
      },
    },
  },
  methods: {
    formatDate,
    async fetchTransactionSum(): Promise<any> {
      if (this.horse?._id) {
        const params = {
          from: this.$route.query.from,
          to: this.$route.query.to,
          horseId: this.horse._id,
        };
        const { data } = await TransactionAPI.getMareTransactionSum(params);
        return data;
      }
      return undefined;
    },
  },
});
