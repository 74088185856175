import { get } from '@/services/API';

export default {
  getAllICSIStorage: (params) => get(`/api/storage/icsi/storage?${new URLSearchParams(params)}`),
  getAllCaseICSI: (params) => get(`/api/storage/icsi/case?${new URLSearchParams(params)}`),
  getAllICSICasesByOwner: (params) => get(`/api/storage/icsi/owner?${new URLSearchParams(params)}`),
  getICSIStorageHistory: (embryoId, options) =>
    get(`/api/storage/icsi/embryo-history?embryoId=${embryoId}&${new URLSearchParams(options)}`),

  getAllSemenStorage: (params) => get(`/api/storage/semen/storage?${new URLSearchParams(params)}`),
  getAllSemenStoragesByOwner: (params) => get(`/api/storage/semen/owner?${new URLSearchParams(params)}`),
  getAllSemenCases: (params) => get(`/api/storage/semen/case?${new URLSearchParams(params)}`),
  getSemenStorageHistory: (collectionId, options) =>
    get(`/api/storage/semen/semen-history?collectionId=${collectionId}&${new URLSearchParams(options)}`),
};
