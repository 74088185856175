import { get, post, postMultiPart, put, deleteR } from '@/services/API';

export default {
  postSemenCollection: (semenCollection) => post('/api/semen', { semenCollection }),
  getAllSemenCollection: (params) => get(`/api/semen?${new URLSearchParams(params)}`),
  getSemenCollections: (id) => get(`/api/semen/${id}`),
  putSemenCollection: (semenCollection) => put(`/api/semen/${semenCollection._id}`, { semenCollection }),
  deleteSemenCollection: (semenCollection) => deleteR(`/api/semen/${semenCollection._id}`),
  splitSemenCollection: (id, data) => post(`/api/semen/change/${id}`, data),
  postSemenCollectionModification: (collectionId, semenCollectionModification) =>
    post(`/api/semen/${collectionId}/modification`, { semenCollectionModification }),
  deleteSemenCollectionModification: (semenCollection, semenCollectionModification) =>
    deleteR(`/api/semen/${semenCollection._id}/modification/${semenCollectionModification._id}`),
  uploadSemenStock: (data) => postMultiPart('/api/semen/upload/semen', data),
  uploadLodging: (data) => postMultiPart('/api/semen/upload/lodging', data),
  getHorsesWithStock: (params) => get(`/api/semen/horses?${new URLSearchParams(params)}`),
  getActiveSemenStockWithLocation: () => get('/api/semen/active-semen-data'),
};
