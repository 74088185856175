<template>
  <v-card
    flat
    class="light-grey"
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-card
        outlined
        class="ma-5"
      >
        <v-toolbar
          flat
          dense
        >
          <v-toolbar-title>ICSI toevoegen</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-row class="ma-5">
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="icsi.code"
              :rules="required"
              label="Code ICSI *"
              :disabled="icsi.active"
              :loading="loading"
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="icsi.amount"
              :rules="[(v) => v>0 || 'Dit veld is verplicht en moet groter zijn dan 0']"
              label="Aantal embryos"
              type="number"
              hide-details
              :loading="loading"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <search-customer
              :customer="icsi.owner"
              :loading="loading"
              label="Eigenaar"
              @emit-customer="icsi.owner = $event"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <search-horse
              :horse="icsi.donor_mare"
              :required="true"
              :clearable="true"
              :outlined="false"
              horse-type="Merrie"
              :mare-only="true"
              @emit-horse="icsi.donor_mare = $event"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <search-horse
              :horse="icsi.donor_stallion"
              :required="true"
              :clearable="true"
              :outlined="false"
              horse-type="Hengst"
              :stud-only="true"
              @emit-horse="icsi.donor_stallion = $event"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <date-picker
              label="Datum import"
              :date="icsi.collectionDate"
              :max="new Date()"
              :close-on-input="true"
              :required="true"
              :hide-details="true"
              @select-date="icsi.collectionDate = $event"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-checkbox
              v-model="icsi.traces"
              label="TRACES document aanwezig?"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-card
        outlined
        class="ma-5"
      >
        <v-toolbar
          flat
          dense
        >
          <v-toolbar-title>Locatie</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-row class="ma-5">
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="icsi.collectionColor"
              :items="collectionColors"
              label="Kleur rietjes"
              hide-details
              :menu-props="{ offsetY: true }"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-if="icsi.location"
              v-model="icsi.location.container"
              :menu-props="{ offsetY: true }"
              :items="nitrogenContainers"
              item-text="name"
              item-value="_id"
              label="Stikstof vat *"
              return-object
              :rules="required"
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="icsi.location.tube"
              :disabled="!icsi.location.container"
              :items="tubesAvailable(icsi.location.container)"
              label="Koker *"
              :rules="required"
              :menu-props="{ offsetY: true }"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row
          v-if="selectedContainer"
          class="ma-5"
        >
          <v-col>
            Resterende plaatsen: {{ selectedContainer.remaining_container_space }}
          </v-col>
        </v-row>
      </v-card>
      <snackbar
        :snackbar="snackbar"
        text="ICSI is succesvol opgeslaan"
        color="success"
        @timeout="snackbar = $event"
      />
    </v-form>
    <v-card
      v-if="icsi.amount && icsi.code"
      outlined
      class="ma-5"
    >
      <v-toolbar
        flat
        dense
        light
      >
        <v-toolbar-title>
          Embryo selecteren
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          text
          @click="embryoCodes = embryos.map((em) => em.code)"
        >
          Alles selecteren
        </v-btn>
        <v-btn
          text
          @click="embryoCodes = []"
        >
          Deselecteren
        </v-btn>
      </v-toolbar>
      <v-divider />
      <v-row class="ma-5">
        <v-col
          v-for="embryo in embryos"
          :key="embryo.code"
          cols="4"
        >
          <v-switch
            v-model="embryoCodes"
            inset
            :label="embryo.code"
            :value="embryo.code"
          />
        </v-col>
      </v-row>
    </v-card>
    <div class="mr-2">
      <v-row
        justify="end"
        dense
      >
        <v-btn
          depressed
          color="primary"
          class="mr-4 mb-5"
          @click="$router.back()"
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          terug
        </v-btn>
        <v-btn
          v-if="!icsi._id"
          :disabled="!valid || !embryoCodes.length"
          color="success"
          class="mr-4"
          depressed
          @click="createICSI()"
        >
          <v-icon left>
            mdi-content-save-outline
          </v-icon>
          ICSI opslaan
        </v-btn>
        <v-btn
          v-if="icsi._id"
          :disabled="!valid || !embryoCodes.length"
          color="success"
          depressed
          class="mr-4"
          @click="updateICSI()"
        >
          {{ icsi.type }} bijwerken
        </v-btn>
        <v-btn
          v-if="icsi._id"
          color="warning"
          depressed
          @click="deleteDialog = true"
        >
          {{ icsi.type }} verwijderen
        </v-btn>
        <v-dialog
          v-model="deleteDialog"
          persistent
          max-width="350"
        >
          <v-card>
            <v-card-title class="headline">
              ICSI verwijderen?
            </v-card-title>
            <v-card-text>
              Ben je zeker dat je het lot {{ icsi.code }} wilt verwijderen? Dit kan niet meer ongedaan
              gemaakt worden
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="success"
                depressed
                @click="deleteDialog = false"
              >
                Annuleren
              </v-btn>
              <v-btn
                color="error"
                depressed
                @click="deleteICSI()"
              >
                Verwijderen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { icsiAPI, locationAPI, nitrogenContainerAPI, configAPI } from '@/services';
import { formatDate, required, tubesAvailable } from '@/Helpers';
import DatePicker from '@/components/DateSelector/DatePicker';
import SearchHorse from '@/components/search/SearchHorse';
import SearchCustomer from '@/components/search/SearchCustomer';
import Snackbar from '@/components/common/Snackbar';

export default {
  components: { SearchCustomer, DatePicker, SearchHorse, Snackbar },
  props: ['icsi', 'loading'],
  data() {
    return {
      deleteDialog: false,
      valid: false,
      snackbar: false,
      locations: null,
      mares: [],
      stallions: [],
      embryos: [],
      embryoCodes: [],
      nitrogenContainers: [],
      collectionColors: [],
      search: null,
      owner: '',
      selectedContainer: null,
    };
  },
  computed: { required },
  watch: {
    icsi: {
      handler() {
        this.createEmbryos();
      },
      deep: true,
      immediate: true,
    },
    'icsi.location': {
      async handler() {
        if (this.icsi.location.container && this.icsi.location.tube) {
          const { data } = await nitrogenContainerAPI.getAvailableEmbryoContainerSpace(
            this.icsi.location.container._id,
            this.icsi.location.tube,
          );

          this.selectedContainer = data.container;
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await Promise.all([this.getColors(), this.getLocations(), this.getNitrogenContainers()]);
  },
  methods: {
    formatDate,
    tubesAvailable,
    async getColors() {
      try {
        const { data: { colors } } = await configAPI.getColors();
        this.collectionColors = colors;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
    async getNitrogenContainers() {
      try {
        const { data: { results } } = await nitrogenContainerAPI.getNitrogenContainers();
        this.nitrogenContainers = results;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
    async getLocations() {
      try {
        const { data: { locations } } = await locationAPI.getLocations();
        this.locations = locations;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
    async createICSI() {
      try {
        await icsiAPI.postICSI({ ...this.icsi, embryoCodes: this.embryoCodes });
        this.snackbar = true;
        await this.$store.dispatch('errorMessage/resetError');
        await this.$router.back();
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
    async updateICSI() {
      try {
        await icsiAPI.putICSI(this.icsi);
        await this.$store.dispatch('errorMessage/resetError');
        await this.$router.back();
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
    async deleteICSI() {
      try {
        await icsiAPI.deleteICSI(this.icsi._id);
        await this.$store.dispatch('errorMessage/resetError');
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      } finally {
        this.$router.go(-1);
        this.dialog = false;
      }
    },
    createEmbryos() {
      const { code, amount } = this.icsi;
      const embryos = [];
      for (let i = 0; i < amount; i += 1) {
        embryos.push({
          selected: true,
          code: `${i + 1}-${code}`,
        });
      }
      this.embryos = embryos;
      this.embryoCodes = embryos.map((emb) => emb.code);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    },
  },
};
</script>
