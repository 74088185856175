<template>
  <v-layout
    column
    fill-height
  >
    <PrintHeader
      class="print-only"
      :title="title"
    />
    <v-card
      v-if="kees"
      class="mx-5 mt-5 no-border-print"
      outlined
    >
      <v-toolbar
        flat
        class="no-print"
      >
        <v-toolbar-title>{{ language === 'NL' ? 'Inseminatie bewijs' : 'Proof of insemination' }}</v-toolbar-title>
      </v-toolbar>
      <div class="mx-4 mb-4">
        <h4>{{ language === 'NL' ? 'Klant' : 'Customer' }}</h4>
        <span>{{ ownerName(kees.customer) }}</span>
      </div>
      <v-layout class="ma-4 mt-0">
        <div
          v-if="kees.horse"
          class="mare d-flex flex-column"
          style="width: 50%"
        >
          <h4>{{ language === 'NL' ? 'Merrie' : 'Mare' }}</h4>
          <span>{{ language === 'NL' ? 'Naam' : 'Name' }}: {{ horseName(kees.horse) }}</span>
          <span>UELN: {{ kees.horse.ueln || '-' }}</span>
          <span>Microchip: {{ kees.horse.microchip || '-' }}</span>
        </div>
        <div
          v-if="protocolHorse"
          class="stud d-flex flex-column"
          style="width: 50%"
        >
          <h4>{{ language === 'NL' ? 'Dekhengst' : 'Stud-horse' }}</h4>
          <span>{{ language === 'NL' ? 'Naam' : 'Name' }}: {{ horseName(protocolHorse) }}</span>
          <span>UELN: {{ protocolHorse.ueln || '-' }}</span>
          <span>Microchip: {{ protocolHorse.microchip || '-' }}</span>
        </div>
      </v-layout>
      <div
        v-if="date"
        class="mx-4 mb-4 grid"
      >
        <b>{{ language === 'NL' ? 'Datum inseminatie' : 'Insemination date' }}:</b> <time>{{ formatDate(date) }}</time>
        <b>{{ language === 'NL' ? 'Datum controle' : 'Check date' }}:</b><time>{{ formatDate(controlDate) }}</time>
        <b>{{ language === 'NL' ? 'Datum geplande bevalling' : 'Estimate date of delivery' }}:</b><time>{{ formatDate(avgDateOfBirth) }}</time>
      </div>
      <div
        v-if="pregnancyStatement"
        class="mx-4"
      >
        <h4>{{ language === 'NL' ? 'Gust verklaring' : 'Pregnancy statement' }}</h4>
        <p>
          {{ language === 'NL'
            ? 'Ondergetekende verklaart de bovengenoemde merrie te hebben gecontroleerd op drachtigheid door middel van echografie en werd drachtig/niet drachtig bevonden op'
            : 'The undersigned declares, having controlled the above mentioned mare on gestation by means of ultrasound examination, that she is / is not pregnant on'
          }}
        </p>
        <time>{{ formatDate(executionDate) || '..../...../.......' }}</time>
        <div class="grid mt-10">
          <div>
            <p>{{ companyInfo.name }}</p>
            <p>{{ companyInfo.address }}</p>
            <p>{{ companyInfo.postalCode }} {{ companyInfo.city }}</p>
            <p>{{ companyInfo.country }}</p>
          </div>
          <div>
            <p>{{ language === 'NL' ? 'Datum' : 'Date' }}: {{ formatDate(startOfToday()) }}</p>
            <p>{{ language === 'NL' ? 'Dierenarts' : 'Veterinarian' }}: {{ doctor }}</p>
          </div>
        </div>
      </div>
      <signature-display
        v-if="doctor"
        class="ma-3"
        :doctor="doctor"
      />
    </v-card>
    <v-card
      class="ma-5 no-print"
      outlined
    >
      <v-card-title>Opties</v-card-title>
      <v-row class="mx-1 mb-2">
        <v-col cols="6">
          <v-select
            v-model="language"
            :items="languages"
            label="Selecteer een taal"
            class="mx-5 mb-5"
            :menu-props="{ offsetY: true }"
            hide-details
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="daysToAdd"
            label="Dagen toevoegen aan controle datum"
            type="number"
            hide-details
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="doctor"
            :items="doctors"
            class="mx-5 mb-5"
            label="selecteer ondergetekende"
            :menu-props="{ offsetY: true }"
            hide-details
            outlined
            dense
          />
        </v-col>
        <v-col
          cols="6"
        >
          <date-picker
            :date="executionDate"
            label="Datum uitvoering"
            :hide-details="true"
            :outlined="true"
            :dense="true"
            @select-date="executionDate = $event"
          />
        </v-col>
        <v-col cols="6">
          <v-checkbox
            v-model="pregnancyStatement"
            class="mx-5 mb-5"
            label="Voeg gust verklaring toe"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-row
      justify="end"
      dense
    >
      <v-btn
        style="width: fit-content;"
        color="primary"
        class="mr-2 mb-5 no-print"
        depressed
        @click="$router.back()"
      >
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        terug
      </v-btn>
      <v-btn
        style="width: fit-content;"
        color="success"
        class="mr-5 mb-5 no-print"
        depressed
        @click="print"
      >
        print
      </v-btn>
    </v-row>
  </v-layout>
</template>

<script>
import { print, formatDate, horseName, ownerName } from '@/Helpers';
import { CaseAPI, horseAPI, semenAPI } from '@/services';
import PrintHeader from '@/components/common/PrintHeader';
import SignatureDisplay from '@/components/SignatureDisplay/SignatureDisplay';
import DatePicker from '@/components/DateSelector/DatePicker';
import startOfToday from 'date-fns/startOfToday';

export default {
  components: { PrintHeader, SignatureDisplay, DatePicker },
  data: (data) => ({
    customer: null,
    mare: null,
    protocolHorse: null,
    date: null,
    fetching: false,
    kees: null,
    daysToAdd: 14,
    semen: null,
    title: '',
    language: 'NL',
    languages: ['NL', 'EN'],
    pregnancyStatement: true,
    doctor: null,
    doctors: ['Dr. Degeneffe Erik', 'Degeneffe Wim'],
    executionDate: startOfToday(),
    companyInfo: data.$store.state.companyInfo,
  }),
  computed: {
    controlDate() {
      return this.date.addDays(this.daysToAdd);
    },
    avgDateOfBirth() {
      return this.date.addDays(335);
    },
  },
  beforeMount() {
    const { caseId, date, horse, semencollection, title } = this.$route.query;
    this.date = new Date(date);
    this.title = title;
    Promise.all([
      this.getAllCases(caseId),
      this.getProtocolHorse(horse),
      this.getSemenCollection(semencollection),
    ]);
  },
  methods: {
    print,
    horseName,
    ownerName,
    formatDate,
    startOfToday,
    async getSemenCollection(semenId) {
      try {
        if (semenId) {
          const { data } = await semenAPI.getSemenCollections(semenId);
          this.semen = data;
          this.protocolHorse = data.stallion;
        }
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      }
    },
    async getProtocolHorse(horseId) {
      try {
        if (horseId) {
          const { data } = await horseAPI.getHorse(horseId);
          this.protocolHorse = data;
        }
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      }
    },
    async getAllCases(caseId) {
      try {
        const { data } = await CaseAPI.get(caseId);
        this.kees = data;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      }
    },
  },
};
</script>

<style scoped>
b, h4, span, time, div, strong, .v-toolbar__title {
  font-family: Arial;
}
h4 {
  text-decoration: underline;
  margin-bottom: .5em;
}
b, h4, span, time, p {
  color: #1A1449;
}
strong, .v-toolbar__title {
  font-weight: bolder;
  color: #FF5233;
}
.grid {
  display: grid; grid-template-columns: 1fr 1fr;
}
</style>
