
import Vue from 'vue';
import { ownerName, formatDate, horseName, compareRouteAndPush } from '@/Helpers';
import transactionAPI from '@/services/TransactionAPI';
import configAPI from '@/services/ConfigAPI';
import { mapActions } from 'vuex';
import Filters from '@/components/filters/Filters.vue';
import { YearlyReportType } from '@/consts/yearlyReport/yearlyReport.consts';

export default Vue.extend({
  name: 'TransactionPerStallionOwnerCard',
  components: { Filters },
  data: (data) => ({
    years: [],
    loading: false,
    transactions: [],
    totalTransactions: 0,
    transactionActions: [],
    transactionTypes: [],
    gustationOptions: [],
    stallionOwnerId: '',
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || 10,
      sortDesc: data.$route.query.sortDesc || false,
    },
    headers: [
      { text: 'Hengsthouder', value: 'stallionOwner', sortable: false },
      { text: 'Naam paard', value: 'name', sortable: false },
      { text: 'Type', value: 'type', sortable: false },
      { text: 'Actie', value: 'action', sortable: false },
      { text: 'Datum', value: 'date', sortable: false },
      { text: 'Merrie', value: 'destinationHorse', sortable: false },
      { text: 'Eigenaar merrie', value: 'destinationHorse.owner', sortable: false },
      { text: 'Drachtigheid', value: 'gustation', sortable: false },
    ],
  }),
  computed: {
    filters() {
      return {
        stallionOwnerId: { vTag: 'searchCustomer', label: 'Hengsthouder zoeken' },
        action: {
          vTag: 'select',
          items: this.transactionActions,
          label: 'Actie',
          dense: true,
          outlined: true,
          clearable: true,
          hideDetails: true,
          cols: 6,
        },
        type: {
          vTag: 'select',
          items: this.transactionTypes,
          label: 'Type',
          dense: true,
          outlined: true,
          clearable: true,
          hideDetails: true,
          cols: 6,
        },
        year: {
          vTag: 'select',
          items: this.years.map((year) => year.toString()),
          label: 'Jaar',
          dense: true,
          outlined: true,
          clearable: true,
          hideDetails: true,
          cols: 6,
        },
      };
    },
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.compareRouteAndPush(
          {
            ...this.$route.query,
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortDesc: this.options.sortDesc,
          },
          this.$route,
          this.$router,
        );
      },
    },
  },
  async beforeMount() {
    this.loading = true;
    await Promise.all([this.getTransactionActions(), this.getTransactionTypes(), this.getYears()]);
    const result = await this.getTransactions(this.$route.query);

    this.loading = false;
    this.transactions = result?.data?.results;
    this.totalTransactions = result?.data?.total;
    this.getGustationOptions();
  },
  methods: {
    ownerName,
    formatDate,
    horseName,
    compareRouteAndPush,
    ...mapActions({
      resetError: 'errorMessage/resetError',
      setError: 'errorMessage/setErrorMessage',
    }),
    async getYears() {
      this.loading = true;
      try {
        const { data } = await transactionAPI.getYears();
        this.years = data;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setErrorMessage', error);
      } finally {
        this.loading = false;
      }
    },
    async getTransactions(urlParams) {
      return transactionAPI.getTransactionsPerStallionOwner(urlParams);
    },
    async getTransactionActions() {
      this.loading = true;
      this.resetError();
      try {
        const { data } = await configAPI.getTransactionActions();

        this.transactionActions = data.transactionActions;
      } catch (error) {
        await this.setError(error);
      } finally {
        this.loading = false;
      }
    },
    async getGustationOptions() {
      this.loading = true;
      try {
        const { data } = await configAPI.getTransactionGustationTypes();
        this.gustationOptions = data.transactionGustationTypes;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setErrorMessage', error);
      } finally {
        this.loading = false;
      }
    },
    async updateTransaction(transaction, selectedGustation) {
      try {
        await this.$store.dispatch('errorMessage/resetError');
        await transactionAPI.putTransaction(transaction._id, { transaction: { ...transaction, gustation: selectedGustation } });

        this.getTransactions(this.selectedYear, this.stallionOwnerId);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      }
    },
    async getTransactionTypes() {
      this.loading = true;
      this.resetError();
      try {
        const { data } = await configAPI.getTransactionTypes();

        this.transactionTypes = data.transactionTypes.filter(
          (type) => type === YearlyReportType.FRESH_SEMEN || type === YearlyReportType.FROZEN_SEMEN,
        );
      } catch (error) {
        await this.setError(error);
      } finally {
        this.loading = false;
      }
    },
    updateStallionOwner(searchResult) {
      this.stallionOwnerId = searchResult?._id;
    },
  },
});
