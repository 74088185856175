import { get } from '@/services/API';

export default {
  getProductConfig: () => get('/api/config/product'),
  getModConfig: () => get('/api/config/mod'),
  getHorseTypes: () => get('/api/config/horse'),
  getVetNames: () => get('/api/config/vets'),
  getResearchConfig: () => get('/api/config/research'),
  getCountries: () => get('/api/config/countries'),
  getContactRoles: () => get('/api/config/contacts'),
  getCaseStatus: () => get('/api/config/status'),
  getColors: () => get('/api/config/colors'),
  getNotificationConfig: () => get('/api/config/notification'),
  getSemenMods: () => get('/api/config/semen'),
  getProtocolReportTypes: () => get('/api/config/reportTypes'),
  getCustomerConfig: () => get('/api/config/vatLiability'),
  getTransactionTypes: () => get('/api/config/transactionTypes'),
  getTransactionActions: () => get('/api/config/transactionActions'),
  getTransactionGustationTypes: () => get('/api/config/transactionGustationTypes'),
};
