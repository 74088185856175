<template>
  <v-btn
    :loading="loading"
    :color="color"
    depressed
    class="ml-1"
    @click="synchronise"
  >
    <v-icon left>
      {{ icon }}
    </v-icon>
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  name: 'SyncButton',
  props: {
    exec: { type: Function, required: true },
    type: { type: String, required: true },
  },
  data: () => ({
    loading: false,
    icon: 'mdi-sync',
    color: 'primary',
    text: 'Synchroniseer',
  }),
  created() {
    this.styleSyncButton('primary', 'mdi-sync', `Sync ${this.type}`);
  },
  methods: {
    styleSyncButton(color, icon, text) {
      this.color = color;
      this.icon = icon;
      this.text = text;
    },
    async synchronise() {
      this.styleSyncButton('primary', 'mdi-sync', `Sync ${this.type}`);
      this.loading = true;
      try {
        await this.exec();
        this.styleSyncButton('success', 'mdi-check', 'Succesvol');
      } catch (err) {
        this.styleSyncButton('error', 'mdi-close', 'Mislukt');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
