
import { isLoggedIn, login, logout } from '@/services/auth';
import Vue from 'vue';
import Dashboard from '@/components/dashboard/Dashboard.vue';

export default Vue.extend({
  components: { Dashboard },

  methods: {
    handleLogin() {
      login();
    },
    handleLogout() {
      logout();
    },
    isLoggedIn() {
      return isLoggedIn();
    },
  },
});
