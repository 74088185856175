export function generateCsvData(headers, subheaders, data) {
  let csvData = 'data:text/csv;charset=utf-8,';
  let subheaderIndex = 0;
  headers.forEach((header) => {
    for (let j = 0; j < header.colspan; j += 1) {
      csvData += `${header.text} - ${subheaders[subheaderIndex].text}`;
      if (subheaderIndex < subheaders.length - 1) {
        csvData += ',';
      }
      subheaderIndex += 1;
    }
  });
  csvData += '\n';
  if (data && data.length) {
    data.forEach((dataLine) => {
      csvData += `${subheaders.map((subheader) => dataLine[subheader.value]).join(',')}\n`;
    });
  }
  return csvData;
}
