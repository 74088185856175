<template>
  <v-dialog
    v-model="deleteDialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title>{{ item }} verwijderen</v-card-title>
      <v-card-text v-if="deleteQueue.name">
        <p>U staat op het punt om <b>{{ deleteQueue.name }}</b> te verwijderen.</p>
      </v-card-text>
      <v-card-text v-else>
        <p>Bent u zeker dat u dit item wil verwijderen?</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="$emit('close', false)"
        >
          Annuleren
        </v-btn>
        <v-btn
          color="error"
          text
          @click="$emit('delete', deleteQueue)"
        >
          Verwijderen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteDialog',
  props: ['deleteDialog', 'deleteQueue', 'item'],
};
</script>
