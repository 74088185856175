<template>
  <yearly-report-table />
</template>

<script>
import YearlyReportTable from '@/components/yearly-report/YearlyReportTable';

export default {
  components: { YearlyReportTable },
};
</script>
