import Vue from 'vue';
import VueRouter from 'vue-router';
import CallBack from '@/components/Callback';
import { requireAuth } from '@/services/auth';
import Case from '@/views/case/Case';
import CaseIndex from '@/views/case/index';
import Customer from '@/views/customer/Customer';
import CustomerIndex from '@/views/customer/Index';
import Home from '@/views/Home';
import Horse from '@/views/horse/Horse';
import Horses from '@/views/horse/Horses';
import CreateICSI from '@/views/icsi/Create';
import ICSIEmbryos from '@/views/icsi/Embryos';
import ICSI from '@/views/icsi/index';
import AppliedMedication from '@/views/report/AppliedMedication';
import DeathHorse from '@/views/report/DeathHorse';
import DeliveredProducts from '@/views/report/DeliveredProducts';
import ReportLocation from '@/views/report/SurrogatePerLocation';
import OutgoingProducts from '@/views/report/OutgoingProducts';
import SemenDelivery from '@/views/report/SemenDelivery.vue';
import labos from '@/views/settings/labos';
import labo from '@/views/settings/labos/labo';
import Location from '@/views/settings/locations';
import NitrogenContainer from '@/views/settings/nitrogenContainer';
import PensionIndex from '@/views/settings/pension/index';
import Pension from '@/views/settings/pension/Pension';
import products from '@/views/settings/products';
import product from '@/views/settings/products/product';
import protocols from '@/views/settings/protocols';
import protocol from '@/views/settings/protocols/protocol';
import ResearchSheets from '@/views/settings/ResearchSheets';
import batch from '@/views/stock/batch';
import Stock from '@/views/stock/index';
import uploadSemenStock from '@/views/settings/uploadSemenStock';
import SemenHorses from '@/views/semen/index';
import Semen from '@/views/semen/Semen';
import CreateSemen from '@/views/semen/Create';
import SemenStock from '@/views/report/SemenStock';
import InsemnationReport from '@/views/report/Insemination.report';
import VetcertificateReport from '@/views/report/Vetcertificate.report';
import TransactionPerStallionOwner from '@/views/transaction/transactionPerStallionOwner';
import YearlyReport from '@/views/yearly-report/index.vue';
import TypeYearlyReportTable from '@/views/yearly-report/tables/TypeYearlyReportTable';
import GestationReport from '@/views/report/Gestation.report';
import LiveStockRegister from '@/views/livestock-register/LiveStockRegister';
import CoveredMaresList from '@/views/covered-mares/index';
import AvailableLocationsOverview from '@/views/available-locations/index';
import StorageCostsOverview from '@/views/storage-costs/index.vue';
import ICSIStorageCostsDetailsOverview from '@/views/storage-costs/icsiDetail.vue';
import SemenCostsDetailsOverview from '@/views/storage-costs/semenDetail.vue';
import IcsiDeliveryReport from '@/views/report/IcsiDeliveryReport.vue';
import TeamleaderLogin from '@/views/teamleader/index';

Vue.use(VueRouter);

const routes = [
  {
    component: Home,
    name: 'home',
    path: '/',
  },
  {
    beforeEnter: requireAuth,
    component: StorageCostsOverview,
    name: 'Stockage kosten',
    path: '/fertility/stock-costs',
  },
  {
    beforeEnter: requireAuth,
    component: ICSIStorageCostsDetailsOverview,
    name: 'ICSI - Stockage kosten detail',
    path: '/fertility/icsi/stock-costs-details/:id',
    props: true,
  },
  {
    beforeEnter: requireAuth,
    component: SemenCostsDetailsOverview,
    name: 'Sperma - Stockage kosten detail',
    path: '/fertility/semen/stock-costs-details/:id',
    props: true,
  },
  {
    beforeEnter: requireAuth,
    component: ICSI,
    name: 'ICSI',
    path: '/icsi',
  },
  {
    beforeEnter: requireAuth,
    component: CreateICSI,
    path: '/icsi/create',
    props: true,
  },
  {
    beforeEnter: requireAuth,
    component: ICSIEmbryos,
    path: '/icsi/:id',
    props: true,
  },
  {
    beforeEnter: requireAuth,
    component: IcsiDeliveryReport,
    path: '/icsi/:id/delivery-report',
    props: true,
  },
  {
    beforeEnter: requireAuth,
    component: SemenHorses,
    name: 'Semen',
    path: '/semen',
  },
  {
    beforeEnter: requireAuth,
    component: Semen,
    path: '/semen/:id',
    props: true,
  },
  {
    beforeEnter: requireAuth,
    component: CreateSemen,
    path: '/semen/create/:id',
    props: true,
  },
  {
    beforeEnter: requireAuth,
    component: Horses,
    name: 'paarden',
    path: '/horse',
  },
  {
    beforeEnter: requireAuth,
    component: Horse,
    path: '/horse/create',
    props: false,
  },
  {
    beforeEnter: requireAuth,
    component: Horse,
    path: '/horse/:id',
    props: true,
  },
  {
    beforeEnter: requireAuth,
    component: CustomerIndex,
    name: 'customer',
    path: '/customer',
  },
  {
    beforeEnter: requireAuth,
    component: Customer,
    name: 'customer page',
    path: '/customer/:id',
    props: true,
  },
  {
    beforeEnter: requireAuth,
    component: ReportLocation,
    name: 'location report',
    path: '/report/location',
  },
  {
    beforeEnter: requireAuth,
    component: DeathHorse,
    name: 'death horses report',
    path: '/report/death-horses',
  },
  {
    beforeEnter: requireAuth,
    component: SemenStock,
    path: '/report/semen-stock',
  },
  {
    beforeEnter: requireAuth,
    component: DeliveredProducts,
    name: 'Stock delivered products',
    path: '/report/delivered-products',
  },
  {
    beforeEnter: requireAuth,
    component: OutgoingProducts,
    name: 'Stock outgoing products',
    path: '/report/outgoing-products',
  },
  {
    beforeEnter: requireAuth,
    component: AppliedMedication,
    name: 'Stock applied medication',
    path: '/report/applied-medication',
  },
  {
    beforeEnter: requireAuth,
    component: InsemnationReport,
    name: 'Insemination report',
    path: '/report/insemination',
  },
  {
    beforeEnter: requireAuth,
    component: VetcertificateReport,
    name: 'Vetcertificate report',
    path: '/report/vetcertificate',
  },
  {
    beforeEnter: requireAuth,
    component: GestationReport,
    name: 'Gestation report',
    path: '/report/gestation',
  },
  {
    beforeEnter: requireAuth,
    component: AvailableLocationsOverview,
    path: '/report/available-locations',
    name: 'Available locations',
  },
  {
    beforeEnter: requireAuth,
    component: SemenDelivery,
    path: '/report/semen-delivery',
    name: 'Semen delivery',
  },
  {
    beforeEnter: requireAuth,
    component: Location,
    name: 'location',
    path: '/settings/location',
  },
  {
    beforeEnter: requireAuth,
    component: NitrogenContainer,
    name: 'Stikstof vaten',
    path: '/settings/nitrogen-container',
  },
  {
    beforeEnter: requireAuth,
    component: ResearchSheets,
    name: 'Onderzoeksfiches',
    path: '/settings/research-sheets',
  },
  {
    beforeEnter: requireAuth,
    component: products,
    name: 'Producten overview',
    path: '/settings/products',
  },
  {
    beforeEnter: requireAuth,
    component: product,
    path: '/settings/product/:id',
    props: true,
  },
  {
    beforeEnter: requireAuth,
    component: protocols,
    name: 'Protocols overview',
    path: '/settings/protocols',
  },
  {
    beforeEnter: requireAuth,
    component: protocol,
    path: '/settings/protocol/:id',
    props: true,
  },
  {
    beforeEnter: requireAuth,
    component: uploadSemenStock,
    path: '/settings/uploadSemenStock',
  },
  {
    beforeEnter: requireAuth,
    component: labos,
    name: 'Labo overview',
    path: '/settings/labos',
  },
  {
    beforeEnter: requireAuth,
    component: labo,
    path: '/settings/labo/:id',
    props: true,
  },
  {
    beforeEnter: requireAuth,
    component: Stock,
    name: 'Stock overview',
    path: '/stock',
  },
  {
    beforeEnter: requireAuth,
    component: batch,
    path: '/stock/:id',
    props: true,
  },
  {
    beforeEnter: requireAuth,
    component: CaseIndex,
    name: 'Cases overview',
    path: '/cases',
  },
  {
    beforeEnter: requireAuth,
    component: Case,
    path: '/case/:id',
    props: true,
  },
  {
    beforeEnter: requireAuth,
    component: PensionIndex,
    name: 'Pension overview',
    path: '/settings/pension',
  },
  {
    beforeEnter: requireAuth,
    component: Pension,
    path: '/settings/pension/:id',
    props: true,
  },
  {
    beforeEnter: requireAuth,
    component: YearlyReport,
    path: '/yearlyReport',
  },
  {
    beforeEnter: requireAuth,
    component: TypeYearlyReportTable,
    path: '/yearlyReport/:type',
    name: 'type-yearly-report-table',
  },
  {
    component: CallBack,
    path: '/callback',
  },
  {
    beforeEnter: requireAuth,
    component: LiveStockRegister,
    path: '/livestock',
    name: 'Livestock register',
  },
  {
    beforeEnter: requireAuth,
    component: CoveredMaresList,
    path: '/covered-mares',
    name: 'Covered mares list',
  },
  {
    beforeEnter: requireAuth,
    component: TransactionPerStallionOwner,
    path: '/transactions-per-stallion-owner',
    name: 'Transactions per stallion owner',
  },
  {
    beforeEnter: requireAuth,
    component: TeamleaderLogin,
    path: '/teamleader-login',
    name: 'Teamleader Login',
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
});

export default router;
