<template>
  <v-layout column fill-height>
    <print-header class="print-only" :title="language === 'NL' ? report.name : report.eng" />
    <v-card class="mx-5 mt-4 no-border-print" :title="report.name" outlined>
      <v-toolbar flat class="no-print">
        <v-toolbar-title>
          {{ language === 'NL' ? report.name : report.eng }}
        </v-toolbar-title>
      </v-toolbar>
      <v-layout column class="mx-4 mb-4 mt-2">
        <v-row no-gutter>
          <v-col key="centre" cols="12" sm="6">
            <v-card class="mx-5 mt-4 pa-2" outlined>
              <v-card-title>
                <h3 class="text-h5">
                  {{ `A. ${strings[language].titleA}` }}
                </h3>
              </v-card-title>
              <div class="mb-2 grid">
                <span>{{ strings[language].name }}</span>
                <span>{{ 'Hof ter leeuwe' }}</span>
                <span>{{ strings[language].address }}</span>
                <span>{{ 'Ganzenkooi 2, 3440 Zoutleeuw' }}</span>
                <span>{{ strings[language].approvalNumber }}</span>
                <span>{{ 'BN 96/05' }}</span>
              </div>
            </v-card>
          </v-col>
          <v-col key="destination" cols="12" sm="6">
            <v-card class="mx-5 mt-4 pa-2" outlined>
              <v-card-title>
                <h3 class="text-h5">
                  {{ `B. ${strings[language].titleB}` }}
                </h3>
              </v-card-title>
              <div class="mb-2 grid">
                <span>{{ strings[language].name }}</span>
                <span v-if="customerDestination">{{ ownerName(customerDestination) }}</span>
                <div v-else>
                  <v-text-field
                    v-model="customer"
                    label="Klant"
                    variant="outlined"
                    class="no-print"
                  />
                  <span class="print-only">{{ customer }}</span>
                </div>
                <span>{{ strings[language].address }}</span>
                <span v-if="customerDestination">{{
                  `${customerDestination.addressLine}, ${customerDestination.zip}
                                  ${customerDestination.city}`
                }}</span>
                <div v-else>
                  <v-text-field
                    v-model="address"
                    label="Adres"
                    variant="outlined"
                    class="no-print"
                  />
                  <span class="print-only">{{ address }}</span>
                </div>
                <span>{{ strings[language].mare }}</span>
                <div>
                  <v-text-field
                    v-model="mareName"
                    label="Naam merrie"
                    variant="outlined"
                    class="no-print"
                  />
                  <span class="print-only">{{ mareName }}</span>
                </div>
                <span>{{ strings[language].breed }}</span>
                <div>
                  <v-text-field
                    v-model="mareBreed"
                    label="Ras merrie"
                    variant="outlined"
                    class="no-print"
                  />
                  <span class="print-only">{{ mareBreed }}</span>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutter>
          <v-col key="stallion" cols="12" sm="6">
            <v-card class="mx-5 mt-4 pa-2" outlined>
              <v-card-title>
                <h3 class="text-h5">
                  {{ `C. ${strings[language].titleC}` }}
                </h3>
              </v-card-title>
              <div class="mb-2 grid">
                <span>{{ strings[language].name ?? '................................' }}</span>
                <span>{{ horseName(stallion) ?? '................................' }}</span>
                <span>{{ strings[language].studbookNumber ?? '................................' }}</span>
                <span>{{ stallion.ueln ?? '................................' }}</span>
                <span>{{ strings[language].studbook ?? '................................' }}</span>
                <span>{{ stallion.studbook ?? '................................' }}</span>
              </div>
            </v-card>
          </v-col>
          <v-col key="semen" cols="12" sm="6">
            <v-card class="mx-5 mt-4 pa-2" outlined>
              <v-card-title>
                <h3 class="text-h5">
                  {{ `D. ${strings[language].titleD}` }}
                </h3>
              </v-card-title>
              <div class="mb-2 grid">
                <span>{{ strings[language].gainDate }}</span>
                <span>{{ formatDate(gainDate) }}</span>
                <span>{{ strings[language].numberForDose }}</span>
                <span class="pt-4">................................</span>
                <span>{{ strings[language].numberInDelivery }}</span>
                <span class="pt-4">................................</span>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutter class="no-print">
          <v-col key="validation" cols="12">
            <v-card class="mx-5 mt-4 pa-2" outlined>
              <v-card-title>
                <h3 class="text-h5">
                  {{ `E. ${strings[language].titleE}` }}
                </h3>
              </v-card-title>
              <div class="mb-2 grid">
                <span>{{ strings[language].handedOverAt }}</span>
                <span>{{ 'Zoutleeuw' }}</span>
                <span>{{ strings[language].onDate }}</span>
                <span>{{ formatDate(currentDate) }}</span>
                <span>{{ strings[language].signatureOfResponsable }}</span>
                <signature-display v-if="doctor" class="ma-5" :doctor="doctor" />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
      <span class="mx-9 mb-6">{{ strings[language].note }}</span>
      <v-row no-gutter>
        <v-col key="validation" cols="12">
          <v-card class="mt-4 pa-4 no-print" flat>
            <v-card-title>
              <h3 class="text-h5">
                {{ 'Selectie velden' }}
              </h3>
            </v-card-title>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="doctor"
                  :items="doctors"
                  label="selecteer ondergetekende"
                  :menu-props="{ offsetY: true }"
                  hide-details
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="language"
                  :items="languages"
                  label="selecteer een taal"
                  :menu-props="{ offsetY: true }"
                  hide-details
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="6">
                <search-customer
                  :outlined="true"
                  :clearable="true"
                  :customer="customerDestination"
                  @emit-customer="changeCustomer"
                />
              </v-col>
              <v-col cols="6">
                <date-picker
                  label="Selecteer de winning datum"
                  :range="false"
                  :date="currentDate"
                  :expanded="true"
                  :no-border="true"
                  :close-on-input="true"
                  :hide-details="true"
                  :outlined="true"
                  :clearable="true"
                  @select-date="gainDate = $event"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="end" dense>
        <v-btn
          style="width: fit-content"
          color="primary"
          class="mr-2 mb-5 no-print"
          depressed
          @click="$router.back()"
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          terug
        </v-btn>
        <v-btn
          style="width: fit-content"
          color="success"
          class="mr-5 mb-5 no-print"
          depressed
          @click="print"
        >
          print
        </v-btn>
      </v-row>
    </v-card>
  </v-layout>
</template>

<script>
import SignatureDisplay from '@/components/SignatureDisplay/SignatureDisplay.vue';
import PrintHeader from '@/components/common/PrintHeader.vue';
import SearchCustomer from '@/components/search/SearchCustomer.vue';
import DatePicker from '@/components/DateSelector/DatePicker';
import { print, formatDate, horseName, ownerName } from '@/Helpers';
import { CaseAPI, configAPI } from '@/services';
import { strings } from '@/consts/translations/SemenDelivery.report.translation.js';

export default {
  name: 'SemenDelivery',
  components: { PrintHeader, SignatureDisplay, SearchCustomer, DatePicker },
  props: {},
  data: () => ({
    caseDate: new Date(),
    currentDate: new Date(),
    gainDate: new Date(),
    handledCase: {},
    title: '',
    doctor: null,
    doctors: ['Dr. Degeneffe Erik', 'Degeneffe Wim'],
    language: 'NL',
    languages: ['NL', 'ENG'],
    report: {
      name: '',
      eng: '',
    },
    customerDestination: {
      name: '',
      address: '',
    },
    mareName: undefined,
    mareBreed: undefined,
    stallion: {},
    strings,
    customer: null,
    address: null,
  }),
  mounted() {
    const { caseId, date, title } = this.$route.query;
    this.caseDate = new Date(date);
    this.title = title;
    Promise.all([this.getCase(caseId), this.getProtocolReportTypes(title)]);
  },
  methods: {
    print,
    formatDate,
    horseName,
    ownerName,
    async getCase(caseId) {
      try {
        const { data } = await CaseAPI.get(caseId);
        this.handledCase = data;

        this.stallion = data.horse;
        this.customerDestination = data.customer;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      }
    },
    async getProtocolReportTypes(title) {
      try {
        const { data: { reportTypes } } = await configAPI.getProtocolReportTypes();
        const [report] = reportTypes.filter((rep) => rep.name === title);
        this.report = report;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      }
    },
    changeCustomer(customer) {
      if (!customer) {
        this.customerDestination = undefined;
      } else {
        this.customerDestination = customer;
      }
    },
    changeStallion(stallion) {
      this.stallion = stallion;
    },
  },
};
</script>

<style scoped>
b, h3, span, time, div, strong, .v-toolbar__title {
  font-family: Arial;
}

b,
span,
time,
div {
  color: #1A1449;
}

strong,
.v-toolbar__title {
  font-weight: bolder;
  color: #FF5233;
}

div.grid {
  color: #1A1449;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
