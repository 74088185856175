
import { productsAPI } from '@/services';
import format from 'date-fns/format';
import Vue from 'vue';

export default Vue.extend({
  name: 'ToExpireProductsBlock',
  data: () => ({
    headers: [
      { text: 'Product', value: 'name', sortable: false },
      { text: 'Lot', value: 'lotnumber', sortable: false },
      { text: 'Vervaldatum', value: 'expire_date', sortable: false },
    ],
    toExpireProducts: [],
    loading: false,
  }),
  beforeMount() {
    this.getToExpireProducts();
  },
  methods: {
    format,
    goToProduct(item) {
      this.$router.push(`/stock/${item._id}`);
    },
    async getToExpireProducts() {
      this.loading = true;
      try {
        const { data } = await productsAPI.getToExpireProducts({ limit: 5 });
        this.toExpireProducts = data;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setErrorMessage', error);
      } finally {
        this.loading = false;
      }
    },
  },
});
