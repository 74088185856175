import { get, post, put, deleteR } from '@/services/API';

export default {
  postProtocol: (protocol) => post('/api/protocol', protocol),
  getAllProtocols: (params) => get(`/api/protocol?${new URLSearchParams(params)}`),
  getProtocol: (id) => get(`/api/protocol/${id}`),
  putProtocol: (protocol) => put(`/api/protocol/${protocol._id}`, protocol),
  deleteProtocol: (id) => deleteR(`/api/protocol/${id}`),
  searchProtocol: (searchValue, params) => get(`/api/protocol/search?searchValue=${searchValue}&${new URLSearchParams(params)}`),
};
