
import Vue from 'vue';

export default Vue.extend({
  name: 'YearlyLiveStockTable',
  props: {
    groups: { type: Object, default: () => {} },
    loading: { type: Boolean, default: false },
    months: { type: Array, default: () => [{ id: 0, name: '' }] },
  },
  data: () => ({
    headers: [
      { text: 'Maand', sortable: false, value: 'maand' },
      { text: 'Aantal paarden per stal', sortable: false, value: 'horseCount', colspan: 3 },
    ],
  }),
  methods: {
    getOccupation(group, month) {
      return this.groups[group].months[month] ? this.groups[group].months[month].occupation : 'N.V.T.';
    },
  },
});
