import { get, put } from '@/services/API';

export default {
  getStallionTransactions: (params: any) => get(`/api/transaction/stallion?${new URLSearchParams(params)}`),
  getStallionTransactionSum: (params: any) => get(`/api/transaction/stallion-sum?${new URLSearchParams(params)}`),
  getMareTransactions: (params: any) => get(`/api/transaction/mare?${new URLSearchParams(params)}`),
  getMareTransactionSum: (params: any) => get(`/api/transaction/mare-sum?${new URLSearchParams(params)}`),
  getYears: () => get('/api/transaction/years'),
  getTransactionsPerStallionOwner: (params: any) => get(`/api/transaction/transactions-per-stallion-owner?${new URLSearchParams(params)}`),
  putTransaction: (id: number, data: any) => put(`/api/transaction/${id}`, data),
};
