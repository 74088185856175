<template>
  <horse-table
    :headers="headers"
    :filters="filters"
    :sort-by="sortBy"
    :is-surrogate-per-location-list="true"
  />
</template>
<script>
import horseTable from '@/components/horse/Table';
import { horseAPI, locationAPI } from '@/services';
import { horseName } from '@/Helpers';
import { displayLocation } from '@/Helpers/location.helper';

export default {
  components: { horseTable },
  data: () => ({
    locations: [],
    headers: [
      { text: 'Naam', value: 'name', selected: true },
      { text: 'Type', value: 'type', sortable: false, selected: false },
      { text: 'Brandnummer', value: 'surrogate_uid', selected: true },
      { text: 'Microchip', value: 'microchip', selected: true },
      { text: 'Locatie', value: 'location', selected: true },
      { text: 'Eigenaar', value: 'owner', selected: false },
      { text: 'Draagmoeder', value: 'surrogate', selected: false },
      { text: 'Aangemaakt op', align: 'end', value: 'createdAt', selected: false },
      { text: 'Gewijzigd op', align: 'end', value: 'updatedAt', selected: false },
      { text: 'Geboortedatum', align: 'end', value: 'date_of_birth', selected: false },
    ],
    sortBy: 'location',
  }),
  computed: {
    filters() {
      return {
        owner: { vTag: 'searchCustomer' },
        location: {
          vTag: 'select',
          label: 'Filter op locatie',
          items: this.locations,
          cols: 6,
          objectItems: {
            text: (item) => displayLocation(item),
            value: '_id',
          },
        },
        microchip: {
          vTag: 'basicSearch',
          label: 'Microchip',
          searchFunction: this.searchMicrochip,
          format: this.horseName,
          getSpecific: this.getSpecificHorse,
          returnValue(item) { return item.microchip; },
          cols: 6,
        },
        surrogate_uid: {
          vTag: 'basicSearch',
          label: 'Brandnummer',
          searchFunction: this.searchSurrogateUID,
          format: this.horseName,
          getSpecific: this.getSpecificHorse,
          returnValue(item) { return item.surrogate_uid; },
        },
        aliveOnly: { vTag: 'switch', label: 'Verberg gestorven paarden' },
      };
    },
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    horseName,
    displayLocation,
    async searchMicrochip(v) {
      const { data } = await horseAPI.searchMicrochip(v, {
        surrogate: true,
        type: 'Merrie',
        limit: 10,
      });
      return data;
    },
    async searchSurrogateUID(v) {
      const { data } = await horseAPI.searchSurrogateUID(v, {
        surrogate: true,
        type: 'Merrie',
        limit: 10,
      });
      return data;
    },
    async getSpecificHorse(query) {
      const { data: { results } } = await horseAPI.getHorses({
        ...query,
        surrogate: true,
        type: 'Merrie',
      });
      return results && results.length ? results[0] : undefined;
    },
    async getLocations() {
      try {
        const { data: { locations } } = await locationAPI.getLocations();
        this.locations = locations;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      }
    },
  },
};
</script>
