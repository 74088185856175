import { get } from '@/services/API';

export default {
  getOptions: () => get('/api/yearlyReport/options'),
  semenByStallion: (params) => get(`/api/yearlyReport/semenByHorse?${new URLSearchParams(params)}`),
  semenByBreed: (params) => get(`/api/yearlyReport/semenByBreed?${new URLSearchParams(params)}`),
  ovumByBreed: (params) => get(`/api/yearlyReport/ovumByBreed?${new URLSearchParams(params)}`),
  icsiEmbryoByBreed: (params) => get(`/api/yearlyReport/icsiEmbryoByBreed?${new URLSearchParams(params)}`),
  rinsedEmbryoByBreed: (params) => get(`/api/yearlyReport/rinsedEmbryoByBreed?${new URLSearchParams(params)}`),
};
