<template>
  <div>
    <v-card outlined>
      <v-toolbar
        flat
        dense
      >
        <v-toolbar-title>{{ title | capitalize }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          v-if="!disabled"
          color="primary"
          icon
          @click="addRow"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider v-if="boardings.length" />
      <v-data-table
        v-if="boardings.length"
        v-model="selected"
        item-key="index"
        :items="boardings"
        :headers="headers"
        :hide-default-footer="boardings.length < 10"
      >
        <template #no-data>
          Geen pension gevonden
        </template>
        <template #item="props">
          <tr>
            <td>
              <v-checkbox
                v-if="props.item._id"
                :disabled="!props.item.billable || !props.item.to || !props.item.type || !props.item.from"
                :input-value="props.isSelected"
                class="ma-0 py-0"
                hide-details
                dense
                @change="props.select($event)"
                @click.stop="(event) => event.stopPropagation()"
              />
            </td>
            <td>
              <v-menu
                offset-y
                transition="slide-x-transition"
                left
                :disabled="!props.item.billable"
              >
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="d-block"
                    v-on="on"
                  >
                    {{ props.item.type ? props.item.type.name : '-' }}
                  </span>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(pensionType, index) in pensionTypes"
                    :key="index"
                    @click="props.item.type = pensionType"
                  >
                    <v-list-item-title>{{ pensionType.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td>
              <span v-if="props.item._id">
                {{ showLocation(props.item._id) }}
              </span>
            </td>
            <td>
              <v-edit-dialog
                v-if="props.item.billable"
                :return-value.sync="props.item.from"
                large
              >
                {{ formatDate(props.item.from) }}
                <template #input>
                  <simple-date-picker
                    :date="props.item.from"
                    :no-border="true"
                    @select-date="props.item.from = $event"
                  />
                </template>
              </v-edit-dialog>
              <span v-else>
                {{ formatDate(props.item.from) }}
              </span>
            </td>
            <td>
              <v-edit-dialog
                v-if="props.item.billable"
                :return-value.sync="props.item.to"
                large
              >
                {{ formatDate(props.item.to) || '-' }}
                <template #input>
                  <simple-date-picker
                    :date="props.item.to"
                    :no-border="true"
                    :min="props.item.from"
                    @select-date="props.item.to = $event"
                  />
                </template>
              </v-edit-dialog>
              <span v-else>
                {{ formatDate(props.item.to) }}
              </span>
            </td>
            <td class="text-end">
              <v-btn
                icon
                small
                color="error"
                :disabled="!props.item.billable"
                @click="removeRow(props.item)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { pensionAPI } from '@/services';
import { accountName, today, formatDate } from '@/Helpers';
import SimpleDatePicker from '@/components/DateSelector/SimpleDatePicker';
import { displayLocation } from '@/Helpers/location.helper.ts';

export default {
  components: { SimpleDatePicker },
  props: ['title', 'pensions', 'disabled', 'horse', 'success'],
  data: () => ({
    boardings: [],
    type: {},
    pensionTypes: [],
    valid: false,
    selected: [],
    headers: [
      { text: '', value: 'select', sortable: false },
      { text: 'Type', value: 'type', width: '30%', sortable: false },
      { text: 'Locatie', value: 'location', sortable: false },
      { text: 'Aankomst', value: 'from', sortable: false },
      { text: 'Vertrek', value: 'to', sortable: false },
      { text: '', value: 'actions', align: 'end', sortable: false },
    ],
  }),
  watch: {
    horse: {
      handler() {
        if (this.horse) {
          this.getAllPensionTypes();
        }
      },
      deep: true,
      immediate: true,
    },
    pensions() {
      if (this.pensions) {
        this.boardings = this.pensions;
      }
    },
    boardings: {
      handler() {
        this.$emit('save', this.boardings);
      },
      deep: true,
    },
    selected() {
      this.$emit('select-pensions', { pensions: this.selected.map((sheet) => sheet._id) });
    },
    success() {
      if (this.success) {
        this.selected = [];
      }
    },
  },
  methods: {
    accountName,
    formatDate,
    displayLocation,
    addRow() {
      this.boardings.push({
        billable: true,
        from: today(),
        to: '',
        index: this.boardings.length,
      });
    },
    removeRow(pension) {
      const index = this.boardings.findIndex((boarding) => boarding === pension);
      this.boardings.splice(index, 1);
    },
    async getAllPensionTypes() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data: { results } } = await pensionAPI.getAll({ availableFor: this.horse?.type });
        this.pensionTypes = results;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
    showLocation(pensionId) {
      return displayLocation(this.horse.lodgings.find((lodging) => lodging.pension === pensionId)?.location);
    },
  },
};
</script>
