<template>
  <div>
    <v-row class="mx-0">
      <v-col
        cols="12"
        class="information d-flex align-center"
      >
        <span class="ml-2">Hier heb je de mogelijkheid om meldingen toe te voegen.</span>
      </v-col>
    </v-row>
    <v-row class="ma-5">
      <v-col
        cols="12"
        md="2"
      >
        <v-btn
          depressed
          color="primary"
          class="mt-3"
          @click="notifications.push({ text: '', days: undefined, tags: [] })"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Notificatie
        </v-btn>
      </v-col>
      <v-col cols="10">
        <v-row
          v-for="(notification, i) in notifications"
          :key="i"
          class="mb-2"
        >
          <v-btn
            color="error"
            icon
            class="mt-6"
            @click="notifications.splice(i, 1)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-col cols="5">
            <v-text-field
              v-model="notification.text"
              :rules="required"
              label="Boodschap"
              hide-details
              type="text"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="notification.days"
              label="Aantal dagen"
              hide-details
              :rules="required"
              type="number"
            />
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="notification.tags"
              :menu-props="{ OffsetY: true}"
              :items="tags"
              label="Tags"
              item-text="value"
              item-value="key"
              multiple
              :rules="required"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required } from '@/Helpers';
import { configAPI } from '@/services';

export default {
  props: {
    data: { type: Array, required: true },
  },
  data: () => ({
    notifications: [],
    tags: [],
  }),
  computed: { required },
  watch: {
    data() {
      this.notifications = this.data;
    },
    notifications: {
      handler() {
        this.$emit('update', this.notifications);
      },
      deep: true,
    },
  },
  mounted() {
    this.getNotificationTags();
    this.notifications = this.data;
  },
  methods: {
    async getNotificationTags() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data: { tags } } = await configAPI.getNotificationConfig();
        this.tags = Object.entries(tags).map(([key, value]) => ({ value, key }));
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center
}

.information {
  background-color: #f6f6f6;
  border-left: solid 4px rgba(0, 0, 0, .12);
}
</style>
