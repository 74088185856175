import auth0 from 'auth0-js';
import decode from 'jwt-decode';
import Router from 'vue-router';

const ID_TOKEN_KEY = 'id_token';
const ACCESS_TOKEN_KEY = 'access_token';

const CLIENT_ID = 'ikWVRx69KalfttdwTkW5lzcMy71Q9he7';
const CLIENT_DOMAIN = 'equibase.eu.auth0.com';
const REDIRECT = process.env.VUE_APP_AUTH0_REDIRECT;
const SCOPE = 'full_access';
const AUDIENCE = 'https://api.equibase.app';

const auth = new auth0.WebAuth({
  clientID: CLIENT_ID,
  domain: CLIENT_DOMAIN,
});

export function login() {
  auth.authorize({
    responseType: 'token id_token',
    redirectUri: REDIRECT,
    audience: AUDIENCE,
    scope: SCOPE,
  });
}

export const checkSession = async () => new Promise((resolve, reject) => {
  auth.checkSession({
    scope: SCOPE,
    audience: AUDIENCE,
    redirectUri: REDIRECT,
    responseType: 'token id_token',
  }, (err, authResult) => {
    if (err) return reject(err);
    const {
      accessToken,
      idToken,
    } = authResult;
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(ID_TOKEN_KEY, idToken);
    return resolve(authResult);
  });
});

const router = new Router({
  mode: 'history',
});

function clearIdToken() {
  localStorage.removeItem(ID_TOKEN_KEY);
}

function clearAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}

export function logout() {
  clearIdToken();
  clearAccessToken();
  router.go('/');
}

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken);
  if (!token.exp) {
    return null;
  }

  const date = new Date(0);
  date.setUTCSeconds(token.exp);

  return date;
}

const isTokenExpired = (token) => getTokenExpirationDate(token) < new Date();

export const getIdToken = () => localStorage.getItem(ID_TOKEN_KEY);

export function isLoggedIn() {
  if (process.env.NODE_ENV === 'development') {
    // return true;
  }
  const idToken = getIdToken();
  return !!idToken && !isTokenExpired(idToken);
}

export function requireAuth(to, from, next) {
  if (!isLoggedIn()) {
    next({
      path: '/',
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
}

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

// Helper function that will allow us to extract the access_token and id_token
const getParameterByName = (name) => {
  const match = RegExp(`[#&]${name}=([^&]*)`)
    .exec(window.location.hash);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

// Get and store access_token in local storage
export function setAccessToken() {
  const accessToken = getParameterByName('access_token');
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

// Get and store id_token in local storage
export function setIdToken() {
  const idToken = getParameterByName('id_token');
  localStorage.setItem(ID_TOKEN_KEY, idToken);
}
