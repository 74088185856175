<template>
  <v-autocomplete
    v-model="selected"
    :label="'Zoek locatie'"
    :items="items"
    item-value="_id"
    item-text="fullStable"
    return-object
    hide-no-data
    flat
    :search-input.sync="search"
    prepend-inner-icon="mdi-magnify"
    clearable
    :menu-props="{ closeOnContentClick: true }"
    outlined
    multiple
    @click:clear="clear"
  >
    <template #item="{ item }">
      <v-list-item @click="emit(item)">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.fullStable }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>
import { mapActions } from 'vuex';
import { locationAPI } from '@/services';
import { displayLocation } from '@/Helpers/location.helper';

export default {
  name: 'SearchLocation',
  props: {
    id: { type: Array, default: undefined },
  },
  data: () => ({
    selected: [],
    search: null,
    entries: [],
  }),
  computed: {
    items() {
      if (this.selected.length && this.selected[0]._id && !this.entries.length) {
        return this.selected;
      }

      return [...this.selected, ...this.entries];
    },
  },
  watch: {
    search(val) {
      if (val && !this.selected.includes(val)) this.searchLocation(val);
    },
    $route: {
      immediate: true,
      deep: true,
      handler() {
        if (this.id.length && !this.entries.length) {
          this.id.map(async (id) => this.getLocationById(id));
          this.$emit('fetched-item', this.selected);
        } else if (!this.entries.length) {
          this.searchLocation();
        }
      },
    },
  },
  methods: {
    displayLocation,
    ...mapActions({
      resetError: 'errorMessage/resetError',
      setError: 'errorMessage/setErrorMessage',
    }),
    async getLocationById(id) {
      try {
        const { data } = await locationAPI.getLocation(id);

        this.selected.push({
          ...data,
          fullStable: displayLocation(data),
        });
      } catch (error) {
        await this.setError(error);
      }
    },
    async searchLocation(search) {
      try {
        const { data } = await locationAPI.searchLocations(search);

        this.entries = data.map((item) => ({
          ...item,
          fullStable: displayLocation(item),
        }));
      } catch (error) {
        await this.setError(error);
      }
    },
    clear() {
      this.selected = [];
      this.$emit('emit-location', undefined);
    },
    emit(item) {
      this.selected.push(item);
      this.$emit('emit-location', this.selected);
    },
  },
};
</script>
