<template>
  <v-layout
    column
    fill-height
  >
    <div>
      <div v-if="$route.query.error">
        <v-alert
          type="error"
          outlined
          class="mx-5 mt-5"
        >
          <span>An error occured while loging in to Teamleader</span>
        </v-alert>
      </div>
      <div v-else>
        <v-alert
          type="info"
          outlined
          class="mx-5 mt-5"
        >
          <span>You have successfully logged in to Teamleader</span>
        </v-alert>
      </div>
      <v-btn
        depressed
        block
        @click="handleReturn()"
      >
        Return to page
      </v-btn>
    </div>
  </v-layout>
</template>

<script>
import { teamleaderAPI } from '@/services';

export default {
  name: 'TeamleaderLogin',
  methods: {
    async handleReturn() {
      const { code } = this.$route.query;
      await teamleaderAPI.postReceivedCode(code);
      this.$router.go(-2);
    },
  },
};

</script>
