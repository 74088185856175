import { get, post, put, deleteR } from '@/services/API';

export default {
  post: (obj) => post('/api/case', obj),
  getAll: (params) => get(`/api/case?${new URLSearchParams(params)}`),
  get: (id) => get(`/api/case/${id}`),
  put: (obj) => put(`/api/case/${obj._id}`, obj),
  delete: (id) => deleteR(`/api/case/${id}`),
  search: (searchValue, params) => get(`/api/case/search?searchValue=${searchValue}&${new URLSearchParams(params)}`),
};
