<template>
  <div style="display: flex; height: 200px; align-items: center;">
    <v-layout
      align-center
      justify-end
      style="position: relative; height: 200px; width: 250px;"
    >
      <stamp
        :stamp="stamp"
        style="position: absolute;"
      />
      <img
        v-if="doctor"
        style="width: 200px; position: absolute; opacity: .6"
        :src="signature"
        :alt="`signature ${doctor}`"
      >
    </v-layout>
    <div class="ml-5">
      <stamp />
    </div>
  </div>
</template>

<script>
import Stamp from '@/components/SignatureDisplay/Stamp';
import signatureWim from './sign-wim.png';
import signatureErik from './sign-erik.png';

export default {
  components: { Stamp },
  props: {
    doctor: { type: String, default: undefined },
    height: { type: Number, default: 200 },
  },
  data: () => ({
    stampWim: {
      title: 'Wim Degeneffe',
      line1: 'Dierenarts N4942',
      line2: 'Dorpsstraat 22',
      line3: '3350 Melkwezer',
    },
    stampErik: {
      title: 'Dr. Erik Degeneffe',
      line1: 'Dierenarts N1865',
      line2: 'Ridderstraat 41',
      line3: '3440 Zoutleeuw',
    },
  }),
  computed: {
    signature() {
      if (this.doctor && this.doctor.includes('Wim')) {
        return signatureWim;
      }
      if (this.doctor && this.doctor.includes('Erik')) {
        return signatureErik;
      }
      return 'selecteer ondergetekende';
    },
    stamp() {
      if (this.doctor && this.doctor.includes('Wim')) {
        return this.stampWim;
      }
      if (this.doctor && this.doctor.includes('Erik')) {
        return this.stampErik;
      }
      return 'selecteer ondergetekende';
    },
  },
  methods: {
  },
};
</script>
