export const strings = {
  ENG: {
    titleA: 'Centre',
    titleB: 'Destination',
    titleC: 'Stallion',
    titleD: 'Semen',
    titleE: 'Validation',
    name: 'Name:',
    address: 'Address:',
    approvalNumber: 'Approval number:',
    mare: 'Mare:',
    breed: 'Breed:',
    studbookNumber: 'Studbook nr.:',
    studbook: 'Studbook:',
    gainDate: 'Gain date:',
    numberForDose: 'Number of tubes/straws for 1 dose:',
    numberInDelivery: 'Number of tubes/straws in this shipment:',
    handedOverAt: 'Handed over at:',
    onDate: 'On date:',
    signatureOfResponsable: 'Signature of responsable:',
    note: 'This document is not a zootechnical certificate in the meaning of the European Regulation 2016/1012.',
  },
  NL: {
    titleA: 'Centrum',
    titleB: 'Bestemming',
    titleC: 'Hengst',
    titleD: 'Sperma',
    titleE: 'Validatie',
    name: 'Naam:',
    address: 'Adres:',
    approvalNumber: 'Erkenningsnummer:',
    mare: 'Merrie:',
    breed: 'Ras:',
    studbookNumber: 'Stamboek nr.:',
    studbook: 'Stamboek:',
    gainDate: 'Datum winning:',
    numberForDose: 'Aantal buisjes/rietjes voor 1 dosis:',
    numberInDelivery: 'Aantal buisjes/rietjes in deze zending:',
    handedOverAt: 'Overhandigd op:',
    onDate: 'Op datum:',
    signatureOfResponsable: 'Handtekening van de verantwoordelijke:',
    note: 'Dit document is geen zoötechnisch certificaat in de zin van de Europese Verordening 2016/1012.',
  },
};
