export const companyInfo = {
  namespaced: true,

  state: () => ({
    name: 'Hof Ter Leeuwe bv',
    address: 'Ganzenkooi 2',
    postalCode: '3440',
    country: 'Belgium',
    city: 'Zoutleeuw',
    website: 'www.hofterleeuwe.be',
    vatNumber: '',
    phone: '+32 11/78.11.13',
    mail: 'office@hofterleeuwe.be',
  }),
};
