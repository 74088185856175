
import { SearchResultTypes } from '@/consts';
import Vue from 'vue';
import SearchHorse from '@/components/search/SearchHorse.vue';
import { ownerName } from '@/Helpers';
import { searchResultAPI } from '@/services';

export default Vue.extend({
  name: 'SearchHorseBlock',
  components: { SearchHorse },
  data: () => ({
    mostViewed: [],
    loading: false,
  }),
  beforeMount() {
    this.getMostViewedHorses();
  },
  methods: {
    ownerName,
    async goToHorse(item) {
      try {
        searchResultAPI.postSearchResult(
          { searchItem: item.searchItem._id, searchType: SearchResultTypes.HORSE },
        );
        await this.$router.push(`/horse/${item.searchItem._id}`);
      } catch (error) {
        await this.$store.dispatch('errorMessage/setErrorMessage', error);
      }
    },
    async getMostViewedHorses() {
      this.loading = true;
      try {
        const { data } = await searchResultAPI.getMostViewed();
        this.mostViewed = data;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setErrorMessage', error);
      } finally {
        this.loading = false;
      }
    },
  },
});
