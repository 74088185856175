<template>
  <v-card
    class="mx-5 mt-5 mb-5"
    outlined
  >
    <v-toolbar flat>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Zoeken"
        outlined
        dense
        single-line
        hide-details
      />
    </v-toolbar>
    <v-data-table
      class="ma-5"
      :headers="headers"
      :search="search"
      :items="horses"
      :loading="loading"
      loading-text="Bezig met laden..."
    >
      <template #no-data>
        Geen draagmoeders gevonden
      </template>
      <template #item="props">
        <tr>
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.surrogate_uid }}</td>
          <td>{{ props.item.microchip }}</td>
          <td>{{ displayLocation(props.item.location) }}</td>
          <td class="text-right">
            {{ formatDate(props.item.date_of_birth) }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-btn
      bottom
      color="primary"
      class="ma-2 white--text"
      depressed
      dark
      fixed
      right
      @click="downloadResearchSheet()"
    >
      onderzoeksfiches downloaden
      <v-icon
        right
        dark
      >
        mdi-arrow-collapse-down
      </v-icon>
    </v-btn>
  </v-card>
</template>
<script>
import horseAPI from '@/services/HorseAPI.js';
import { formatDate } from '@/Helpers';
import { downloadResearchSheetPDF } from '@/Helpers/research-sheet.helper';
import { displayLocation } from '@/Helpers/location.helper';

export default {
  data() {
    return {
      title: 'Onderzoeksfiches',
      search: '',
      horses: [],
      headers: [
        { text: 'Naam', value: 'name', selected: true },
        { text: 'Brandnummer', value: 'surrogate_uid', sortable: false, selected: false },
        { text: 'Microchip', value: 'microchip', selected: false },
        { text: 'Locatie', value: 'location', selected: true },
        { text: 'Geboortedatum', align: 'end', value: 'date_of_birth', selected: false },
      ],
      loading: true,
      URLParameters: {
        type: 'Merrie',
        death: false,
        surrogate: true,
        location: true,
        date_of_birth: true,
        surrogate_uid: true,
        microchip: true,
      },
    };
  },
  mounted() {
    this.getHorses();
  },
  methods: {
    displayLocation,
    formatDate,
    async getHorses() {
      this.loading = true;
      try {
        const { data: { results, total } } = await horseAPI.getHorses(this.URLParameters);
        this.horses = results;
        this.totalHorses = total;
        await this.$store.dispatch('errorMessage/resetError');
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      } finally {
        this.loading = false;
      }
    },
    downloadResearchSheet() {
      downloadResearchSheetPDF('onderzoeksfiches', this.horses);
    },
  },
};
</script>
