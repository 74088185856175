<template>
  <v-layout
    class="print-only-flex mx-5 mb-5"
    justify-space-between
    align-end
    row
    style="max-height: 110px;"
  >
    <h3
      v-if="title"
      style="width: 50%; color: #FF5233 !important;"
      class="ml-4 primary--text text-center"
    >
      {{ title }}
    </h3>
    <div
      style="display: flex; align-items: center; width: fit-content; margin-bottom: 8px;"
    >
      <img
        alt="logo Hof ter Leeuwe"
        src="../../assets/logo.png"
        width="100"
        style="width: 110px; height: 110px; display: block;"
      >
      <address
        class="mx-3 primary--text"
        style="display: flex; flex-direction: column; font-size: 12px;"
      >
        <span>{{ companyInfo.name }}</span>
        <span>{{ companyInfo.address }}, {{ companyInfo.postalCode }} {{ companyInfo.city }}</span>
        <span>{{ companyInfo.website }}</span>
        <span>{{ companyInfo.phone }}</span>
        <span>{{ companyInfo.mail }}</span>
        <span>BN 96/05</span>
        <span>BN 00/02</span>
      </address>
    </div>
  </v-layout>
</template>

<script>
export default {
  name: 'PrintHeader',
  props: {
    title: { type: String, default: undefined },
  },
  data: (data) => ({
    publicPath: process.env.BASE_URL,
    companyInfo: data.$store.state.companyInfo,
  }),
};
</script>

<style scoped>
.print-only-flex {
  display: none;
}

@media print {
  .print-only-flex {
    display: flex;
    margin-top: 0;
  }
}
</style>
