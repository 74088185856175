<template>
  <v-data-table
    v-if="protocol"
    :headers="headers"
    :items="parameters"
    :item-key="_id"
    hide-default-header
    hide-default-footer
    style="width: 100%; background-color: #f9f9f9;"
  >
    <template #no-data>
      Geen parameters in dit protocol
    </template>
    <template #item="props">
      <tr v-if="props.item.elementModel === 'Embryo'">
        <td>
          {{
            props.item.element.donor_mare ? props.item.element.donor_mare.name : 'Geen donor merrie'
          }}
        </td>
        <td class="text-end">
          {{ embryoDescription(props.item.element) }}
        </td>
      </tr>
      <tr v-if="props.item.elementModel === 'SemenCollection'">
        <td v-if="props.item.element">
          {{
            props.item.element.stallion ? props.item.element.stallion.name : 'Geen hengst geselecteerd'
          }}
        </td>
        <td
          v-if="props.item.elementAmount"
          class="text-center"
        >
          {{ props.item.elementAmount }} {{ props.item.elementAmount === 1 ? 'rietje' : 'rietjes' }}
        </td>
        <td class="text-end">
          {{ semenDescription(props.item.element) }}
        </td>
      </tr>
      <tr v-if="props.item.elementModel === 'Horse'">
        <td
          v-if="props.item.element"
          :colspan="headers.length"
        >
          {{ checkType(props.item.elementType) }}
          Paard: {{ props.item.element.name ? props.item.element.name : 'Geen hengst geselecteerd' }}
        </td>
      </tr>
      <tr v-if="props.item.elementModel === 'Customer'">
        <td
          v-if="props.item.element"
          :colspan="headers.length"
        >
          Bestemmeling: {{ ownerName(props.item.element) }}
        </td>
      </tr>
      <tr v-if="props.item.elementType === 'Amount'">
        <td
          :colspan="headers.length"
        >
          Specifieke hoeveelheid: {{ props.item.elementAmount }} {{ props.item.elementName }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { embryoDescription, semenDescription, ownerName } from '@/Helpers';

export default {
  props: {
    protocol: { type: Object, default: () => ({}) },
    disabled: { type: Boolean },
  },
  data: () => ({
    headers: [
      { text: 'Paard' },
      { text: 'Aantal' },
      { text: 'Locatie', align: 'end' },
    ],
  }),
  computed: {
    parameters() {
      return [...this.protocol.parameters, ...this.protocol.simple_parameters];
    },
  },
  methods: {
    embryoDescription,
    semenDescription,
    ownerName,
    checkType(elementType) {
      switch (elementType) {
      case 'Merrie':
        return 'Draagmoeder: ';
      case 'Hengst':
        return 'Hengst: ';
      default:
        return '';
      }
    },
  },
};
</script>
