<template>
  <v-layout
    column
    fill-height
  >
    <PrintHeader
      class="print-only"
      title="ICSI delivery report"
    />
    <v-row>
      <v-col>
        <v-card
          class="mx-5 mt-4 pa-2"
          outlined
        >
          <v-card-title>
            <h3 class="text-h5">
              {{ `A. ${strings[language].titleA}` }}
            </h3>
          </v-card-title>
          <div class="mb-2 grid">
            <span>{{ strings[language].name }}</span>
            <span>{{ 'Hof ter leeuwe' }}</span>
            <span>{{ strings[language].address }}</span>
            <span>{{ 'Ganzenkooi 2, 3440 Zoutleeuw' }}</span>
            <span>{{ strings[language].approvalNumber }}</span>
            <span>{{ approvalNumber }}</span>
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card
          class="mx-5 mt-4 pa-2"
          outlined
        >
          <v-card-title>
            <h3 class="text-h5">
              {{ `B. ${strings[language].titleB}` }}
            </h3>
          </v-card-title>
          <div class="mb-2 grid">
            <span>{{ strings[language].name }}</span>
            <v-text-field
              v-model="customer"
              label="Klant"
              variant="outlined"
              class="no-print"
            />
            <span class="print-only">{{ customer }}</span>
            <span>{{ strings[language].address }}</span>
            <v-text-field
              v-model="address"
              label="Adres"
              variant="outlined"
              class="no-print"
            />
            <span class="print-only">{{ address }}</span>
            <span>{{ strings[language].exportDate }}</span>
            <span>{{ exportDate ? formatDate(exportDate) : '................................' }}</span>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-card
      class="ma-5 pa-5"
      outlined
    >
      <v-card-title>
        <h3 class="text-h5">
          {{ `C. ${strings[language].titleC}` }}
        </h3>
      </v-card-title>
      <v-row justify="center">
        <v-card
          outlined
          class="ma-5"
        >
          <v-data-table
            :items="selectedEmbryos"
            loading-text="Bezig met laden..."
            class="ma-5"
            item-key="_id"
            :headers="headers"
            hide-default-footer
          >
            <template #no-data>
              Geen geselecteerde embryo's voor export
            </template>
            <template #item="props">
              <tr>
                <td>{{ props.item.code }}</td>
                <td>
                  <v-icon medium>
                    mdi-gender-female
                  </v-icon>
                  {{ horseName(props.item.donor_mare) }}
                  -
                  <v-icon medium>
                    mdi-gender-male
                  </v-icon>
                  {{ horseName(props.item.donor_stallion) }}
                </td>
                <td>{{ ownerName(props.item.owner) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
      <v-row justify="center">
        <v-col>
          <signature-display
            v-if="doctor"
            class="ma-3"
            :doctor="doctor"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-card
      class="ma-5 no-print"
      outlined
    >
      <v-card-title>Selectie velden</v-card-title>
      <v-row class="mx-1 mb-2">
        <v-col cols="6">
          <v-select
            v-model="language"
            :items="languages"
            label="selecteer een taal"
            :menu-props="{ offsetY: true }"
            hide-details
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <date-picker
            :date="exportDate"
            label="Verzenddatum"
            :hide-details="true"
            :outlined="true"
            :dense="true"
            @select-date="exportDate = $event"
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="doctor"
            :items="doctors"
            label="selecteer ondergetekende"
            :menu-props="{ offsetY: true }"
            hide-details
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="approvalNumber"
            :items="approvalNumbers"
            label="selecteer erkenningsnummer"
            :menu-props="{ offsetY: true }"
            hide-details
            outlined
            dense
          />
        </v-col>
      </v-row>
    </v-card>
    <v-row
      justify="end"
      dense
    >
      <v-btn
        style="width: fit-content"
        color="primary"
        class="mr-2 mb-5 no-print"
        depressed
        @click="$router.back()"
      >
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        terug
      </v-btn>
      <v-btn
        style="width: fit-content"
        color="success"
        class="mr-5 mb-5 no-print"
        depressed
        @click="print"
      >
        print
      </v-btn>
    </v-row>
  </v-layout>
</template>

<script>
import { print, formatDate, horseName, ownerName } from '@/Helpers';
import { displayEmbryoLocation } from '@/Helpers/location.helper';
import DatePicker from '@/components/DateSelector/DatePicker';
import PrintHeader from '@/components/common/PrintHeader';
import SignatureDisplay from '@/components/SignatureDisplay/SignatureDisplay.vue';
import { icsiAPI } from '@/services';
import { strings } from '@/consts/translations/IcsiDelivery.report.translation';

export default {
  name: 'IcsiDeliveryReport',
  components: {
    DatePicker,
    PrintHeader,
    SignatureDisplay,
  },
  data() {
    return {
      approvalNumber: null,
      approvalNumbers: ['BN 96/05', 'BN 00/02'],
      icsiId: this.$route.params.id,
      selectedEmbryos: [],
      mare: null,
      stallion: null,
      code: null,
      address: null,
      customer: null,
      language: 'NL',
      languages: ['NL', 'ENG'],
      doctor: null,
      doctors: ['Dr. Degeneffe Erik', 'Degeneffe Wim'],
      exportDate: new Date(),
      headers: [
        {
          text: 'Code',
          value: 'code',
        },
        {
          text: 'Donormerrie en donorhengst',
          value: 'donors',
        },
        {
          text: 'Eigenaar',
          value: 'owner',
        },
      ],
      strings,
    };
  },
  computed: {},
  beforeMount() {
    const { embryo } = this.$route.query;
    this.getIcsi(this.icsiId);

    if (typeof embryo === 'string') {
      this.getEmbryo(embryo);
    } else {
      Promise.all([...embryo.map((id) => this.getEmbryo(id))]);
    }
  },
  methods: {
    print,
    formatDate,
    horseName,
    ownerName,
    displayEmbryoLocation,
    async getIcsi(id) {
      try {
        const { data } = await icsiAPI.getICSI(id);

        this.mare = data.donor_mare;
        this.stallion = data.donor_stallion;
        this.code = data.code;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      }
    },
    async getEmbryo(id) {
      try {
        const { data } = await icsiAPI.getEmbryo(id);

        this.selectedEmbryos.push(data);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      }
    },
  },
};
</script>

<style scoped>
h4 {
  text-decoration: underline;
  margin-bottom: 0.5em;
}

b,
h4,
span,
time,
div {
  color: #1a1449;
}

span {
  margin: 0.25em 0;
}

strong,
.v-toolbar__title {
  font-weight: bolder;
  color: #ff5233;
}

div.grid {
  color: #1a1449;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
