<template>
  <v-layout
    column
    fill-height
    justify-space-between
  >
    <horse-table
      :headers="headers"
      :filters="filters"
      has-csv-export
      selectable
      @update-download-data="downloadData = $event"
    />
    <v-row
      class="mb-5 mr-5"
      justify="end"
    >
      <v-btn
        style="width: fit-content; align-self: flex-end"
        color="success"
        class="mr-5 mb-5"
        tag="a"
        :disabled="!downloadData"
        :loading="!downloadData"
        :href="downloadData?.dataUri"
        :download="downloadData?.name"
        depressed
      >
        Download CSV
        <v-icon right>
          mdi-file-download
        </v-icon>
      </v-btn>
      <v-btn
        style="width: fit-content; align-self: flex-end"
        color="primary"
        class="mr-5 mb-5 no-print"
        depressed
        @click="openCreateHorsePage()"
      >
        Paard toevoegen
        <v-icon right>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-row>
  </v-layout>
</template>
<script>
import horseTable from '@/components/horse/Table';
import { horseAPI } from '@/services';
import { horseName } from '@/Helpers';

export default {
  components: { horseTable },
  data: () => ({
    locations: [],
    headers: [
      { text: 'Naam', value: 'name', selected: true, groupable: false },
      { text: 'Type', value: 'type', sortable: false, selected: true },
      { text: 'Brandnummer', value: 'surrogate_uid', selected: false, groupable: false },
      { text: 'Microchip', value: 'microchip', selected: false, groupable: false },
      { text: 'Locatie', value: 'location', selected: true },
      { text: 'Eigenaar', value: 'owner', selected: true },
      { text: 'Dekhengst', align: 'end', value: 'stud_horse', selected: true, groupable: false },
      { text: 'Draagmoeder', align: 'end', value: 'surrogate', selected: true, groupable: false },
      { text: 'Embryo', value: 'embryo', selected: true },
      { text: '45 dagen', value: 'embryoImplanted', selected: true },
      { text: 'Aangemaakt op', align: 'end', value: 'createdAt', selected: false, groupable: false },
      { text: 'Gewijzigd op', align: 'end', value: 'updatedAt', selected: false, groupable: false },
      { text: 'Geboortedatum', align: 'end', value: 'date_of_birth', selected: false, groupable: false },
    ],
    downloadData: null,
  }),
  computed: {
    filters() {
      return {
        owner: { vTag: 'searchCustomer' },
        stable_prefix: {
          vTag: 'locationFilter',
        },
        microchip: {
          vTag: 'basicSearch',
          label: 'Microchip',
          searchFunction: this.searchMicrochip,
          format: this.horseName,
          getSpecific: this.getSpecificHorse,
          returnValue(item) {
            return item.microchip;
          },
          cols: 6,
        },
        surrogate_uid: {
          vTag: 'basicSearch',
          label: 'Brandnummer',
          searchFunction: this.searchSurrogateUID,
          format: this.horseName,
          getSpecific: this.getSpecificHorse,
          returnValue(item) {
            return item.surrogate_uid;
          },
          cols: 6,
        },
        stallion: { vTag: 'switch', label: 'Toon enkel hengsten' },
        mare: { vTag: 'switch', label: 'Toon enkel merries' },
        surrogate: { vTag: 'switch', label: 'Toon enkel draagmoeders' },
        stud_horse: { vTag: 'switch', label: 'Toon enkel dekhengsten' },
        aliveOnly: { vTag: 'switch', label: 'Verberg gestorven paarden' },
      };
    },
  },
  methods: {
    horseName,
    openCreateHorsePage() {
      this.$router.push({ path: '/horse/create' });
    },
    async searchMicrochip(v) {
      const { data } = await horseAPI.searchMicrochip(v, {
        limit: 10,
      });
      return data;
    },
    async searchSurrogateUID(v) {
      const { data } = await horseAPI.searchSurrogateUID(v, {
        limit: 10,
      });
      return data;
    },
  },
};
</script>
