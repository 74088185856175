<template>
  <v-autocomplete
    v-model="selected"
    :placeholder="selected && selected.description !== 'Alle producten' ? selected.description : ''"
    :menu-props="{closeOnContentClick:true}"
    prepend-inner-icon="mdi-magnify"
    :search-input.sync="search"
    :clearable="clearable"
    :autofocus="autofocus"
    :items="items"
    item-text="name"
    item-value="_id"
    label="Product zoeken"
    return-object
    hide-no-data
    hide-details
    outlined
    :dense="dense"
    @click:clear="clear"
  >
    <template #item="{ item }">
      <v-list-item @click="emitProduct(item)">
        <v-list-item-action>
          <v-icon>mdi-pill</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.remaining }} {{ item.outgoingUnit }} in stock</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>
import { productsAPI } from '@/services';

export default {
  props: {
    product: { type: [Object, String, undefined] },
    id: { type: String, default: undefined },
    clearable: { type: Boolean },
    autofocus: { type: Boolean },
    dense: { type: Boolean },
  },
  data: () => ({
    loading: false,
    entries: [],
    search: null,
    selected: null,
  }),
  computed: {
    items() {
      if (this.selected && this.selected._id && !this.entries.length) {
        return [this.selected];
      }
      return this.entries;
    },
  },
  watch: {
    search(val) {
      if (val && val !== this.selected) this.querySelections(val);
    },
    product() {
      this.selected = this.product;
    },
    $route: {
      immediate: true,
      deep: true,
      handler() {
        if (this.id && !this.entries.length) {
          this.getProductById();
        }
        if (!this.id && !this.customer) {
          this.selected = {};
          this.entries = [];
        }
      },
    },
  },
  mounted() {
    this.selected = this.product;
  },
  methods: {
    async querySelections(v) {
      this.loading = true;
      try {
        await this.$store.dispatch('errorMessage/resetError');
        const { data } = await productsAPI.searchProduct(v, { limit: 5 });
        this.entries = data;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      } finally {
        this.loading = false;
      }
    },
    async getProductById() {
      this.loading = true;
      try {
        const { data } = await productsAPI.getProduct(this.id);
        this.selected = data;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.selected = {};
      this.$emit('emit-product', undefined);
    },
    emitProduct(item) {
      this.$emit('emit-product', item);
      this.selected = item;
    },
  },
};
</script>
