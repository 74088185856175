<template>
  <v-layout
    column
    fill-height
  >
    <v-card
      outlined
      elevation="0"
      class="mx-5 mt-5"
    >
      <v-toolbar flat>
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <component
        :is="tableComponent"
        :is-print="false"
        @update-download-data="downloadData=$event"
      />
    </v-card>
    <v-row
      dense
      class="ma-5 type-yearly-report-table__bottom-row"
      justify="end"
    >
      <v-btn
        style="width: fit-content;"
        color="primary"
        depressed
        @click="$router.back()"
      >
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        terug
      </v-btn>
      <v-btn
        v-if="downloadData"
        class="ml-2"
        color="success"
        tag="a"
        :disabled="!downloadData"
        :loading="!downloadData"
        :href="downloadData?.dataUri"
        :download="downloadData?.name"
        depressed
      >
        download csv
      </v-btn>
    </v-row>
  </v-layout>
</template>

<script>
import EmbryoGainTable from '@/components/yearly-report/table/mare/EmbryoGainTable';
import EmbryoTransplantationTable from '@/components/yearly-report/table/mare/EmbryoTransplantationTable';
import SpermGainTable from '@/components/yearly-report/table/stallion/SpermGainTable';
import SpermSentReceivedTable from '@/components/yearly-report/table/stallion/SpermSentReceivedTable';
import {
  semenGainType,
  semenImportExportType,
  ovumGainType,
  yearlyReportTitles, embryoTransplantation,
} from '@/consts/yearlyReport/yearlyReport.types';

export default {
  components: { EmbryoGainTable, EmbryoTransplantationTable, SpermGainTable, SpermSentReceivedTable },
  data: (data) => ({
    year: parseInt(data.$route.query.year, 10),
    type: data.$route.params.type,
    downloadData: null,
  }),
  computed: {
    tableComponent() {
      switch (this.$route.params.type) {
      case semenGainType:
        return SpermGainTable;
      case semenImportExportType:
        return SpermSentReceivedTable;
      case ovumGainType:
        return EmbryoGainTable;
      case embryoTransplantation:
        return EmbryoTransplantationTable;
      default:
        return undefined;
      }
    },
    title() {
      return yearlyReportTitles[this.$route.params.type] || '';
    },
  },
  watch: {
    year: {
      immediate: true,
      handler(newYear) {
        if (newYear && this.$store.state.yearlyReport.year !== newYear) {
          this.$store.dispatch('yearlyReport/setYear', { year: newYear });
        }
      },
    },
  },
};
</script>
<style lang="scss">
.type-yearly-report-table__bottom-row {
  position: sticky;
  bottom: 1rem;
}
</style>
